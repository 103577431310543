import { Icon } from 'components/structure';
import * as S from './FooterPoweredBy.styles';

export type FooterPoweredByProps = {
  fixed?: boolean;
};
export const FooterPoweredBy = ({ fixed = false }: FooterPoweredByProps) => (
  <S.Container fixed={fixed}>
    <Icon icon="IcPoweredByTm1" />
  </S.Container>
);
