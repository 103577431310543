/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */
import { yupResolver } from '@hookform/resolvers/yup';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { ConsoleView, isMobile } from 'react-device-detect';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { responseInterface } from 'swr';
import * as Yup from 'yup';
import { dispatchGAEvent } from 'services/google';
import { useChat } from 'useCases/chat';
import { useLikes } from 'useCases/likes';
import { useUserByUuid } from 'useCases/users';
import {
  useInfiniteScroll,
  usePortal,
  useSession,
  useTransformMessages,
  useTranslate,
  useClickOutside,
} from 'hooks';
import { useCountries } from 'hooks/useCountries';
import { useChatContext, useNotificationContext } from 'contexts';
import {
  Message,
  PlenaryIcon,
  UserChat,
} from 'components/contexts/interactions';
import { ConversationMessage } from 'components/contexts/interactions/Chat';
import { Chip, UserCard } from 'components/contexts/users';
import { Spinner, Icon, Modal } from 'components/structure';
import { EventAction, EventCategory } from 'interfaces/analytics';
import { LatestMessages } from 'interfaces/chat';
import { InteractionsTabs, BRAZIL_COUNTRY_CODE } from 'constants/enums';
import {
  ChatTypes,
  GA_EVENTS,
  CHAT_FIELD_MAX_LENGTH,
  NotificationTypes,
} from 'constants/enums';
import { Skeleton } from './Conversation.skeleton';
import * as S from './Conversation.styles';

export const schema = Yup.object().shape({
  uuid: Yup.string().max(CHAT_FIELD_MAX_LENGTH),
});

type LatestMessagesReturn = responseInterface<LatestMessages, unknown> & {
  isLoading: boolean;
  isLoadingMore: boolean;
  hasMore: boolean;
  loadMoreMessages: () => void;
};

export type ConversationProps = {
  room: UserChat;
  onClick?: () => void;
  handleSendMessage: (
    message: string,
    mentions?: any[],
    customMentions?: any[],
  ) => void;
  useLatestMessages: (roomId: string) => LatestMessagesReturn;
  error?: string;
  isOpen?: boolean;
};

type FormData = {
  message: string;
  mentions?: string;
};
interface UseCard {
  name: string;
  userId?: string;
  avatar?: string;
  email?: string;
  country?: any;
  company?: string;
  state?: string;
}
export const Conversation = memo(
  ({
    room,
    error,
    onClick,
    handleSendMessage,
    useLatestMessages,
    isOpen,
  }: ConversationProps) => {
    const { handleJoinPrivateChannel } = useChat();
    const { pathname } = useLocation();
    const { id: userIdSession } = useSession();
    const [isLiked, setIsLiked] = useState(false);
    const streamId = pathname.split('/')[2];
    const translate = useTranslate();
    const Portal = usePortal();
    const { getUseCountryLabel, getUseStateLabel } = useCountries();

    const [showError, setShowError] = useState(false);
    const {
      isLoading,
      isLoadingMore,
      hasMore,
      loadMoreMessages,
      data,
    } = useLatestMessages(room.id);
    const [modal, setModal] = useState<UseCard>({
      name: '',
      userId: '',
      avatar: '',
      email: '',
      country: '',
    });
    const clickOutSideRef = useClickOutside(() =>
      setModal({ name: '', userId: '', avatar: '', email: '' }),
    );
    const { plenary, showRoom, setRoom, setShowRoom } = useChatContext();
    const messages = useTransformMessages(data?.messages);
    const {
      handleReceiveReaction,
      handleSendReaction,
      likes: realTimeLikes,
      setLikes,
    } = useLikes(streamId);
    const [likeUpdateNeeded, setLikeUpdateNeeded] = useState(false);
    const [receivedFirstLikeUpdate, setFirstLikeUpdate] = useState(false);

    const lastMessageRef = useInfiniteScroll(
      isLoadingMore,
      hasMore,
      loadMoreMessages,
    );
    const { handleSubmit, register, setValue, getValues } = useForm<FormData>({
      resolver: yupResolver(schema),
    });
    const focus = !isMobile;

    const [alreadySaved, setAlreadySaved] = useState(false);
    useEffect(() => {
      if (messages.length > 0 && !alreadySaved) {
        setLikes(
          messages
            .map((msg) => msg.likes?.content)
            .reduce((prev: any, curr: any) => [...prev, ...(curr || [])], []),
        );
        setAlreadySaved(true);
      }
      if (likeUpdateNeeded) {
        setLikes(
          [
            ...realTimeLikes,
            ...messages
              .map((msg) => msg.likes?.content)
              .reduce(
                (prev: any, curr: any) => [...prev, ...(curr || [])],
                [],
              )!,
          ].filter(
            (item, index, arr) =>
              arr.findIndex((v) => v.id === item.id) === index,
          ),
        );
        setLikeUpdateNeeded(false);
        setFirstLikeUpdate(true);
      }
      if (!receivedFirstLikeUpdate) {
        setLikes(
          [
            ...messages
              .map((msg) => msg.likes?.content)
              .reduce(
                (prev: any, curr: any) => [...prev, ...(curr || [])],
                [],
              )!,
          ].filter(
            (item, index, arr) =>
              arr.findIndex((v) => v.id === item.id) === index,
          ),
        );
        setFirstLikeUpdate(true);
      }
      // if (messages.map(msg => msg.likes?.content).length > 0 && realTimeLikes.length === 0) {
      //   console.log('s')
      //   setLikes([...realTimeLikes, ...messages.map(msg => msg.likes?.content).reduce((prev: any, curr: any) => [...prev, ...(curr || [])], [])!]
      //     .filter((item, index, arr) => arr.findIndex(v => v.id === item.id) === index)
      //   )
      //   setLikeUpdateNeeded(false)
      // }
    }, [messages]);
    useEffect(() => {
      if (isLoadingMore) {
        setLikeUpdateNeeded(true);
      }
    }, [isLoadingMore]);

    useEffect(() => {
      handleReceiveReaction();
    }, [handleReceiveReaction]);
    const notification = useNotificationContext();
    const [slowMode, setSlowMode] = useState(false);
    const getMessageRef = (position: number) =>
      position === messages?.length - 1 ? lastMessageRef : null;
    useEffect(() => {
      (async () => {
        if (slowMode) {
          await new Promise((resolve) => setTimeout(resolve, 1500));
          setSlowMode(false);
        }
      })();
    }, [slowMode]);
    const messageRef = useRef(null);
    const onSubmit = useCallback(
      (data: FormData) => {
        (messageRef as any).current?.scrollIntoView();
        if (slowMode) {
          setShowError(true);
          setTimeout(() => {
            setShowError(false);
          }, 3500);
          return;
        }
        if (data.message) {
          const serializedMentions: any[] = JSON.parse(data?.mentions || '');
          if (!data.message.includes(serializedMentions[0]?.username)) {
            data.mentions = '[]';
          }
          serializedMentions.forEach((sM) => {
            if (!data.message.includes(sM?.username)) {
              data.mentions = JSON.stringify(
                (JSON.parse(data.mentions || '[]') as any[]).filter(
                  (m) => m.username != sM.username,
                ),
              );
            }
          });
          handleSendMessage(data.message, serializedMentions);
          setSlowMode(true);

          setValue('message', '');
        }

        dispatchGAEvent({
          category: EventCategory.Chat,
          action: EventAction.click,
          label: GA_EVENTS.chat.labels.sendMessage,
        });
      },
      [handleSendMessage, setValue, slowMode],
    );

    useEffect(() => {
      if (error) {
        setShowError(true);

        setTimeout(() => {
          setShowError(false);
        }, 5000);
      }
    }, [error]);

    if (isLoading) {
      return <Skeleton />;
    }

    const device = localStorage.getItem('checkDevice');

    const handlePrivateChat = async (id: string) => {
      // handleOpenPrivateChat(id);
      setRoom(undefined);
      // await new Promise(resolve => setTimeout(resolve, 0));
      setTimeout(() => {
        handleJoinPrivateChannel(id);
      }, 0);
      // setShowRoom(true);
    };

    return (
      <>
        <S.Header>
          <S.ReturnButton type="button" onClick={onClick}>
            <Icon color="white" icon="IcChevron" />
          </S.ReturnButton>
          <Chip
            id={room.id}
            name={room.name}
            avatar={room.avatar}
            icon={room.isPrivate ? null : <PlenaryIcon size="small" />}
            isCenter
          />
        </S.Header>
        {modal.name != '' && modal.userId != '' && (
          <S.Modal>
            {console.log(modal)}
            <UserCard
              handlePrivateChat={async () => {
                await handlePrivateChat(modal.userId || '');
                dispatchGAEvent({
                  action: EventAction.click,
                  category: EventCategory.Chat,
                  label: GA_EVENTS.chat.labels.talk,
                });
              }}
              handleCloseCard={() =>
                setModal({
                  name: '',
                  userId: '',
                  avatar: '',
                  email: '',
                  country: '',
                  state: '',
                })
              }
              user={{
                id: modal.userId,
                name: modal.name,
                avatar: modal.avatar,
                email: modal.userId === userIdSession ? '' : modal.email,
                country:
                  (modal.state ? getUseStateLabel(modal.state) + ', ' : '') +
                  getUseCountryLabel(Number(modal.country)),
                company: modal.company,
                state: modal.state,
              }}
              forwardRef={modal.userId ? clickOutSideRef : null}
              isChatDisabled={modal.userId === userIdSession}
            />
            {/* {console.log(modal, userIdSession)} */}
          </S.Modal>
        )}

        <S.Messages device={device || ''}>
          {messages?.map(
            (
              {
                avatar,
                name,
                message,
                country,
                company,
                email,
                state,
                id,
                received,
                isSended,
                isStaff,
                createdAt,
                likes,
                mentions,
                userId,
              },
              index,
            ) => (
              <>
                <Message
                  setUserCard={setModal}
                  handleOpenPrivateChat={() => userId}
                  setTab={() => InteractionsTabs.chat}
                  ref={getMessageRef(index)}
                  messageRef={index === 0 ? messageRef : null}
                  id={id}
                  key={id}
                  userIdTest={userId}
                  avatar={avatar}
                  name={name}
                  email={email}
                  company={company}
                  country={country}
                  state={state}
                  createdAt={createdAt}
                  message={message}
                  received={received}
                  isSended={isSended}
                  isPrivate={room.id.startsWith(ChatTypes.PRIVATE)}
                  isStaff={isStaff}
                  likes={likes}
                  mentions={mentions}
                  currentUserId={userIdSession}
                  handleReceiveReaction={handleReceiveReaction}
                  handleSendReaction={handleSendReaction}
                  realTimeLikes={
                    realTimeLikes?.filter((l: any) => l.messageId == id)
                    // .concat(likes?.content)
                    // .filter((value: any, index: number, arr: any[]) => {
                    //   arr.findIndex((v2) => v2.userId === value.userId) === index;
                    // })
                  }
                  setLikes={setLikes}
                  userId={userId}
                  streamId={streamId}
                  isLastMessage={index == 0}
                />
              </>
            ),
          )}
          {isLoadingMore && (
            <S.SpinnerWrapper>
              <Spinner />
            </S.SpinnerWrapper>
          )}
        </S.Messages>
        {isMobile && isOpen && (
          <Portal>
            <ConversationMessage
              showError={showError}
              error={
                error?.length
                  ? error
                  : 'Você deve esperar antes de enviar a próxima mensagem'
              }
              focus={focus}
              handleSubmit={handleSubmit(onSubmit)}
              setValue={setValue}
              getValues={getValues}
              placeholder={translate('interactions.typeHere')}
              register={register}
            />
          </Portal>
        )}
        {!isMobile && (
          <ConversationMessage
            showError={showError}
            error={
              error?.length
                ? error
                : 'Você deve esperar antes de enviar a próxima mensagem'
            }
            focus={focus}
            handleSubmit={handleSubmit(onSubmit)}
            setValue={setValue}
            getValues={getValues}
            placeholder={translate('interactions.typeHere')}
            register={register}
          />
        )}
      </>
    );
  },
);
