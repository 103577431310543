import en from './en.json';
import es from './es.json';
import pt from './pt-br.json';

export const usersPT = {
  users: pt,
};

export const usersEN = {
  users: en,
};

export const usersES = {
  users: es,
};
