import { memo, useEffect, useMemo } from 'react';
// import { useLocation } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { dispatchGAEvent } from 'services/google';
import { useChat } from 'useCases/chat';
import { useEvent } from 'useCases/events';
import { useTranslate } from 'hooks';
import {
  useChatContext,
  useInteractionsContext,
  useSettingsContext,
  ThemeCustomProvider,
  useSurveyContext,
} from 'contexts';
import {
  ChatList,
  ChatRoom,
  Networking,
} from 'components/contexts/interactions';
import { Survey } from 'components/contexts/interactions/Survey/Survey';
import { Icon } from 'components/structure';
import EventOptions from 'styles/overrides/EventOptions.theme';
import * as InteractionsTheme from 'styles/overrides/Interactions.theme';
import { AnalyticsEvent } from 'interfaces/analytics';
import { InteractionsOpenState, InteractionsTabs } from 'constants/enums';
import { interactiveTabs } from './Interactions.mock';
import * as S from './Interactions.styles';

export type InteractiveTab = {
  icon: JSX.Element;
  title: string;
  tabPosition: InteractionsTabs;
  ga: AnalyticsEvent;
};

const tabsKey = Object.keys(interactiveTabs);

export type InteractionsProps = {
  show?: boolean;
};

export const Interactions = memo(({ show = true }: InteractionsProps) => {
  const translate = useTranslate();
  const { data: event } = useEvent();
  const {
    openState,
    tab,
    setOpenState,
    setTab,
    hasCollapse,
    availableTabs,
    setAvailableTabs,
  } = useInteractionsContext();
  const {
    hasInteraction,
    setHasInteraction,
    playerHeight,
    streamPage,
  } = useSettingsContext();
  const { plenary, showRoom, room, setRoom, setShowRoom } = useChatContext();
  const {
    error,
    isLoading,
    isChatConnected,
    useUserChats,
    useLatestMessages,
    handleSendMessage,
    handleOnLeaveChatRoom,
    handleJoinStreamChannel,
    handleJoinPrivateChannel,
  } = useChat();
  const isChatDisabled = event?.event.disableChat;
  const { surveyState } = useSurveyContext();
  const { pathname } = useLocation();
  // TODO add availableTabs array to Event Theme
  const tabs = useMemo(
    () =>
      tabsKey
        .filter((tabKey) =>
          EventOptions.hasOffers
            ? tabKey !== InteractionsTabs.survey &&
              availableTabs.includes(tabKey as InteractionsTabs)
            : availableTabs.includes(tabKey as InteractionsTabs),
        )
        .map((tabKey) => interactiveTabs[tabKey]),
    [availableTabs],
  );

  const offersOrSurveyTab = EventOptions.hasOffers
    ? InteractionsTabs.offers
    : InteractionsTabs.survey;

  const handleCloseRoom = () => {
    setShowRoom(false);
    setRoom(undefined);
    handleOnLeaveChatRoom();
  };

  const handleInteractionPosition = () => {
    if (openState === InteractionsOpenState.closed) {
      setOpenState(InteractionsOpenState.full);
    } else {
      setOpenState(InteractionsOpenState.closed);
    }
  };

  const handleClick = (activeTab: InteractionsTabs) => {
    setTab(activeTab);
    if (!availableTabs.includes(offersOrSurveyTab)) {
      setOpenState(InteractionsOpenState.full);
    }

    if (!streamPage) {
      if (
        activeTab === InteractionsTabs.chat
        // activeTab === InteractionsTabs.networking
      ) {
        setOpenState(InteractionsOpenState.open);
        handleJoinStreamChannel(plenary.id);
        setTab(InteractionsTabs.chat);
      }
    }

    if (room && activeTab !== InteractionsTabs.chat) {
      handleCloseRoom();
    }
  };

  useEffect(() => {
    if (plenary.id && isChatConnected && !isChatDisabled) {
      handleJoinStreamChannel(plenary.id);
      setTab(InteractionsTabs.chat);

      return () => setShowRoom(false);
    }
  }, [
    handleJoinStreamChannel,
    isChatConnected,
    plenary.id,
    setShowRoom,
    isChatDisabled,
    setTab,
  ]);

  useEffect(() => {
    if (isChatDisabled) {
      setAvailableTabs((prevState) =>
        prevState.filter((tab) => tab !== InteractionsTabs.chat),
      );
    }
  }, [isChatDisabled, setAvailableTabs]);

  const handleSurveyEvent = () => {
    if (room && tab === InteractionsTabs.chat) {
      handleCloseRoom();
    }
  };

  return (
    <ThemeCustomProvider {...InteractionsTheme}>
      <S.Wrapper show={show} openState={openState} playerHeight={playerHeight}>
        <S.HeaderWrapper>
          {hasCollapse && (
            <S.HeaderCollapse
              closed={!show}
              onClick={() => setHasInteraction(!hasInteraction)}
            >
              <Icon icon="IcCollapse" />
            </S.HeaderCollapse>
          )}
          <S.Header>
            {streamPage && (
              <S.IconWrapper
                isOpen={openState !== InteractionsOpenState.closed}
                hide={availableTabs.includes(offersOrSurveyTab)}
                onClick={handleInteractionPosition}
              >
                <Icon icon="IcChevronTop" />
              </S.IconWrapper>
            )}
            {tabs.map(({ icon, title, tabPosition, ga }: InteractiveTab) => {
              if (!pathname.includes('stream') && tabPosition === 'survey') {
                return null;
              }
              return (
                <S.TabButton
                  key={title}
                  active={tab === tabPosition}
                  onClick={() => {
                    dispatchGAEvent(ga);
                    handleClick(tabPosition);
                  }}
                >
                  {icon}
                  {translate(title)}
                </S.TabButton>
              );
            })}
          </S.Header>
        </S.HeaderWrapper>
        <S.Body>
          <S.Content active={tab === InteractionsTabs.networking}>
            <Networking
              handleOpenPrivateChat={handleJoinPrivateChannel}
              setTab={setTab}
              isPending={isLoading || !isChatConnected}
              isChatDisabled={isChatDisabled}
              hasInterests={EventOptions.hasInterests}
            />
          </S.Content>
          <S.Content active={tab === InteractionsTabs.chat}>
            <ChatList
              isPending={isLoading || !isChatConnected}
              plenaryChat={plenary}
              useUserChats={useUserChats}
              handleJoinStreamChannel={handleJoinStreamChannel}
              handleJoinPrivateChannel={handleJoinPrivateChannel}
            />
          </S.Content>
          <ChatRoom
            error={error}
            room={room}
            show={showRoom && isChatConnected}
            isLoading={isLoading || !isChatConnected}
            handleSendMessage={handleSendMessage}
            useLatestMessages={useLatestMessages}
            onClick={handleCloseRoom}
          />
          <S.Content active={tab === InteractionsTabs.survey}>
            <Survey
              state={surveyState}
              onSurveyAvailable={handleSurveyEvent}
              onSurveyResult={handleSurveyEvent}
            />
          </S.Content>
        </S.Body>
      </S.Wrapper>
    </ThemeCustomProvider>
  );
});
