/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useContext, useState } from 'react';
// import { useQueryString } from 'hooks';
import { WithChildren } from 'interfaces/children';
import { DEFAULT_LANGUAGE } from 'constants/enums';
import { Language } from './Intl';

type GettingStarted = {
  email?: string;
  uuid?: string;
  id?: string;
  country?: any;
  firstName?: string;
  lastName?: string;
  preferableLanguage: Language;
};

type ContextType = {
  gettingStarted: GettingStarted;
  setGettingStarted: (gettingStarted: GettingStarted) => void;
};

const defaultValue: ContextType = {
  gettingStarted: {
    preferableLanguage: DEFAULT_LANGUAGE,
  },
  setGettingStarted: () => undefined,
};

export const AccreditationContext = createContext<ContextType>(defaultValue);

export const AccreditationProvider = ({ children }: WithChildren) => {
  const [gettingStarted, setGettingStarted] = useState<GettingStarted>(
    defaultValue.gettingStarted,
  );

  return (
    <AccreditationContext.Provider
      value={{
        gettingStarted,
        setGettingStarted,
      }}
    >
      {children}
    </AccreditationContext.Provider>
  );
};

export const useAccreditationContext = () => useContext(AccreditationContext);

AccreditationContext.displayName = 'AccreditationContext';
