import styled, { css } from 'styled-components';

export const ChangeLanguage = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: ${theme.colors.neutral.black};

    > button + button {
      margin-top: 11px;
    }
  `}
`;
