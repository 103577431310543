import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
// import { useMatch } from 'react-router-dom';
import { useEventById } from 'useCases/events';
//import { useUserById } from 'useCases/users';
import { useEnv, useTranslate } from 'hooks';
//import { retrieveUserSession } from 'lib/core/session';
import { useSettingsContext } from 'contexts';
//import { BusinessCard } from 'components/contexts/users';
//import { Skeleton as BusinessCardSkeleton } from 'components/contexts/users/BusinessCard/BusinessCard.skeleton';
import { Icon, FooterPoweredBy } from 'components/structure';
import { ROUTES } from 'constants/urls';
import * as S from './Configurations.styles';
import { Divider } from './divider/Divider';
import { TabRouter } from './tab-router/TabRouter';

export const Configurations = () => {
  const { setHasInteraction, setHasNotification } = useSettingsContext();
  // const { show, setShow, cardProps } = useBusinessCardContext();
  const translate = useTranslate();
  const { eventId } = useEnv();
  const eventById = useEventById(eventId);
  //const { id: userId } = retrieveUserSession();
  //const { isLoading } = useUserById(userId!);
  // const match = useMatch({
  //   path: `${ROUTES.configurations.base}/${ROUTES.configurations.children.personalData}`,
  // });
  const hasInterests = !!eventById.data?.event.interests.length;

  // useEffect(() => {
  //   setShow(!!match);
  // }, [setShow, match]);

  useEffect(() => {
    setHasInteraction(false);
    setHasNotification(false);

    return () => {
      setHasInteraction(true);
      setHasNotification(true);
    };
  }, [setHasInteraction, setHasNotification]);

  return (
    <S.Wrapper>
      <S.Heading>{translate('configurations.configuration')}</S.Heading>
      <S.TabContainer>
        <TabRouter
          icon={<Icon icon="IcUserOutline" />}
          path={ROUTES.configurations.children.personalData}
          label={translate('configurations.personalData')}
        />
        <TabRouter
          icon={<Icon icon="IcUser" />}
          path={ROUTES.configurations.children.photo}
          label={translate('configurations.photo')}
        />
        {hasInterests && (
          <>
            <TabRouter
              icon={<Icon icon="IcLightning" />}
              path={ROUTES.configurations.children.interests}
              label={translate('configurations.interests')}
            />
            <Divider height="2px" width="250px" />
          </>
        )}
      </S.TabContainer>
      <S.Content>
        <S.OutletWrapper>
          <Outlet />
        </S.OutletWrapper>
        {/* <S.BussinessCardContainer show={show}>
          {isLoading ? (
            <BusinessCardSkeleton />
          ) : (
            <BusinessCard {...cardProps} />
          )}
        </S.BussinessCardContainer> */}
      </S.Content>
      <S.Footer>
        <FooterPoweredBy fixed />
      </S.Footer>
    </S.Wrapper>
  );
};
