/* eslint-disable no-restricted-imports */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */
import {
  Dispatch,
  forwardRef,
  Ref,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
//import { useLocation } from 'react-router-dom';
import { dispatchGAEvent } from 'services/google';
//import { useLikes } from 'useCases/likes';
import { useChat } from 'useCases/chat';
import { useClickOutside, useSession } from 'hooks';
import { useChatContext, useNotificationContext } from 'contexts';
import { UserChat } from 'components/contexts/interactions';
import { UserCard } from 'components/contexts/users';
import { Avatar } from 'components/contexts/users';
import { EventAction, EventCategory } from 'interfaces/analytics';
import { GA_EVENTS } from 'constants/enums';
import { InteractionsTabs } from 'constants/enums';
import { IcNewLike } from '../../../../../assets/icons/interactions';
import * as S from './Message.styles';

interface UseCard {
  name: string;
  userId?: string;
  avatar?: string;
  email?: string;
  country?: any;
  company?: string;
  state?: string;
}

export type UserCardProps = {
  handleOpenPrivateChat: (id: string) => void;
  setTab: (tab: InteractionsTabs) => void;
  isChatDisabled?: boolean;
};

export const Message = forwardRef(
  (
    {
      userIdTest,
      avatar,
      name,
      company,
      email,
      state,
      country,
      message,
      id,
      received,
      createdAt,
      isSended = true,
      isPrivate = false,
      isStaff = false,
      isChatDisabled = false,
      // setTab,
      messageRef,
      likes,
      mentions,
      handleSendReaction,
      handleReceiveReaction,
      realTimeLikes,
      setLikes,
      userId,
      streamId,
      eventId,
      isLastMessage,
      currentUserId,
      setUserCard,
    }: UserChat & {
      setUserCard: Dispatch<SetStateAction<UseCard>>;
      isLastMessage: boolean;
      messageRef: any;
      currentUserId?: string;
    } & UserCardProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    // const messageRef = useRef(null);
    const formattedTime =
      !!createdAt &&
      new Date(createdAt).toLocaleTimeString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });

    let formattedMessage = message;

    if (
      formattedMessage?.includes('https://') ||
      formattedMessage?.includes('http://') ||
      formattedMessage?.includes('www')
    ) {
      formattedMessage = formattedMessage.split(' ').map((word) => {
        if (word?.startsWith('https://') || word?.startsWith('http://')) {
          return (
            <a
              style={{
                wordBreak: 'break-all',
                textDecoration: 'underline',
                color: 'white',
              }}
              href={word}
              target={'_blank'}
              rel={'noreferrer'}
            >
              {' '}
              <i>{word}</i>
            </a>
          );
        }
        if (word?.startsWith('www')) {
          return (
            <a
              style={{
                wordBreak: 'break-all',
                textDecoration: 'underline',
                color: 'white',
              }}
              href={`https://${word}`}
              target={'_blank'}
              rel={'noreferrer'}
            >
              {' '}
              <i>{word}</i>
            </a>
          );
        }
        return <> {word}</>;
      }) as any;
    }

    function CountLike(arr: any) {
      const arr2 = arr.map((item: any) => item?.userId);
      const teste2 = Array.from(new Set(arr2)).filter((e) => e);
      return teste2.length;
    }
    if (isPrivate || !received) {
      return (
        <S.BoxMensagerSend ref={messageRef}>
          <S.MessageSent ref={ref} isPending={!isSended} isReceived={received}>
            {formattedMessage}
            <S.DateSent>{formattedTime}</S.DateSent>
          </S.MessageSent>
          {isPrivate ? (
            <></>
          ) : (
            <div className={'likeContainerSent'}>
              {CountLike(realTimeLikes) > 0 && (
                <>
                  <S.LikeButtonSend className={'liked'}>
                    <IcNewLike />
                  </S.LikeButtonSend>
                  <span style={{ display: 'inline', color: 'gray' }}>
                    {CountLike(realTimeLikes) || ''}
                  </span>
                </>
              )}
            </div>
          )}
        </S.BoxMensagerSend>
      );
    }

    function emitLikeOrDislike() {
      const userAlreadyLikedComment = realTimeLikes?.find(
        (like: any) => like.userId === currentUserId,
      );
      const eventToEmit = userAlreadyLikedComment ? 'dislike' : 'new_like';

      handleSendReaction(eventToEmit, {
        reactType: eventToEmit,
        eventId: process.env.REACT_APP_EVENT_ID,
        streamId,
        messageId: id,
        userId,
        id: userAlreadyLikedComment?.id,
      });
    }

    if (mentions) {
      mentions.forEach((mention, index) => {
        message = message?.replace(
          mention?.username || mention?.name,
          `mention-${index}`,
        );
      });
    }
    const splittedMessage = message?.split(' ');

    let messageElement;
    if (mentions && mentions.length) {
      messageElement = splittedMessage?.map((word, index) => {
        const currentMention = mentions ? mentions[0] : '';
        word = word.replace(/[@]/g, '');
        if (word?.startsWith('mention-') && mentions && mentions?.length) {
          const mentionIndex: string = (word.split('-') as any).at(-1);
          const mentionUserName =
            mentions[Number(mentionIndex)]?.username ||
            mentions[Number(mentionIndex)]?.name;

          return (
            <>
              <S.Mention
                onClick={() =>
                  setUserCard({
                    name: mentionUserName,
                    userId: mentions[Number(index)]?.id,
                    country: mentions[Number(index)]?.country,
                    avatar: mentions[Number(index)]?.avatar,
                    email: mentions[Number(index)]?.email,
                    company: mentions[Number(index)]?.company,
                    state: mentions[Number(index)]?.state,
                  })
                }
              >
                {' '}
                @{mentionUserName}
              </S.Mention>
            </>
          );
        }
        if (
          splittedMessage[index - 1] + ' ' + word ==
          currentMention?.username
        ) {
          return <></>;
        }
        if (word.startsWith('https://')) {
          return (
            <a
              style={{
                wordBreak: 'break-all',
                textDecoration: 'underline',
                color: 'white',
              }}
              href={word}
              target={'_blank'}
              rel={'noreferrer'}
            >
              {word}
            </a>
          );
        }
        return <> {word}</>;
      });
    } else {
      messageElement = message?.split(' ').map((word) => {
        if (word.startsWith('https://') || word.startsWith('http://')) {
          return (
            <a
              style={{
                wordBreak: 'break-all',
                textDecoration: 'underline',
                color: 'white',
              }}
              href={word}
              target={'_blank'}
              rel={'noreferrer'}
            >
              {' '}
              {word}
            </a>
          );
        }
        if (word.startsWith('www')) {
          return (
            <a
              style={{
                wordBreak: 'break-all',
                textDecoration: 'underline',
                color: 'white',
              }}
              href={`https://${word}`}
              target={'_blank'}
              rel={'noreferrer'}
            >
              {' '}
              {word}
            </a>
          );
        }
        return <> {word}</>;
      });
    }

    return (
      <S.MessageWrapper key={id} ref={ref} isStaff={isStaff}>
        <Avatar
          src={avatar}
          alt={name}
          size="small"
          onclick={() =>
            setUserCard({
              name: name,
              userId: userIdTest,
              avatar: avatar,
              country: country,
              email: email,
              company: company,
              state: state,
            })
          }
        />
        <S.Name ref={messageRef}>
          {name}
          <S.Date>{formattedTime}</S.Date>
        </S.Name>
        <S.Message>
          <>{messageElement}</>

          <div className={'likeContainer'}>
            <S.LikeButton
              className={'liked'}
              onClick={emitLikeOrDislike}
              liked={realTimeLikes?.find(
                (like: any) => like?.userId === currentUserId,
              )}
            >
              <IcNewLike />
            </S.LikeButton>
            <span style={{ display: 'inline', color: 'gray' }}>
              {CountLike(realTimeLikes) || ''}
              {/* {realTimeLikes?.filter((l: any) => l)?.length || 0} */}
            </span>
          </div>
        </S.Message>
      </S.MessageWrapper>
    );
  },
);
