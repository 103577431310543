import * as Yup from 'yup';
import messages from 'lib/core/errors';

const { requiredParam } = messages;

const yupPassword = Yup.string().required(requiredParam('password'));

export const schema = Yup.object().shape({
  newPassword: yupPassword,
  rePassword: Yup.string().required(requiredParam('password')),
  token: Yup.string(),
});

export const defaultValues = {
  newPassword: '',
  rePassword: '',
  token: '',
};

export type ChangePassword = Yup.InferType<typeof schema>;
