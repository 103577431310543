import styled, { css, DefaultTheme } from 'styled-components';
import media from 'styled-media-query';

type ButtonItemProps = {
  active: boolean;
};

const buttonItemModifiers = {
  active: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.primary.main};
    color: ${theme.colors.neutral.white};
    font-weight: 400;

    svg {
      opacity: 1;
      width: 18px;
      margin-right: ${theme.spacing['2xs']};
    }
  `,
};

export const ButtonGroupWrapper = styled.div`
  ${({ theme }) => css`
    border: 2px solid ${theme.colors.primary.main};
    transition: background-color 0.2s linear;
    display: inline-flex;
    margin-bottom: ${theme.spacing.lg};
    margin-right: ${theme.spacing.lg};
    margin: 24px 0px 0px 0px;
    /* border-radius: 10px; */
  `}
`;

export const ButtonItem = styled.button<ButtonItemProps>`
  ${({ theme, active }) => css`
    background: ${theme.colors.neutral.black};
    color: ${theme.colors.primary.main};
    /* border-radius: 10px; */
    cursor: pointer;
    padding: ${theme.spacing['2xs']} 14px;
    display: flex;
    align-items: center;
    transition: color 0.2s linear;
    flex-shrink: 0;
    font-family: ${theme.typography.family.primary};
    font-size: ${theme.typography.sizes.sm};
    font-weight: 500;
    outline: none;

    ${media.lessThan('medium')`
    font-size: 13px;
    padding:  ${theme.spacing['2xs']} 8px;
    `}

    svg {
      opacity: 0;
      width: 0;
      transition: opacity 0.2s linear;
      margin-right: 0;
    }

    ${active && buttonItemModifiers.active(theme)};
  `}
`;
