import styled, { css, DefaultTheme } from 'styled-components';
import { InterestProps } from './Interest';

type WrapperProps = Pick<InterestProps, 'active'>;

const wrapperModifiers = {
  active: (theme: DefaultTheme) => css`
    color: ${theme.colors.neutral.white};
    background-color: ${theme.colors.primary.main};
  `,
};

export const Wrapper = styled.button<WrapperProps>`
  ${({ theme, active }) => css`
    appearance: none;
    background-color: ${theme.colors.grayscale.light.info};
    border-radius: ${theme.shapes.borderRadius.lg};
    color: ${theme.colors.grayscale.light.paragraph};
    cursor: pointer;
    font-size: ${theme.typography.sizes.base};
    font-family: ${theme.typography.family.primary};
    line-height: ${theme.typography.lineHeight.base};
    padding: ${theme.spacing.xs} ${theme.spacing.sm};
    text-align: center;
    transition: all 0.2s linear;

    ${active && wrapperModifiers.active(theme)};
  `}
`;
