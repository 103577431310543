// import axios from 'axios';
import { useCallback, useEffect, useState, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { useParams } from 'react-router';
import { useRealTimeSession } from 'useCases/session';
import { useStream, useStreamById } from 'useCases/streams';
import { usePrefixMessage, useTranslate } from 'hooks';
import {
  useChatContext,
  useInteractionsContext,
  useSettingsContext,
  LanguageStream,
  useIntlContext,
  useStreamContext,
  useNotificationContext,
  usePictureInPictureContext,
  useMiniPlayerContext,
} from 'contexts';
import { VirtualRoom } from 'components/contexts/interactions';
import { RedirectMessage } from 'components/contexts/plenary/RedirectMessage/RedirectMessage';
import { Container } from 'components/structure';
import EventOptions from 'styles/overrides/EventOptions.theme';
import { StreamingDetails, StreamState } from 'interfaces/stream';
import {
  InteractionsOpenState,
  InteractionsTabs,
  NotificationTypes,
  Players,
  VideoPlayers,
} from 'constants/enums';
import { Skeleton } from './Stream.skeleton';

export const Stream = () => {
  const { id } = useParams();
  const { languageStream } = useIntlContext();
  const translate = useTranslate();
  // const { streamLanguages } = useStreamContext();
  // const session = useRealTimeSession();

  const { data, isLoading } = useStreamById(id, { language: languageStream });
  const prefixMessage = usePrefixMessage();
  const { room, setShowRoom, setRoom, setPlenary } = useChatContext();
  const notification = useNotificationContext();
  const setViewed = useRef(notification.setViewed);
  const isLiveShopping = false;

  const {
    setIsSpaceFull,
    setHasInteraction,
    setHasTranslationChange,
    setHasStream,
    setStreamPage,
    hasTranslationChange,
  } = useSettingsContext();
  const [videoUrl, setVideoUrl] = useState('');
  const [videoPlayerType, setVideoPlayerType] = useState<
    VideoPlayers | undefined
  >();
  const {
    setHasCollapse,
    setAvailableTabs,
    setOpenState,
    setTab,
  } = useInteractionsContext();
  const {
    streamsBackup,
    setStreamLanguages,
    streamLanguages,
  } = useStreamContext();
  const { setStream } = usePictureInPictureContext();
  const {
    setPlenaryId,
    setIsActive: setIsActiveMiniPlayer,
  } = useMiniPlayerContext();

  useStream(id, { language: languageStream });

  const getVideoInfo = useCallback(
    (): StreamingDetails =>
      streamsBackup
        ?.find((stream) => stream.id === id)
        ?.streamingDetails.find(
          ({ language }) => language === languageStream,
        ) ?? ({} as StreamingDetails),
    [id, languageStream, streamsBackup],
  );

  useEffect(() => {
    setViewed.current(id, NotificationTypes.NEW_LIVE_STREAM);
  }, [id]);

  useEffect(() => {
    if (getVideoInfo().videoUrl) {
      const { videoUrl, videoPlayer } = getVideoInfo();

      setVideoUrl(videoUrl);
      setVideoPlayerType(videoPlayer);
    } else {
      setVideoUrl(data?.stream.streamingDetails.videoUrl ?? '');
      setVideoPlayerType(
        data?.stream.streamingDetails.videoPlayer ?? undefined,
      );
    }
  }, [streamsBackup, data, getVideoInfo]);

  useEffect(() => {
    setHasTranslationChange(streamLanguages.length > 1);
  }, [streamLanguages]);
  useEffect(() => {
    const streamTab = isLiveShopping
      ? [InteractionsTabs.offers]
      : [InteractionsTabs.survey];

    if (isMobile) {
      setIsSpaceFull(true);
    }
    setHasCollapse(true);
    setOpenState(InteractionsOpenState.open);
    setTab(InteractionsTabs.chat);
    if (EventOptions.hasTranslationChange) setHasTranslationChange(true);
    setHasStream(true);
    setPlenary({
      id,
      name: translate('interactions.chatPlenary'),
      message: prefixMessage(data?.stream?.latestMessage, true),
    });
    setAvailableTabs((prevState) => {
      const tabs = [...prevState, ...streamTab];
      return tabs.filter((tab) =>
        isLiveShopping ? tab !== InteractionsTabs.networking : tab,
      );
    });
    setStreamLanguages(data?.stream.languages ?? []);
    return () => {
      setIsSpaceFull(false);
      setHasCollapse(false);
      setOpenState(InteractionsOpenState.closed);
      setHasInteraction(true);
      setPlenary({ id: '', name: '' });
      setHasTranslationChange(streamLanguages.length > 1);
      setHasStream(false);
      setAvailableTabs((prevState) => {
        const tabs = prevState.filter((tab) => !streamTab.includes(tab));
        return isLiveShopping ? [...tabs, InteractionsTabs.networking] : tabs;
      });
    };
  }, [
    streamLanguages,
    data?.stream?.latestMessage,
    data?.stream.languages,
    id,
    prefixMessage,
    setAvailableTabs,
    setHasCollapse,
    setHasStream,
    setIsSpaceFull,
    setOpenState,
    setPlenary,
    setHasInteraction,
    setTab,
    setHasTranslationChange,
    translate,
    setStreamLanguages,
    isLiveShopping,
    streamLanguages,
    hasTranslationChange,
  ]);

  useEffect(() => {
    setHasTranslationChange(streamLanguages.length > 1);
  }, [streamLanguages]);
  useEffect(
    () => () => {
      if (room && !room.isPrivate) {
        setRoom(undefined);
        setShowRoom(false);
      }
    },
    [room, setRoom, setShowRoom],
  );

  // useEffect(() => {
  //   (async () => {
  //     await axios.patch('http://localhost:3000/creator-week-stream-01/audience')
  //   })()
  // }, [])

  useEffect(() => {
    setStream({ id, url: videoUrl });

    if (document.pictureInPictureElement) {
      document.exitPictureInPicture();
    }
  }, [id, setStream, videoUrl]);

  useEffect(() => {
    if (typeof window.jwplayer === 'function' && window.jwplayer(Players.PIP)) {
      window.jwplayer(Players.PIP).remove();
    }
  }, []);

  useEffect(() => {
    setStreamPage(false);

    return () => setStreamPage(true);
  }, [setStreamPage]);

  useEffect(() => {
    setPlenaryId(id);
    setIsActiveMiniPlayer(false);
  }, [id, setIsActiveMiniPlayer, setPlenaryId]);

  if (isLoading) {
    return (
      <Container isSpaceFull>
        <Skeleton />
      </Container>
    );
  }

  return (
    <VirtualRoom
      url={videoUrl}
      watchAgainPlayer={data?.stream.state === StreamState.WatchAgain}
      videoPlayerType={videoPlayerType}
    >
      {data?.stream?.state === StreamState.Finished && <RedirectMessage />}
    </VirtualRoom>
  );
};
