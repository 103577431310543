import { ThemeOptions } from 'interfaces/theme';
import { THEME } from './themes';
import {
  breakpoints,
  typography,
  spacing,
  shapes,
  layers,
  colors,
  sizes,
} from './tokens';

export default {
  colors: THEME?.colors ?? colors,
  breakpoints,
  typography,
  spacing,
  shapes,
  layers,
  sizes,
  options: {} as ThemeOptions,
} as const;
