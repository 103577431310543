import styled, { css } from 'styled-components/macro';
import media from 'styled-media-query';

type ContainerProps = {
  isSpaceFull?: boolean;
};

export const Container = styled.div<ContainerProps>`
  ${({ theme, isSpaceFull }) => css`
    padding: 0 ${theme.spacing.md};
    max-width: ${theme.sizes.portView};
    margin: 0 auto;
    position: relative;
    width: 100%;

    ${media.lessThan('medium')`
      padding: 0 ${theme.spacing.sm};
      width: 100vw;
    `}

    ${isSpaceFull &&
    media.lessThan('large')`
      padding: 0;
    `}
  `}
`;

export const DefaultScroll = css`
  ${({ theme }) => css`
    overscroll-behavior: contain;

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb {
      border-radius: ${theme.shapes.borderRadius.xs};
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px ${theme.colors.grayscale.dark.line};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${theme.colors.grayscale.dark.info};
    }
  `}
`;
