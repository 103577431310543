import { getFont } from 'lib/core/event';
import {
  ThemePartial,
  ThemeCountdownPartial,
  ThemePrivatePartial,
  ThemePublicPartial,
  ThemeCoffeePartial,
  ThemeBusinessCardPartial,
  Colors,
  ThemeOptions,
} from 'interfaces/theme';
import BackgroundError from 'assets/images/core/bayer/background-error.png';
import BackgroundOutlet from 'assets/images/core/bayer/background-outlet.png';
import { ReactComponent as Logo } from 'assets/images/core/bayer/logo.svg';
import BackgroundAccreditationFallbackEn from 'assets/images/pages/bayer/accreditation/bg-fallback-en.png';
import BackgroundAccreditationFallbackEs from 'assets/images/pages/bayer/accreditation/bg-fallback-es.png';
import BackgroundAccreditationFallback from 'assets/images/pages/bayer/accreditation/bg-fallback.png';
import BackgroundCountdown from 'assets/images/pages/bayer/countdown/background.png';

export const colors: Colors = {
  primary: {
    main: '#0089C2',
    hover: '#0089C2',
    active: '#0089C2',
  },
  secondary: {
    main: '#0089C2',
    hover: '#0089C2',
    active: '#0089C2',
  },
  tertiary: {
    main: '#66d9a3',
    active: '#47c885',
    hover: '#5ad297',
  },
  neutral: {
    black: '#E8E8E8',
    filter: 'rgba(0, 0, 0, 0.6)',
    modal: '#ffffff',
    materialVideoOverlay: 'rgba(0, 0, 0, 0.4)',
    white: '#ffffff',
  },
  grayscale: {
    dark: {
      title: 'rgba(0, 0, 0, 1)',
      paragraph: 'rgba(0, 0, 0, 0.8)',
      info: 'rgba(0, 0, 0, 0.5)',
      line: 'rgba(0, 0, 0, 0.2)',
    },
    light: {
      title: '#000000',
      paragraph: 'rgba(0, 0, 0, 0.8)',
      info: 'rgba(0, 0, 0, 0.5)',
      line: 'rgba(0, 0, 0, 0.2)',
    },
  },
  background: {
    dark: {
      input: 'rgba(255, 255, 255, 0.5)',
      button: 'rgba(0, 0, 0, 0.01)',
    },
    light: {
      input: 'rgba(255, 255, 255, 0.5)',
      button: 'rgba(255, 255, 255, 0.01)',
    },
    outlet: '#E8E8E8',
    float: {
      solid: '#DCE0E2',
    },
    public: {
      outlet: '#E8E8E8',
    },
  },
  contextual: {
    info: {
      main: '',
      hover: '',
      active: '',
    },
    success: {
      main: '#38cd3c',
      hover: '#2fc533',
      active: '#22b825',
    },
    attention: {
      main: '#fda700',
      hover: '',
      active: '',
    },
    danger: {
      main: '#ff0202',
      hover: '#cc0202',
      active: '#990303',
    },
    disabled: {
      main: '',
      hover: '',
      active: '',
    },
  },
  skeleton: {
    backgroundColor: '#c4c4c4',
    foregroundColor: 'rgba(255, 255, 255, 0.5)',
  },
  message: {
    light: 'rgba(255, 255, 255, 0.15)',
    dark: 'rgba(0, 0, 0, 0.2)',
  },

  popover: {
    dark: {
      background: '#fff',
    },
    light: {
      background: '#fff',
    },
  },
};

export const typography = {
  family: {
    primary: getFont('Etelka Text Pro'),
    secondary: getFont('Etelka Text Pro'),
  },
};

export const Interactions: ThemePartial = {
  colors: {
    grayscale: {
      dark: colors.grayscale.dark,
      light: {
        ...colors.grayscale.light,
        paragraph: '#000',
        title: '#000',
      },
    },
  },
};

export const BusinessCard: ThemeBusinessCardPartial = {
  colors: {
    grayscale: colors.grayscale,
  },
  options: {
    hasIcons: false,
    hasProfile: false,
  },
};

export const Navbar: ThemePartial = {
  colors: {
    neutral: {
      ...colors.neutral,
      ...{
        black: '#fff',
        white: 'rgba(0, 0, 0, 0.7)',
      },
    },
  },
};

export const Footer: ThemePartial = {
  colors: {
    neutral: {
      ...colors.neutral,
      ...{
        black: '#fff',
        white: 'rgba(0, 0, 0, 0.8)',
      },
    },
    grayscale: {
      dark: {
        ...colors.grayscale.dark,
        info: 'rgba(0, 0, 0, 0.5)',
      },
      light: {
        ...colors.grayscale.light,
        ...{
          paragraph: 'rgba(0, 0, 0, 0.8)',
        },
      },
    },
  },
};

export const Private: ThemePrivatePartial = {
  colors: {
    grayscale: {
      dark: {
        ...colors.grayscale.dark,
        ...{
          paragraph: '#000',
        },
      },
      light: {
        ...colors.grayscale.light,
        ...{
          paragraph: '#fff',
        },
      },
    },
  },
};

export const Public: ThemePublicPartial = {
  colors: {
    background: {
      ...colors.background,
      outlet: '#E8E8E8',
    },
    grayscale: {
      dark: {
        ...colors.grayscale.dark,
        ...{
          paragraph: '#000',
        },
      },
      light: {
        ...colors.grayscale.light,
        ...{
          paragraph: '#fff',
        },
      },
    },
  },

  // TODO: new options align image in accreditation.
  options: {
    backgroundSize: 'inherit',
    backgroundPosition: 'center',
  },
};

// TODO: Ideal não ficar dessa forma
export const Countdown: ThemeCountdownPartial = {
  colors: {
    neutral: {
      ...colors.neutral,
      white: 'rgba(0, 0, 0, 0.8)',
    },
    grayscale: {
      dark: {
        ...colors.grayscale.dark,
        ...{
          paragraph: 'rgba(0, 0, 0, 0.8)',
          title: 'rgba(0, 0, 0, 1)',
        },
      },
      light: colors.grayscale.light,
    },
  },
  options: {
    hasFooterDescription: false,
    hasFooterTitle: true,
    hasSocialsLinks: false,
    hasHashtags: false,
  },
};

export const UserCountdown: ThemePartial = {};

export const Coffee: ThemeCoffeePartial = {};

export const EventOptions: ThemeOptions = {
  hasNotification: false,
};

// Quais items do menu estão ativos
export const MenuLabels = [
  'navbar.userMenu.profile',
  'navbar.plenary',
  'navbar.expo',
  'navbar.schedule',
];

export const LogoWhite = Logo;

export {
  BackgroundError,
  BackgroundOutlet,
  Logo,
  BackgroundAccreditationFallbackEn,
  BackgroundAccreditationFallbackEs,
  BackgroundAccreditationFallback,
  BackgroundCountdown,
};
