import { footerPT, footerEN, footerES } from './footer';
import { navBarPT, navBarEN, navBarES } from './navbar';
import { notificationEN, notificationPT, notificationES } from './notification';

export const structurePT = {
  ...navBarPT,
  ...footerPT,
  ...notificationPT,
};

export const structureEN = {
  ...navBarEN,
  ...footerEN,
  ...notificationEN,
};

export const structureES = {
  ...navBarES,
  ...footerES,
  ...notificationES,
};
