import { isSafari } from 'react-device-detect';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { SubTitle } from 'components/structure/Typography/Typography';
import { SponsorItemProps } from './SponsorItem';

export const Content = styled.div`
  ${({ theme }) => css`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${theme.spacing.md};
    width: 180px;
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    flex-direction: column;

    ${SubTitle} {
      font-size: ${theme.typography.sizes.md};
    }

    & + div {
      margin-top: ${theme.spacing.lg};
      padding-top: ${theme.spacing.md};
      border-top: 1px solid ${theme.colors.grayscale.dark.line};
    }
  `}
`;

export const Logo = styled.img``;

export const Banners = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    grid-gap: ${theme.spacing.md};

    ${isSafari &&
    css`
      grid-gap: unset;
      margin: -${theme.spacing.md} 0 0 -${theme.spacing.md};

      & > * {
        margin: ${theme.spacing.md} 0 0 ${theme.spacing.md} !important;
      }
    `}
  `}
`;

const bannerModifiers = {
  small: () => css`
    max-width: 215px;

    ${media.lessThan('large')`
      height: 140px;
      max-width: 140px;
    `}
  `,
  medium: () => css`
    max-width: 50%;

    ${media.lessThan('large')`
      height: 100%;
      max-width: 260px;
    `}
  `,
  large: () => css`
    max-width: 100%;
    object-fit: cover;

    ${media.lessThan('large')`
      height: 100%;
    `}
  `,
};

export const Banner = styled.img<Pick<SponsorItemProps, 'type'>>`
  ${({ theme, type }) => css`
    height: 202px;
    border-radius: ${theme.shapes.borderRadius.sm};
    //width: 100%;
    background: #e1dcd1;

    ${media.lessThan('medium')`
      width: 100%;
    `}

    ${bannerModifiers[type]}
  `}
`;
