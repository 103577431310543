import { Link } from 'react-router-dom';
// import { useTheme } from 'hooks';
import { THEME } from 'styles/themes';
import { ROUTES } from 'constants/urls';
import * as S from './Logo.styles';

export type LogoProps = {
  url?: string;
  alt?: string;
  center?: boolean;
  large?: boolean;
  medium?: boolean;
  hideOnMobile?: boolean;
  light?: boolean;
};

export const Logo = ({
  center = false,
  large = false,
  medium = false,
  hideOnMobile = false,
  light = false,
  url,
  alt,
}: LogoProps) => (
  // const { options } = useTheme();

  <Link to={ROUTES.app.getLink('home')}>
    <S.Wrapper
      center={center}
      large={large}
      medium={medium}
      hideOnMobile={hideOnMobile}
      light={light}
    >
      {url ? (
        <img src={url} alt={alt} />
      ) : (
        //TODO: verify this
        // options?.logo ||
        <THEME.Logo />
      )}
    </S.Wrapper>
  </Link>
);
