import * as Yup from 'yup';
import { passwordStrengthForVale } from 'lib/contexts/input';
import messages from 'lib/core/errors';
import { getEventThemeName } from 'lib/core/event';
import EventOptions from 'styles/overrides/EventOptions.theme';
import { DefaultValues, ShapeSchema } from 'interfaces/validations';
import { ProjectNames, BRAZIL_COUNTRY_CODE } from 'constants/enums';

const { requiredParam } = messages;

const values: DefaultValues = {
  firstName: '',
  lastName: '',
  uuid: '',
  country: '',
  company: '',
  profile: {
    area: '',
  },
  preferableLanguage: '',
  state: '',
  inviteCode: '',
  ageGroup: '',
  password: '',
  rePassword: '',
  terms: false,
  acceptedShareData: false,
  acceptedNews: false,
};

// eslint-disable-next-line no-useless-escape
//const validateDuplicateURL = /^(https:\/\/|http:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-])*\/?$/;

// const adicionalFields = {
//   //ask1: Yup.string().required(requiredParam('ask1')),
//   // ask2: Yup.string().required(requiredParam('ask2')),
//   // ask3: Yup.string().required(requiredParam('ask3')),
// };

export const shape: ShapeSchema = {
  firstName: Yup.string().required(requiredParam('firstName')),
  lastName: Yup.string().required(requiredParam('lastName')),
  company: Yup.string().required(requiredParam('company')),
  ask3: Yup.string().required(requiredParam('ask3')),
  uuid: Yup.string().email().required(requiredParam('uuid')),
  email: Yup.string().email().required(requiredParam('uuid')),
  password: Yup.string().required(requiredParam('password')),
  rePassword: Yup.string().required(requiredParam('rePassword')),
  ask1: Yup.string().required(requiredParam('ask1')),
  ask2: Yup.string().required(requiredParam('ask2')),
  ask4: Yup.string().when('ask2', {
    is: '6',
    then: Yup.string().required(requiredParam('ask4')),
  }),
  terms: Yup.bool().oneOf([true], requiredParam('terms')),
  country: EventOptions.hasCountry
    ? Yup.string().required(requiredParam('country'))
    : Yup.string(),
  state: EventOptions.hasCountry
    ? Yup.string().when('country', {
        is: BRAZIL_COUNTRY_CODE.toString(),
        then: Yup.string().required(requiredParam('state')),
      })
    : Yup.string(),
  referrer: Yup.string(),
};

if (getEventThemeName() === ProjectNames.VALE) {
  shape.numberOfPeopleWatchingWithMe = Yup.number()
    .typeError(requiredParam('numberOfPeopleWatchingWithMe'))
    .min(0, requiredParam('numberOfPeopleWatchingWithMe'))
    .required(requiredParam('numberOfPeopleWatchingWithMe'));

  values.numberOfPeopleWatchingWithMe = 0;

  shape.password = Yup.string()
    .matches(
      passwordStrengthForVale.hasOnlySixNumbers,
      messages.invalidParam('passwordHasSixNumbers'),
    )
    .required(requiredParam('password'));
}

export const schema = Yup.object().shape(shape);

// export const schemaHasAdicionalFields = (res: boolean) =>
//   Yup.object().shape(res ? { ...shape, ...adicionalFields } : shape);

export const defaultValues = values;
