import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { Paragraph } from 'components/structure/Typography/Typography';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    max-width: 330px;
    margin: 0 auto;

    ${Paragraph} {
      text-align: center;
    }

    > div {
      margin: ${theme.spacing.xl} auto ${theme.spacing.xl};
    }

    span {
      line-height: ${theme.spacing.md};
      color: ${theme.colors.grayscale.light.paragraph};
    }

    ${media.lessThan('large')`
      max-width: 100vw;
      padding: 0 ${theme.spacing.lg};

      ${Paragraph} {
        margin-bottom: 0;
      }
    `}
  `}
`;
