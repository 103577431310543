import en from './en.json';
import es from './es.json';
import pt from './pt-br.json';

export const streamPT = {
  stream: pt,
};

export const streamEN = {
  stream: en,
};

export const streamES = {
  stream: es,
};
