import * as Yup from 'yup';
import { passwordStrength } from 'lib/contexts/input';
import messages from 'lib/core/errors';
import { BRAZIL_COUNTRY_CODE } from 'constants/enums';
import { enabledCities } from './BusinessCardForm.mock';

const { requiredParam, paramsDontMatch, invalidParam } = messages;

export const schema = {
  firstName: Yup.string().required(requiredParam('firstName')),
  lastName: Yup.string().required(requiredParam('lastName')),
  uuid: Yup.string().email().required(requiredParam('uuid')),
  email: Yup.string().email().required(requiredParam('uuid')),
  professionalId: Yup.string().required(requiredParam('professionalId')),
  country: Yup.string().required(requiredParam('country')),
  workshop: Yup.object().when('country', {
    is: (val: string) => enabledCities.includes(parseInt(val)),
    then: Yup.object({
      first: Yup.string().required(requiredParam('workshop')),
      second: Yup.string().required(requiredParam('workshop')),
    }),
    otherwise: Yup.object({
      first: Yup.string().required(requiredParam('workshop')),
      second: Yup.string().required(requiredParam('workshop')),
      third: Yup.string().required(requiredParam('workshop')),
    }),
  }),
  preferableLanguage: Yup.string().required(requiredParam('language')),
  state: Yup.string().when('country', {
    is: BRAZIL_COUNTRY_CODE.toString(),
    then: Yup.string().required(requiredParam('state')),
  }),
  password: Yup.string()
    .min(8, invalidParam('password'))
    .required(requiredParam('password'))
    .matches(
      passwordStrength.hasNumber,
      messages.invalidParam('passwordHasNumber'),
    )
    .matches(
      passwordStrength.hasSymbol,
      messages.invalidParam('passwordHasSymbol'),
    )
    .matches(
      passwordStrength.hasCapitalLetter,
      messages.invalidParam('passwordHasCapitalLetter'),
    )
    .matches(
      passwordStrength.hasLowercaseLetter,
      messages.invalidParam('passwordHasLowercaseLetter'),
    ),
  rePassword: Yup.string()
    .required(requiredParam('rePassword'))
    .oneOf([Yup.ref('password'), null], paramsDontMatch('password')),
  terms: Yup.bool().oneOf([true], requiredParam('terms')),
};

export const schemaForNotBayer = Yup.object().shape({
  profile: Yup.object({
    specialty: Yup.string().required(requiredParam('specialty')),
  }),
  healthProfessional: Yup.bool().oneOf(
    [true],
    requiredParam('healthProfessional'),
  ),
  ...schema,
});

export const schemaForBayer = Yup.object().shape(schema);

export const defaultValues = {
  firstName: '',
  lastName: '',
  uuid: '',
  country: '',
  profile: {
    specialty: '',
  },
  professionalId: '',
  workshop: {
    first: '',
    second: '',
    third: '',
  },
  preferableLanguage: '',
  state: '',
  password: '',
  rePassword: '',
  terms: false,
};
