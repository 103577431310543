import { Outlet } from 'react-router-dom';
import { useEvent } from 'useCases/events';
import {
  useIntlContext,
  ThemeCustomProvider,
  useBusinessCardContext,
} from 'contexts';
import { BusinessCard } from 'components/contexts/users';
import { Logo, CFCode } from 'components/structure';
import * as PublicTheme from 'styles/overrides/Public.theme';
import { THEME } from 'styles/themes';
import { PublicContexts } from './Public.contexts';
import * as S from './Public.styles';

const bgs = {
  pt: THEME.BackgroundAccreditationFallback,
  en: THEME.BackgroundAccreditationFallback,
};

type BusinessCardConsumerProps = {
  background?: string;
};

const BusinessCardConsumer = ({ background }: BusinessCardConsumerProps) => {
  const { show, cardProps } = useBusinessCardContext();
  return (
    <>
      <S.HeroWrapper>
        <S.BlurEffect
          bgUrl={background}
          role="img"
          aria-label="background image"
          blur={show && !!cardProps?.name}
        />
        <S.BusinessCardWrapper show={show && !!cardProps?.name}>
          <BusinessCard {...cardProps} />
        </S.BusinessCardWrapper>
      </S.HeroWrapper>
    </>
  );
};

const Public = () => {
  const { data } = useEvent();
  const { language } = useIntlContext();

  return (
    <PublicContexts>
      <ThemeCustomProvider {...PublicTheme}>
        <S.Wrapper>
          <S.Content>
            <S.OutletWrapper>
              <S.WrapperLogo>
                <Logo url={data?.event?.logo?.link} alt={data?.event?.name} />
              </S.WrapperLogo>
              <Outlet />
              <CFCode isDesktop marginTop />
            </S.OutletWrapper>
            <BusinessCardConsumer background={bgs[language]} />
          </S.Content>
        </S.Wrapper>
      </ThemeCustomProvider>
    </PublicContexts>
  );
};

export default Public;
