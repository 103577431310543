/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import styled, { css, DefaultTheme } from 'styled-components';
import media from 'styled-media-query';
import * as UserCardStyles from 'components/contexts/users/UserCard/UserCard.styles';

const wrapperModifiers = {
  isStaff: () => css`
    background: transparent;
    opacity: 2;
    width: 95%;
    margin-left: 7px;
    outline: solid 1.5px #d6aa23;
    border-radius: 6px;
  `,
};

export const MessageWrapper = styled.div<{ isStaff: boolean }>`
  ${({ theme, isStaff }) => css`
    display: grid;
    grid-template-columns: 32px 1fr;
    grid-template-rows: 12px 1fr;
    grid-gap: 0 ${theme.spacing.xs};
    justify-items: flex-start;
    position: relative;
    flex: 1 1 40px;
    padding: ${theme.spacing['2xs']} ${theme.spacing.sm} 2px;
    &:not(:first-child) {
      margin-bottom: ${theme.spacing.xs};
    }
    ${isStaff && wrapperModifiers.isStaff()}
  `}
`;

export const BoxMensagerSend = styled.div`
  margin: 5px 0;
  display: flex;
  flex-direction: column;
  .likeContainerSent {
    display: flex;
    flex: 0 0;
    padding: 5px 10px;
    justify-content: flex-end !important;
  }
`;

type MessageSentProps = {
  isPending?: boolean;
  isReceived?: boolean;
  isStaff?: boolean;
};

export const Message = styled.p<MessageSentProps>`
  /* color: ${({ theme }) => theme.colors.grayscale.light.paragraph}; */
  color: ${({ theme }) => theme.colors.neutral.white};
  font-family: ${({ theme }) => theme.typography.family.primary};
  font-size: ${({ theme }) => theme.typography.sizes.sm};
  line-height: ${({ theme }) => theme.typography.lineHeight.base};
  max-width: 90%;
  /* width: 90%; */
  min-width: 90%;
  word-wrap: break-word;
  /* word-break: break-all; */
  .likeContainer {
    display: flex;
    flex: 0 0;
  }

  // dentro da caixa com curtir a esquerda
  /* .likeContainerSent {
    display: flex;
    flex: 0 0;
    padding: 5px 0px;
    justify-content: flex-start !important;
  } */

  ${UserCardStyles.Wrapper} {
    visibility: visible;
    opacity: 1;
    /* transform: translate(-50%, -44%); */
    transition: opacity 0.3s ease-in-out;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 9999 !important;

    ${media.lessThan('medium')`
        left: 0;
        top: -21px;
        right: 0;
        /* bottom: 100%; */
        transform: unset;
      `}

    ${UserCardStyles.Container} {
      transition: transform 0.3s ease-in-out;
      z-index: 9999;
    }
  }
`;

const messageSentModifiers = {
  received: (theme: DefaultTheme) => css`
    align-self: flex-start;
    background: ${theme.colors.neutral.black};
    color: ${theme.colors.neutral.white};
  `,
};

export const MessageSent = styled(Message)`
  ${({ theme, isPending, isReceived }) => css`
    display: flex;
    min-width: auto;
    color: ${theme.colors.neutral.white};
    font-weight: normal;
    flex-direction: column;
    align-self: flex-end;
    background: ${theme.colors.primary.active};
    border-radius: ${theme.shapes.borderRadius.sm};
    padding: ${theme.spacing.xs} ${theme.spacing.sm};
    opacity: ${isPending ? 0.5 : 1};
    flex: 2 0 40px;
    margin: 0 ${theme.spacing.sm};
    word-wrap: break-word !important;
    /* word-break: break-all !important; */
    /* letter-spacing: .05rem; */

    &:not(:first-child) {
      margin-bottom: ${theme.spacing.xs};
    }

    ${isReceived && messageSentModifiers.received(theme)};
  `}
`;

export const Date = styled.time`
  ${({ theme }) => css`
    margin-left: 5px;
    color: ${theme.colors.neutral.white};
    font-family: ${theme.typography.family.primary};
    font-size: 10px;
    line-height: ${theme.typography.lineHeight.sm};
    text-align: left;
  `}
`;

export const DateSent = styled.small`
  ${({ theme }) => css`
    /* color: ${theme.colors.grayscale.dark.line}; */
    color: ${theme.colors.neutral.white};
    font-family: ${theme.typography.family.primary};
    font-size: 10px;
    line-height: ${theme.typography.lineHeight.sm};
    text-align: right;
    margin-right: 1rem;
  `}
`;

export const Name = styled.small`
  color: ${({ theme }) => theme.colors.neutral.white};
  font-family: ${({ theme }) => theme.typography.family.primary};
  font-size: ${({ theme }) => theme.typography.sizes.xs};
  line-height: ${({ theme }) => theme.typography.lineHeight.sm};
  font-weight: 600;
  min-height: 20px;
  max-width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const LikeButton = styled.button<any>`
  @keyframes expand {
    0% {
      /* transform: scale(0); */
      height: 1%;
      width: 1%;
    }
    100% {
      /* transform: scale(20); */
      height: 100%;
      width: 100%;
    }
  }
  display: block;
  background-color: ${(props) => (props.liked ? ' #D6AA23 ' : '#d0d0d0')};
  /* padding: .5rem; */
  width: 25px;
  height: 25px;
  border-radius: 9999px;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  margin-right: 0.3rem;
  transition: transform cubic-bezier(0.59, 2, 0.41, 3) 0.2s, background-color 0s;
  transition-delay: 0ms, ${(props) => (!props.liked ? '0s' : '.2s')};
  svg {
    color: #ffffff !important;
    margin: auto;
    ${(props) =>
      props.liked
        ? css`
            filter: brightness(100%) invert(1);
          `
        : ''}

    position: absolute;
    top: 25%;
    left: 25%;
  }
  ${(props) =>
    props.liked
      ? css`
          &:before {
            content: '';
            background-color: #d6aa23 !important;
            height: 1%;
            width: 1%;
            border-radius: 9999px;
            animation: expand 0.1s forwards;
            margin: auto;
            z-index: -9;
          }
        `
      : ''}
  &:hover {
    transform: scale(1.2) rotate(-20deg);
    /* background-color: rgb(55, 95, 236); */
  }
`;

export const LikeButtonSend = styled.div<any>`
  display: block;
  background-color: ${(props) => (props.liked ? ' #D6AA23 ' : '#d0d0d0')};
  /* padding: .5rem; */
  width: 25px;
  height: 25px;
  border-radius: 9999px;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  margin-top: -15px; // fora da caixa, metade dentro
  /* margin-top: 0; // fora da caixa , totalmente fora */
  /* cursor: auto; */
  margin-right: 0.3rem;
  z-index: 25;

  svg {
    color: #ffffff !important;
    margin: auto;
    position: absolute;
    top: 25%;
    left: 25%;
  }
`;
export const test = styled.div``;

export const Mention = styled.strong`
  /* background-color: #1288ff91; */
  color: #0f6ac5b7;
  /* line-height: 2rem; */
  /* padding: .05rem .2rem; */
  /* border-radius: 4px; */
  cursor: pointer;
`;

export const div = styled.a`
  word-break: break-all;
  text-decoration: underline;
`;
