import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { Wrapper as ButtonWrapper } from 'components/structure/Button/Button.styles';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 160px;
    height: 305px;
    padding: ${theme.spacing.xs};
    background: #fefefe;
    border-radius: ${theme.shapes.borderRadius.sm};
    transition: all 0.2s linear;
    box-shadow: 0px 8px 20px rgba(29, 60, 133, 0.1);

    ${ButtonWrapper} {
      height: 30px;
      border-color: ${theme.colors.primary.main};
      color: ${theme.colors.primary.main};

      &:hover {
        background: ${theme.colors.primary.main};
        color: ${theme.colors.neutral.white};
      }

      ${media.lessThan('small')`
        height: 24px;
      `}
    }

    ${media.lessThan('large')`
      width: 100%;
      flex-direction: row;
      height: 111px;
      padding: calc(${theme.spacing.sm} - 2px) ${theme.spacing.xs};
    `}
  `}
`;

export const WrapperImage = styled.div`
  display: flex;
  align-items: center;
  height: auto;
`;

export const Image = styled.img`
  height: 134px;
  width: 100%;

  ${media.lessThan('large')`
    height: 80px;
    width: 80px;
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: ${theme.spacing.xs};

    ${media.lessThan('large')`
      margin-left: ${theme.spacing.sm};
      margin-top: 0;
    `}
  `}
`;

const EllipsisMultiLine = css`
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Name = styled.strong`
  ${({ theme }) => css`
    color: ${theme.colors.primary.main};
    font-size: ${theme.typography.sizes.sm};
    margin-bottom: ${theme.spacing.xs};

    ${EllipsisMultiLine}
  `}
`;

export const Description = styled.small`
  ${({ theme }) => css`
    color: ${theme.colors.grayscale.light.paragraph};
    font-size: ${theme.typography.sizes.xs};
    display: -webkit-box;
    margin-bottom: ${theme.spacing.xs};

    ${EllipsisMultiLine}
    line-clamp: 3;
    -webkit-line-clamp: 3;

    ${media.lessThan('large')`
      line-clamp: 1;
      -webkit-line-clamp: 1;
    `}
  `}
`;

export const BottomContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  flex: 1;

  ${media.lessThan('large')`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  `}
`;

export const Price = styled.strong`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.xs};
    color: ${theme.colors.grayscale.light.paragraph};

    ${media.lessThan('large')`
      margin-bottom: calc(${theme.spacing['2xs']} - 2px);
    `}
  `}
`;
