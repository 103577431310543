import { ThemeProvider } from 'styled-components';
import { useTheme } from 'hooks';
import { WithChildren } from 'interfaces/children';
import {
  ThemePartial,
  ThemePrivatePartial,
  ThemeCountdownPartial,
} from 'interfaces/theme';

type ThemeProps = WithChildren &
  ThemePartial &
  ThemePrivatePartial &
  ThemeCountdownPartial;

export const ThemeCustomProvider = ({
  children,
  colors,
  typography,
  options,
}: ThemeProps) => {
  const {
    breakpoints,
    sizes,
    layers,
    shapes,
    spacing,
    typography: typographyData,
    colors: colorsData,
    options: optionsData,
  } = useTheme();
  return (
    <ThemeProvider
      theme={{
        breakpoints,
        sizes,
        layers,
        spacing,
        typography: {
          ...typographyData,
          ...(typography || {}),
        },
        shapes,
        colors: {
          ...colorsData,
          ...(colors || {}),
        },
        options: {
          ...optionsData,
          ...(options || {}),
        },
      }}
    >
      {children}
    </ThemeProvider>
  );
};
