import { Link } from "react-router-dom";
import { BackButtonContainer } from "./styles";

export function BackButton(props: { href?: string }) {
    return (
        <BackButtonContainer>
            <Link className="backlink" to={props.href ?? '/'} style={{ textDecoration: 'none' }}><div style={{ margin: '1rem 1rem 1rem 0', cursor: 'pointer' }}>
                <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="sc-hYAvag ldFWcE">
                    <path d="M9.24268 13.6568L7.82846 15.071L0.757367 7.99999L7.82843 0.928926L9.24265 2.34314L3.58582 7.99997L9.24268 13.6568Z" fill="currentColor">
                    </path>
                </svg>
                <span style={{ marginLeft: '1rem' }}>Voltar</span>
            </div>
            </Link>
        </BackButtonContainer>
    )
}