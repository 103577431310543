import { motion } from 'framer-motion/dist/framer-motion';
import styled, { css, DefaultTheme } from 'styled-components';

type ControlProps = {
  hide: boolean;
};

type WrapperProps = {
  isMiniPlayer: boolean;
};

const controlModifiers = {
  hide: () => css`
    opacity: 0;
    pointer-events: none;
  `,

  show: () => css`
    opacity: 1;
    pointer-events: all;
  `,
};

const wrapperModifiers = {
  enableMiniPlayer: (theme: DefaultTheme) => css`
    position: fixed;
    inset: auto ${theme.spacing.sm} ${theme.spacing.sm} auto;
    max-width: 400px;
    width: 100%;
    height: 225px;
    z-index: ${theme.layers.aboveTheTop};
    overflow: hidden;
  `,
};

export const Wrapper = styled(motion.div)<WrapperProps>`
  ${({ theme, isMiniPlayer = false }) => css`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    overflow: hidden;

    .react-player {
      > div {
        aspect-ratio: 16 / 9;

        > iframe {
          width: 100%;
          height: calc(100% + 340px);
          position: absolute;
          top: -170px;
          bottom: 0;
          left: 0;
          right: 0;
          border: 0;
        }
      }
    }

    ${isMiniPlayer && wrapperModifiers.enableMiniPlayer(theme)};
  `}
`;

export const Controls = styled.div<ControlProps>`
  ${({ theme, hide = false }) => css`
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    opacity: 1;
    pointer-events: all;
    transition: opacity 300ms;
    z-index: ${theme.layers.base};
    background: ${theme.colors.neutral.materialVideoOverlay};

    ${hide && controlModifiers.hide()};
  `}
`;

export const ControlItem = styled.div`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.xs};
    display: flex;
    align-items: center;

    svg {
      transition: transform 300ms;
    }

    &:hover {
      svg {
        transform: scale(1.2);
      }
    }

    &:nth-child(2) {
      flex: 1;
    }
  `}
`;

export const Control = styled.button`
  ${({ theme }) => css`
    padding: ${theme.spacing.xs};
  `}
`;

export const Play = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const ControlsWrapper = styled.div<{ hasMiniPlayer?: boolean }>`
  ${({ hasMiniPlayer = false }) => css`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: all;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 300ms;

    ${Controls} {
      justify-content: ${hasMiniPlayer ? 'space-around' : 'start'};
    }

    ${ControlItem} {
      &:nth-child(2) {
        flex: ${hasMiniPlayer ? 'inherit' : 1};
      }
    }

    &:hover {
      ${Controls} {
        ${controlModifiers.show()}
      }
    }
  `}
`;

export const VolumeBarWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: -85px;
  transform: translateX(-50%);
`;

export const Range = styled.input`
  ${({ theme }) => css`
    appearance: none;
    vertical-align: middle;
    outline: none;
    border: none;
    padding: 0;
    background: none;
    height: 6px;
    border-radius: ${theme.shapes.borderRadius.md};
    background: ${theme.colors.neutral.white}
      linear-gradient(
        ${theme.colors.primary.main},
        ${theme.colors.primary.main}
      )
      no-repeat;

    &::-webkit-slider-runnable-track {
      background-color: ${theme.colors.neutral.white};
      border: none;
      background: transparent;
    }

    &[disabled]::-webkit-slider-runnable-track {
      border: 1px solid ${theme.colors.neutral.white};
      background-color: transparent;
      opacity: 0.4;
    }

    &::-moz-range-track {
      background-color: transparent;
      height: 6px;
      border-radius: 3px;
      border: none;
    }

    &::-ms-track {
      color: transparent;
      border: none;
      background: none;
      height: 6px;
    }

    &::-ms-fill-lower {
      background-color: ${theme.colors.neutral.white};
      border-radius: 3px;
    }

    &::-ms-fill-upper {
      background-color: ${theme.colors.neutral.white};
      border-radius: 3px;
    }

    &::-ms-tooltip {
      display: none;
    }

    &::-moz-range-thumb {
      border-radius: ${theme.shapes.borderRadius.lg};
      height: 18px;
      width: 18px;
      border: none;
      background: none;
      background-color: ${theme.colors.primary.main};
    }

    &:active::-moz-range-thumb {
      outline: none;
    }

    &::-webkit-slider-thumb {
      appearance: none !important;
      border-radius: ${theme.shapes.borderRadius.full};
      background-color: ${theme.colors.primary.main};
      height: 18px;
      width: 18px;
    }

    &[disabled]::-webkit-slider-thumb {
      background-color: transparent;
      border: 1px solid ${theme.colors.neutral.white};
    }

    &:active::-webkit-slider-thumb {
      outline: none;
    }

    &::-ms-thumb {
      border-radius: 100%;
      background-color: ${theme.colors.primary.main};
      height: 18px;
      width: 18px;
      border: none;
    }

    &:active::-ms-thumb {
      border: none;
    }
  `}
`;

export const ProgressBar = styled(Range)<{ percent: number }>`
  width: 100%;
  background-size: ${({ percent }) => `${percent}%`} 100%;
`;

export const VolumeBar = styled(Range)`
  transform: rotate(270deg);
  opacity: 0;
  visibility: hidden;
  transition: visibility 300ms, opacity 300ms;
  background-size: ${({ value }) => `${value}%`} 100%;
`;

export const VolumeWrapper = styled.div`
  position: relative;

  &:hover {
    ${VolumeBar} {
      opacity: 1;
      visibility: visible;
    }
  }
`;
