import styled, { css } from 'styled-components';
import * as Icons from 'assets/icons';

export type IconsKeys = keyof typeof Icons;

export type IconProps = {
  height?: string;
  width?: string;
  color?: string;
  icon?: IconsKeys;
};

export const Icon = styled.svg.attrs<IconProps>(({ icon = 'IcAbout' }) => ({
  as: Icons[icon],
}))<IconProps>`
  ${({ theme, width, height, color }) => css`
    width: ${width ?? theme.spacing.md};
    height: ${height ?? theme.spacing.md};
    color: ${color ?? theme.colors.primary.main};
  `}
`;
