import styled from 'styled-components';

export const ForceInstagramRegisterComponent = styled.div``;
export const BackButtonContainer = styled.div`
  * {
    text-decoration: none;
    color: white;
    &:active {
      color: white;
    }
  }
`;
