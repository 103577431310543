import en from './en.json';
import es from './es.json';
import pt from './pt-br.json';

export const errorGuardPT = {
  errorGuard: pt,
};

export const errorGuardEN = {
  errorGuard: en,
};

export const errorGuardES = {
  errorGuard: es,
};
