import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Country, useCountries } from 'useCases/assets';
import { useEventById } from 'useCases/events';
import { useUpdateUser, useUserById } from 'useCases/users';
import { useEnv, useTranslate, useToast, useThemeOptions } from 'hooks';
import { setPasswordMinLength } from 'lib/contexts/accreditation';
import { setReadOnlyDefaultValue } from 'lib/contexts/input';
import { getEventThemeName } from 'lib/core/event';
import { retrieveUserSession } from 'lib/core/session';
import { Language, useBusinessCardContext, useIntlContext } from 'contexts';
import { brazilianStates } from 'components/contexts/accreditation/BusinessCardForm/Default/BusinessCardForm.mock';
import { TextField, PasswordField, CustomSelect } from 'components/form';
import {
  Button,
  Form,
  MediaMatch,
  FooterPoweredBy,
  TrickSpace,
} from 'components/structure';
import EventOptions from 'styles/overrides/EventOptions.theme';
import { Area, User } from 'interfaces/user';
import {
  BRAZIL_COUNTRY_CODE,
  LINKEDIN_DEFAULT_URL,
  ProjectNames,
} from 'constants/enums';
import { Skeleton } from './ConfigurationsPersonalData.skeleton';
import * as S from './ConfigurationsPersonalData.styles';
import {
  schema,
  defaultValues,
} from './ConfigurationsPersonalData.validations';

type FormModel = {
  rePassword: string;
} & User;

export const ConfigurationsPersonalData = () => {
  const linkedinInputRef = useRef<HTMLInputElement | null>(null);
  const { setLanguage } = useIntlContext();
  const { themeName } = useThemeOptions();
  const { eventId } = useEnv();
  const { isLoading, data } = useEventById(eventId);
  const { isPending, updateUser } = useUpdateUser();
  const { setCardProps, setShow } = useBusinessCardContext();
  const { id: userId } = retrieveUserSession();
  const userById = useUserById(userId!);
  const { setToast } = useToast();
  const translate = useTranslate();
  const { data: countriesData } = useCountries();
  // const languages = useMemo(
  //   () => [new Option('English', 'en'), new Option('Português', 'pt')],
  //   [],
  // );

  const {
    errors,
    register,
    handleSubmit,
    watch,
    reset,
    control,
  } = useForm<FormModel>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const countries = useMemo(
    () =>
      countriesData?.map(({ label, value }) => ({
        label,
        value: value.toString(),
      })) || [],
    [countriesData],
  );

  const profiles = useMemo(
    () =>
      data?.event.profiles.map(({ value, id }) => ({
        value: id,
        label: value,
      })) || [],
    [data?.event.profiles],
  );

  const { hasPasswordStrength } = EventOptions;

  const firstName = watch('firstName');
  const lastName = watch('lastName');
  const email = watch('email');
  const profile = watch('profile');
  const linkedIn = watch('linkedInUrl');
  const countryForm = watch('country');
  const stateForm = watch('state');

  const country = countries?.find(({ value }) => value === countryForm);
  const state = brazilianStates.find(({ value }) => value === stateForm)?.label;
  const user = userById?.data?.user;
  const image = user?.avatar;
  const hasLinkedInField = true;

  useEffect(() => {
    if (linkedinInputRef.current && hasLinkedInField) {
      setReadOnlyDefaultValue(linkedinInputRef.current || LINKEDIN_DEFAULT_URL);
    }
  }, [hasLinkedInField]);

  const onSubmit = async (data: User) => {
    if (data.linkedInUrl === LINKEDIN_DEFAULT_URL) {
      data.linkedInUrl = '';
    }
    const userData = await updateUser(data);
    if (userData) {
      setToast({
        type: 'success',
        hasChat: false,
        title: 'Sucesso',
        description: 'Dados pessoais alterados com sucesso!',
      });
    }

    if (data.preferableLanguage) {
      setLanguage(data.preferableLanguage as Language);
    }

    if (data.preferableLanguage == 'pt') {
      setToast({
        type: 'success',
        hasChat: false,
        title: 'Sucesso',
        description: 'Dados pessoais alterados com sucesso!',
      });
    }
    if (data.preferableLanguage == 'en') {
      setToast({
        type: 'success',
        hasChat: false,
        title: 'Success',
        description: 'Personal data changed successfully!',
      });
    }
  };

  useEffect(() => {
    if (user) {
      if (!user.linkedInUrl) {
        delete user.linkedInUrl;
      }
      let resetContext = {
        ...user,
      };
      if (getEventThemeName() !== ProjectNames.VALE) {
        resetContext = {
          ...resetContext,
          country: (user.country as Country)?.value?.toString() || '',
          profile: { area: ((user.profile?.area as unknown) as Area)?.id },
        };
      }
      reset(resetContext);
    }
  }, [user, reset]);

  useEffect(() => {
    setCardProps({
      image,
      name: firstName ? `${firstName} ${lastName}` : undefined,
      email,
      profile: profiles?.find(({ value }) => value === profile?.area)?.label,
      linkedIn,
      country,
      state,
    });
  }, [
    setCardProps,
    image,
    firstName,
    lastName,
    email,
    country,
    linkedIn,
    profile?.area,
    countries,
    state,
    profiles,
  ]);

  useEffect(() => {
    setShow(true);
    return () => setShow(false);
  }, [setShow]);

  if (isLoading || userById.isLoading) {
    return <Skeleton />;
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <S.FormGroup lgMargin>
        <TextField
          name="firstName"
          ref={register}
          label={translate('labels.name')}
          placeholder={translate('labels.name')}
          error={!!errors?.firstName?.message}
          helperText={translate(errors?.firstName?.message)}
        />
        <TextField
          name="lastName"
          ref={register}
          label={translate('labels.lastName')}
          placeholder={translate('labels.lastName')}
          error={!!errors?.lastName?.message}
          helperText={translate(errors?.lastName?.message)}
        />
      </S.FormGroup>

      <S.FormGroup lgMargin>
        <CustomSelect
          control={control}
          name="country"
          label={translate('labels.country')}
          placeholder={translate('labels.country')}
          error={translate(errors?.country?.message)}
          options={countries}
        />
        {Number(country?.value) === BRAZIL_COUNTRY_CODE ? (
          <CustomSelect
            control={control}
            name="state"
            label={translate('labels.state')}
            placeholder={translate('labels.state')}
            error={errors?.state?.message}
            options={brazilianStates}
          />
        ) : (
          <TrickSpace />
        )}
      </S.FormGroup>
      {/* <S.FormGroup lgMargin>
        <CustomSelect
          control={control}
          name="profile.area"
          label={translate('labels.profile')}
          placeholder={translate('labels.profile')}
          error={errors?.profile?.area?.message}
          options={profiles}
        />
        {hasLinkedInField && (
          <TextField
            name="linkedInUrl"
            ref={(e) => {
              register(e);
              linkedinInputRef.current = e;
            }}
            label={translate('labels.linkedin')}
            placeholder={translate('labels.linkedin')}
            error={!!errors?.linkedInUrl?.message}
            helperText={translate(errors?.linkedInUrl?.message)}
            defaultValue={LINKEDIN_DEFAULT_URL}
          />
        )}
      </S.FormGroup> */}
      <S.FormGroup lgMargin>
        <TextField
          readOnly
          name="email"
          ref={register}
          label={translate('labels.email')}
          placeholder={translate('labels.email')}
          error={!!errors?.email?.message}
          helperText={translate(errors?.email?.message)}
        />
        {/* <CustomSelect
          control={control}
          name="preferableLanguage"
          label={translate('labels.language')}
          placeholder={translate('labels.language')}
          error={errors?.preferableLanguage?.message}
          options={languages}
        /> */}
      </S.FormGroup>
      <S.FormGroup lgMargin>
        <PasswordField
          name="password"
          autoComplete="new-password"
          ref={register}
          label={translate('labels.password')}
          placeholder={translate('labels.password')}
          error={!!errors?.password?.message}
          helperText={translate(errors?.password?.message)}
          minLength={setPasswordMinLength(themeName, hasPasswordStrength)}
          maxLength={ProjectNames.VALE === themeName ? 6 : 100}
          showStrength
        />
        <PasswordField
          name="rePassword"
          autoComplete="new-password"
          ref={register}
          label={translate('labels.passwordConfirmation')}
          placeholder={translate('labels.passwordConfirmation')}
          error={!!errors?.rePassword?.message}
          helperText={translate(errors?.rePassword?.message)}
          minLength={setPasswordMinLength(themeName, hasPasswordStrength)}
          maxLength={ProjectNames.VALE === themeName ? 6 : 100}
        />
      </S.FormGroup>
      <MediaMatch lessThan="large">
        <FooterPoweredBy />
      </MediaMatch>

      <S.FormActions>
        <Button type="submit" isLoading={isPending} disabled={isPending}>
          {translate('configurations.save')}
        </Button>
      </S.FormActions>
    </Form>
  );
};
