import ContentLoader from 'react-content-loader';
import { useLocation } from 'react-router-dom';
import { useTheme } from 'hooks';
import { skeletonProps } from 'lib/contexts/animations';
import { getOptions } from 'lib/contexts/navbar';
import { useIntlContext } from 'contexts';
import { Interactions } from 'components/contexts/interactions';
import * as PrivateStyles from 'components/contexts/routes/Private/Private.styles';
import { Footer, Navbar, Container } from 'components/structure';
import { MediaMatch } from 'components/structure';
import optionsMock from 'components/structure/Navbar/Navbar.mock';
import { THEME } from 'styles/themes';

type SkeletonStateType = {
  hasInteractions?: boolean;
  hasFooter?: boolean;
};

type SkeletonRoutes = {
  [key: string]: SkeletonStateType;
};

const RoutesWithSkeleton = {
  '/virtual-expo': {
    hasInteractions: true,
    hasFooter: false,
  } as SkeletonStateType,
} as SkeletonRoutes;

export const SkeletonDesktop = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      {...skeletonProps}
      {...colors.skeleton}
      viewBox="0 0 962 570"
    >
      <rect width="962" height="422" />
    </ContentLoader>
  );
};

export const SkeletonMobile = () => {
  const { colors } = useTheme();

  return (
    <ContentLoader
      {...skeletonProps}
      {...colors.skeleton}
      viewBox="0 0 327 300"
    >
      <rect width="327" height="169" />
    </ContentLoader>
  );
};

export const SkeletonHome = () => (
  <>
    <MediaMatch greaterThan="medium">
      <SkeletonDesktop />
    </MediaMatch>
    <MediaMatch lessThan="medium">
      <SkeletonMobile />
    </MediaMatch>
  </>
);

export const Skeleton = () => {
  const { languageStream } = useIntlContext();
  const location = useLocation();
  const state = RoutesWithSkeleton[location.pathname as string];
  const [hasInteractions, hasFooter] = [
    state?.hasInteractions ?? true,
    state?.hasFooter ?? true,
  ];

  return (
    <>
      <PrivateStyles.Wrapper
        hasInteractions={hasInteractions}
        hasFooter={hasFooter}
      >
        <Navbar
          language={languageStream}
          isTranslationChange={false}
          hasInteractions={hasInteractions}
          options={getOptions(optionsMock, THEME.MenuLabels)}
          hasNotification={true}
        />
        <PrivateStyles.OutletWrapper hasInteractions={hasInteractions}>
          <Container>
            <SkeletonHome />
          </Container>
        </PrivateStyles.OutletWrapper>
        <Interactions show={hasInteractions} />
        <Footer
          show={hasFooter}
          language={languageStream}
          hasInteractions={hasInteractions}
          hasNotification={true}
          menuItems={[]}
        />
      </PrivateStyles.Wrapper>
    </>
  );
};
