import { ButtonHTMLAttributes } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import styled, { css } from 'styled-components';

export type MenuLinkType = ButtonHTMLAttributes<HTMLButtonElement> &
  NavLinkProps;

export const MenuLink = styled(NavLink)<MenuLinkType>`
  ${({ theme }) => css`
    color: ${theme.colors.grayscale.dark.info};
    cursor: pointer;
    font-size: ${theme.typography.sizes.base};
    line-height: ${theme.typography.lineHeight.base};
    display: inline-flex;
    align-items: center;
    transition: color 0.3s linear;
    svg {
      margin-right: ${theme.spacing.xs};
      height: 24px;
      width: 24px;
    }

    &:not(:last-child) {
      margin-right: ${theme.spacing.md};
    }

    &:hover {
      color: ${theme.colors.primary.hover};
    }
  `}
`;
