import { contextsPT, contextsEN, contextsES } from './contexts';
import { corePT, coreEN, coreES } from './core';
import { structurePT, structureEN, structureES } from './structure';

export type Locales = {
  [key: string]: string;
};

export default {
  pt: {
    ...contextsPT,
    ...corePT,
    ...structurePT,
  },
  en: {
    ...contextsEN,
    ...coreEN,
    ...structureEN,
  },
  es: {
    ...coreES,
    ...contextsES,
    ...structureES,
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any;
