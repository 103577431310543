import styled, { css } from 'styled-components';
import { BusinessCardProps } from 'components/contexts/users/BusinessCard/BusinessCard';

type StyleProps = Pick<BusinessCardProps, 'isLightBackground'>;

export const Wrapper = styled.div<StyleProps>`
  ${({ theme }) => css`
    background-color: ${theme.colors.neutral.black};
    backdrop-filter: 'none';
    border: 1px solid ${theme.colors.primary.main};
    border-radius: ${theme.shapes.borderRadius.md};
    /* box-shadow: 0px 8px 20px rgba(204, 0, 0, 0.1); */
    display: grid;
    grid-template-columns: 48px 1fr;
    grid-gap: 8px;
    padding: ${theme.spacing.sm};
    width: ${theme.sizes.businessCard};
    position: relative;
  `}
`;

export const AvatarWrapper = styled.div`
  align-self: flex-start;
`;

export const ContentWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    span {
      color: ${theme.colors.neutral.white};
      font-family: ${theme.typography.family.primary};
      font-size: ${theme.typography.sizes.sm};
      line-height: ${theme.typography.lineHeight.base};
      min-height: 24px;

      strong {
        color: ${theme.colors.grayscale.dark.title};
      }

      &:empty {
        display: none;
      }
    }
  `}
`;

export const LinksWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    margin-top: ${theme.spacing.xs};

    a {
      color: ${theme.colors.primary.main};
      display: inline-block;
      text-decoration: none;
      height: 18px;
      width: 18px;

      &:not(:last-child) {
        margin-right: ${theme.spacing.md};
      }

      svg {
        height: 100%;
        width: 100%;
        color: ${theme.colors.primary.main};
      }
    }
  `}
`;

export const FloatingText = styled.span<StyleProps>`
  ${({ theme }) => css`
    color: ${theme.colors.grayscale.dark.title};
    font-family: ${theme.typography.family.primary};
    font-size: ${theme.typography.sizes.sm};
    line-height: ${theme.typography.lineHeight.base};
    text-align: center;
    position: absolute;
    left: 50%;
    top: -${theme.spacing.md};
    transform: translateX(-50%);
    width: 100%;
  `}
`;
