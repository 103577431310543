import en from './en.json';
import es from './es.json';
import pt from './pt-br.json';

export const materialsPT = {
  materials: pt,
};

export const materialsEN = {
  materials: en,
};

export const materialsES = {
  materials: es,
};
