/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef } from 'react';
import { useTranslate } from 'hooks';
import { isBayer, isValidUrl } from 'lib/contexts';
import { getEventThemeName } from 'lib/core/event';
import { ThemeCustomProvider } from 'contexts';
import { Avatar } from 'components/contexts/users';
import { Button, Icon } from 'components/structure';
import * as BusinessCardTheme from 'styles/overrides/BusinessCard.theme';
import { User } from 'interfaces/user';
import { ProjectNames } from 'constants/enums';
import * as S from './UserCard.styles';

export type UserCardProps = {
  user: User;
  handleCloseCard?: () => void;
  handlePrivateChat?: () => void;
  isChatDisabled?: boolean;
  forwardRef?: React.Ref<HTMLDivElement>;
};

export const UserCard = ({
  user: { avatar, name, country, email, linkedInUrl, profile },
  handleCloseCard,
  handlePrivateChat,
  isChatDisabled = false,
  forwardRef,
}: UserCardProps) => {
  const translate = useTranslate();
  const isBayerEvent = getEventThemeName() === ProjectNames.BAYER;
  const isBayerEmail = isBayer(email ?? '');

  return (
    <ThemeCustomProvider {...BusinessCardTheme}>
      <S.Wrapper>
        <S.Container ref={forwardRef}>
          <S.Content>
            <Avatar src={avatar} alt={name} />

            <S.ContentInfos>
              <strong>{name}</strong>
              <S.UserInfoSmall>{country}</S.UserInfoSmall>
              <S.UserInfoSmall>
                {isBayerEvent && isBayerEmail ? 'Bayer' : profile?.value}
              </S.UserInfoSmall>
              {BusinessCardTheme.options?.hasIcons && (
                <S.SocialsContainer>
                  {linkedInUrl && isValidUrl(linkedInUrl) && (
                    <a
                      href={linkedInUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon icon="IcLinkedinSmall" />
                    </a>
                  )}
                  {email && (
                    <a
                      href={`mailto:${email}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon icon="IcEmail" />
                    </a>
                  )}
                </S.SocialsContainer>
              )}
            </S.ContentInfos>
            <S.IconWrapper onClick={handleCloseCard}>
              <Icon width="15px" height="15px" icon="IcClose" />
            </S.IconWrapper>
          </S.Content>
          {!isChatDisabled && (
            <Button isSmall fullWidth onClick={handlePrivateChat}>
              {translate('interactions.talk')}
            </Button>
          )}
        </S.Container>
      </S.Wrapper>
    </ThemeCustomProvider>
  );
};
