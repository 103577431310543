const APP_BASE = '';

class Route<T> {
  public base: string;
  public children: T;

  constructor(base: string, children: T) {
    this.base = base;
    this.children = children;
  }

  private linkFactory = (key?: keyof T, routeParamValue = '') => {
    if (key) {
      const routeParams = String(this.children[key]).match(/:[A-Za-z]+/g);

      return `${this.children[key]}`
        .replace(/\//g, '')
        .replace(routeParams ? routeParams[0] : ':id', `${routeParamValue}/`);
    }
  };

  public getLink = (key?: keyof T, id = '') => {
    let link = '';
    const baseUrl = this.base.replace(/\//g, '');

    if (APP_BASE) {
      link += `/${APP_BASE}`;
    }

    if (baseUrl) {
      link += `/${baseUrl}`;
    }

    link += `/${this.linkFactory(key, id)}`;

    return link;
  };
}

export const ROUTES = {
  app: new Route('/', {
    home: 'home',
    expo: 'virtual-expo',
    material: 'material',
    coffee: 'coffee',
    helpCenter: 'help-center',
    rewards: 'rewards',
    interests: 'interests',
    sponsors: 'sponsors',
  }),
  integrations: new Route('integrations', {
    facebookAuth: 'facebook',
  }),
  configurations: new Route('configurations', {
    root: '/',
    personalData: 'personal-data',
    photo: 'photo',
    interests: 'interests',
  }),
  countdown: new Route('countdown', {
    base: '/',
    userCountdown: 'user-countdown',
  }),
  schedule: new Route('schedule', {
    base: '/',
    id: ':id',
  }),
  login: new Route('login', {
    signIn: '/',
    resetPassword: ':token/reset-password',
    forgotPassword: 'recover',
    userConfirmation: 'confirmation',
  }),
  accreditation: new Route('accreditation', {
    gettingStarted: '/',
    createBusinessCard: 'business-card',
    personalData: 'personal-data',
    interests: 'interests',
  }),
  stream: new Route('stream', {
    id: ':id',
  }),
} as const;
