import { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import { useUserById } from 'useCases/users';
import { WithChildren } from 'interfaces/children';

const SOCKET_URL = process.env.REACT_APP_WEB_SOCKET_URL!;
const SURVEY = '/streams';
const CHATS = '/chat';
const NOTIFICATIONS = '/notifications';

const defaultChatSocketParams = {
  path: CHATS,
  forceNew: true,
  transports: ['websocket'],
};

type ContextType = {
  chatSocket: SocketIOClient.Socket;
  surveySocket: SocketIOClient.Socket;
  notificationSocket: SocketIOClient.Socket;
  sessionSocket: SocketIOClient.Socket;
  resetChatSocket: () => void;
};

// type IUser = {
//   name: string;
//   lastName: string;
//   email: string;
// };

const defaultValue: ContextType = {
  chatSocket: io(SOCKET_URL, { autoConnect: false }),
  surveySocket: io(SOCKET_URL, { autoConnect: false }),
  notificationSocket: io(SOCKET_URL, { autoConnect: false }),
  sessionSocket: io(SOCKET_URL, { autoConnect: false }),
  resetChatSocket: () => undefined,
};

export const SocketContext = createContext<ContextType>(defaultValue);

export const SocketProvider = ({ children }: WithChildren) => {
  // const socket = useRef<SocketIOClient.Socket>();
  const [chatSocket, setChatSocket] = useState<SocketIOClient.Socket>(
    defaultValue.chatSocket,
  );

  const [sessionSocket, setSessionSocket] = useState<SocketIOClient.Socket>(
    defaultValue.sessionSocket,
  );
  const [surveySocket, setSurveySocket] = useState<SocketIOClient.Socket>(
    defaultValue.surveySocket,
  );
  const [
    notificationSocket,
    setNotificationSocket,
  ] = useState<SocketIOClient.Socket>(defaultValue.notificationSocket);

  const { data } = useUserById(localStorage.getItem('id') || '');
  useEffect(() => {
    if (data?.accessToken)
      setChatSocket(
        io.connect(SOCKET_URL, {
          ...defaultChatSocketParams,
          auth: {
            accessToken: data?.accessToken,
          },
        }),
      );
  }, [data]);

  useEffect(() => {
    if (data?.accessToken)
      setNotificationSocket(
        io.connect(SOCKET_URL, {
          path: NOTIFICATIONS,
          auth: {
            accessToken: data?.accessToken,
          },
          transports: ['websocket'],
        }),
      );
  }, [data?.accessToken]);
  useEffect(() => {
    if (data?.accessToken)
      setSessionSocket(
        io.connect(SOCKET_URL, {
          path: '/session',
          auth: {
            accessToken: data?.accessToken,
          },
          transports: ['websocket']
        }),
      );
  }, [data?.accessToken]);
  useEffect(() => {
    if (data?.accessToken)
      setSurveySocket(
        io.connect(SOCKET_URL, {
          path: SURVEY,
          auth: {
            accessToken: data?.accessToken,
          },
          transports: ['websocket'],
        }),
      );
  }, [data?.accessToken]);

  const resetChatSocket = () => {
    chatSocket.disconnect();
    setChatSocket(
      io.connect(SOCKET_URL, {
        ...defaultChatSocketParams,
        auth: {
          accessToken: data?.accessToken,
        },
      }),
    );
  };

  return (
    <SocketContext.Provider
      value={{
        sessionSocket,
        chatSocket,
        surveySocket,
        notificationSocket,
        resetChatSocket,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocketContext = () => useContext(SocketContext);
