import styled, { css, DefaultTheme } from 'styled-components/macro';
import media from 'styled-media-query';
import { ModalProps } from './Modal';

type ContainerProps = Pick<
  ModalProps,
  'noBackground' | 'noIconContainer' | 'isShort'
>;

type WrapperProps = Pick<ModalProps, 'showModal' | 'colorSchema'>;

const wrapperModifiers = {
  showModal: () => css`
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s linear, visibility 0s 0s;
  `,
};

const getColorBackdrop = (
  theme: DefaultTheme,
  colorSchema: 'dark' | 'light',
) => {
  if (theme.colors?.modal && theme.colors?.modal[colorSchema]?.backdrop) {
    return theme.colors?.modal[colorSchema].backdrop;
  }
  return theme.colors.neutral.modal;
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, showModal, colorSchema = 'dark' }) => css`
    background: ${getColorBackdrop(theme, colorSchema)};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s linear, visibility 0s 0.2s;
    z-index: 10000;

    ${showModal && wrapperModifiers.showModal()};
  `}
`;

const ContainerModifiers = {
  noIconContainer: () => css`
    ${media.lessThan('medium')`
      height: 394px;
    `}
  `,
  isShort: () => css`
    height: 344px;

    ${media.lessThan('medium')`
      height: 192px;
    `}
  `,
};

export const Container = styled.div<ContainerProps>`
  ${({ theme, noIconContainer, isShort }) => css`
    border: 1px solid ${theme.colors.primary.main};
    display: flex;
    padding: ${theme.spacing.sm};
    flex-direction: column;
    align-items: center;
    background: ${theme.colors.neutral.black};
    width: 640px;
    height: 448px;
    border-radius: ${theme.shapes.borderRadius.md};
    position: fixed;

    ${media.lessThan('medium')`
      width: 90vw;
      height: 284px;
    `} ${noIconContainer && ContainerModifiers.noIconContainer()}
      ${isShort && ContainerModifiers.isShort()};
  `}
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const IconWrapper = styled.button`
  ${({ theme }) => css`
    appearance: none;
    color: ${theme.colors.primary.main};
    height: 24px;
    width: 24px;
  `}
`;

const contentModifiers = {
  noIconContainer: () => css`
    margin-top: 0;
  `,
};

export const Content = styled.main<ContainerProps>`
  ${({ theme, noIconContainer }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 42px;
    height: 100%;

    ${media.lessThan('medium')`
      margin-top: -${theme.spacing.sm};
      justify-content: space-between;

      ${noIconContainer && contentModifiers.noIconContainer()};
    `}
  `}
`;

const iconContainerModifiers = {
  noBackground: () => css`
    background: none;
  `,
};

export const IconContainer = styled.div<ContainerProps>`
  ${({ noBackground }) => css`
    background: red;
    height: 50px;
    width: 150px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    ${noBackground && iconContainerModifiers.noBackground()};

    ${media.lessThan('medium')`
      height: 48px;
      width: 48px;
    `}
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.neutral.white};
    font-size: ${theme.typography.sizes.lg};
    margin-top: ${theme.spacing.lg};
    text-align: center;
    max-width: 436px;

    ${media.lessThan('medium')`
      margin-top: ${theme.spacing.sm};
      padding: 0 ${theme.spacing.xs};
    `}
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.grayscale.dark.paragraph};
    font-size: ${theme.typography.sizes.base};
    margin-top: ${theme.spacing.xs};
    max-width: 464px;
    text-align: center;
    line-height: ${theme.spacing.md};

    ${media.lessThan('medium')`
      margin-top: 0;
      padding: 0 6px;
    `}
  `}
`;

export const ButtonContainer = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacing['2xl']};

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    ${media.lessThan('medium')`
      margin-top: ${theme.spacing.sm};
    `}
  `};
`;
