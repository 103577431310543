import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { UseFormMethods } from 'react-hook-form';
import { useTranslate } from 'hooks';
import { InterestProps, Interests } from 'components/contexts/users';
import { Checkbox } from 'components/form';
import { FooterPoweredBy } from 'components/structure';
import {
  Button,
  CFCode,
  Form,
  FormActions,
  FormGroup,
  SubTitle,
  ButtonSupport,
} from 'components/structure';
import { MediaMatch } from 'components/structure';
import { User } from 'interfaces/user';
import LogoButton from 'assets/images/pages/creators/logoButton.png';

export type InterestsFormProps = {
  interests?: InterestProps[];
  isPending?: boolean;
  name?: string;
  selectedInterests?: string[];
  onSubmit: (payload: User) => void;
  submitLabel: string;
  showPoweredBy?: boolean;
  showSupportButton?: boolean;
} & Pick<UseFormMethods, 'register' | 'handleSubmit' | 'setValue'>;

const InterestsForm = ({
  interests = [],
  isPending = false,
  selectedInterests,
  register,
  onSubmit,
  handleSubmit,
  setValue,
  name = 'interests',
  submitLabel,
  showPoweredBy = false,
  showSupportButton = true,
}: InterestsFormProps) => {
  const translate = useTranslate();

  const onChange = (newInterests: string[]) =>
    setValue('interests', newInterests);

  useEffect(() => {
    register(name);
  }, [register, name]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <SubTitle>{translate('accreditation.interests.title')}</SubTitle>

      <FormGroup hasMargin={false}>
        <Interests
          onChange={onChange}
          interests={interests}
          initalSelectedInterests={selectedInterests}
        />
      </FormGroup>

      <SubTitle>{translate('users.networking')}</SubTitle>
      <Checkbox
        dark
        register={register}
        name="allowChat"
        label={translate('labels.allowNetworking')}
      />
      {showPoweredBy && (
        <MediaMatch lessThan="large">
          <FooterPoweredBy />
        </MediaMatch>
      )}

      {showSupportButton && (
        <FormGroup lgMargin>
          <ButtonSupport
            onlyIcon={isMobile}
            fixedDesktop={!isMobile}
            icon={isMobile ? 'IcHelp' : undefined}
          />
        </FormGroup>
      )}

      <FormActions>
        <CFCode />
        <Button
          style={{
            backgroundImage: `url(${LogoButton})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
          type="submit"
          isLoading={isPending}
          disabled={isPending}
        >
          {submitLabel}
        </Button>
      </FormActions>
    </Form>
  );
};

export default InterestsForm;
