import { errorsPT, errorsEN, errorsES } from './errors';
import { labelsPT, labelsEN, labelES } from './labels';

export const corePT = {
  ...labelsPT,
  ...errorsPT,
};

export const coreEN = {
  ...labelsEN,
  ...errorsEN,
};

export const coreES = {
  ...labelES,
  ...errorsES,
};
