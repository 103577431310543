import { forwardRef } from 'react';
import { useTheme } from 'hooks';
import { TextField, TextFieldProps } from 'components/form/TextField/TextField';
import { Icon } from 'components/structure';
import * as S from './SearchField.styles';

type SearchFieldProps = Omit<TextFieldProps, 'icon'>;

export const SearchField = forwardRef<HTMLInputElement, SearchFieldProps>(
  (props, ref) => {
    const theme = useTheme();
    return (
      <TextField
        {...props}
        ref={ref}
        icon={
          <S.IconContainer>
            <Icon
              height="24px"
              width="24px"
              color={theme.colors.neutral.filter}
              icon="IcSearch"
            />
          </S.IconContainer>
        }
      />
    );
  },
);
