import styled, { css } from 'styled-components';
import { SubTitle } from 'components/structure';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.neutral.filter};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    backdrop-filter: blur(50px);
    right: 0;
    left: 0;
    margin: 0 auto;

    ${SubTitle} {
      color: ${theme.colors.grayscale.dark.info};
      font-size: ${theme.typography.sizes.base};
      line-height: ${theme.typography.lineHeight.base};
    }

    svg {
      * {
        stroke: ${theme.colors.grayscale.dark.info};
        fill: ${theme.colors.grayscale.dark.info};
      }
    }
  `}
`;
