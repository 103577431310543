import en from './en.json';
import es from './es.json';
import pt from './pt-br.json';

export const helpCenterPT = {
  helpCenter: pt,
};

export const helpCenterEN = {
  helpCenter: en,
};

export const helpCenterES = {
  helpCenter: es,
};
