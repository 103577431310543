/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useForm } from 'react-hook-form';
import { useTerms, Term } from 'useCases/service-terms';
import { useTranslate, useThemeOptions, useQueryString } from 'hooks';
import { setReadOnlyDefaultValue } from 'lib/contexts/input';
import { useBusinessCardContext } from 'contexts';
import { Terms } from 'components/contexts/accreditation';
import {
  Checkbox,
  Option,
  PasswordField,
  TextField,
  CustomSelect,
} from 'components/form';
import {
  Button,
  // FormActions,
  FormGroup,
  Form,
  Heading,
  Paragraph,
  TrickSpace,
  ButtonSupport,
} from 'components/structure';
import { User } from 'interfaces/user';
import {
  ProjectNames,
  BRAZIL_COUNTRY_CODE,
  LINKEDIN_DEFAULT_URL,
} from 'constants/enums';
import { brazilianStates } from './BusinessCardForm.mock';
import * as S from './BusinessCardForm.styles';
import {
  schema,
  //schemaHasAdicionalFields,
  defaultValues as defaultValuesFromValidations,
} from './BusinessCardForm.validations';

type FormModel = {
  rePassword: string;
  terms: boolean;
  acceptedShareData: boolean;
  acceptedNews: boolean;
  numberOfPeopleWatchingWithMe?: number;
  ageGroup: number;
  ask1: string;
  ask3: string;
} & User;

export type BusinessCardFormProps = {
  onSubmit: (user: User) => void;
  isPending?: boolean;
  countries: Option[];
  profiles?: Option[];
  languages?: Option[];
  defaultValues?: User;
  showSupportButton?: boolean;
  hasLinkedInField?: boolean;
  hasMemberGetMember?: boolean;
  hasPasswordStrength?: boolean;
  hasCountry?: boolean;
  hasJobArea?: boolean;
};

const BusinessCardForm = ({
  onSubmit,
  isPending,
  defaultValues,
  showSupportButton,
  languages = [],
  countries,
  profiles = [],
  hasLinkedInField = false,
  hasCountry = true,
  hasJobArea = false,
}: BusinessCardFormProps) => {
  const { themeName } = useThemeOptions();
  const translate = useTranslate();
  const { data: { terms } = {} } = useTerms();
  const [showTerms, setShowTerms] = useState(false);
  const [term, setTerm] = useState<Term>({} as Term);

  const linkedinInputRef = useRef<HTMLInputElement | null>(null);

  const { setCardProps } = useBusinessCardContext();
  const {
    errors,
    register,
    handleSubmit,
    watch,
    reset,
    control,
  } = useForm<FormModel>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...defaultValuesFromValidations,
      ...defaultValues,
    },
  });
  const firstName = watch('firstName');
  const lastName = watch('lastName');
  const ask3 = watch('ask3');
  const email = watch('uuid');
  const profile = watch('profile');
  const linkedIn = watch('linkedInUrl');
  const country = watch('country', {
    label: 'Brasil',
    value: '76',
  });
  const state = watch('state');
  const areaAsk2 = watch('ask2');
  const company = watch('company');
  useEffect(() => {
    setCardProps({
      name: firstName ? `${firstName} ${lastName}` : undefined,
      email,
      ask3,
      company,
      profile: profiles.find(({ value }) => value === profile?.area)?.label,
      linkedIn,
      country: countries?.find(({ value }) => value === country),
      state: brazilianStates.find(({ value }) => value === state)?.label,
    });
  }, [
    firstName,
    lastName,
    email,
    country,
    linkedIn,
    profile?.area,
    countries,
    state,
    ask3,
    company,
  ]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  useEffect(() => {
    if (linkedinInputRef.current && hasLinkedInField) {
      setReadOnlyDefaultValue(linkedinInputRef.current || LINKEDIN_DEFAULT_URL);
    }
  }, []);
  const { referrer } = useQueryString() as { referrer?: string };
  const onHandleSubmit = (user: User) => {
    if (user.linkedInUrl === LINKEDIN_DEFAULT_URL) {
      user.linkedInUrl = '';
    }

    // const newUser = { ...user, ask2: user.ask2 + `|` + (user.ask4 || '') };
    return onSubmit({ ...user, referrer: referrer ?? undefined });
  };

  const handleSetIndexTerm = (currentTerm: string) => {
    if (!terms) return;

    setTerm(terms[currentTerm]);
    setShowTerms(true);
  };

  const ask2 = [
    {
      label: translate('accreditation.customFields.option01'),
      value: '0',
    },
    {
      label: translate('accreditation.customFields.option02'),
      value: '1',
    },
    {
      label: translate('accreditation.customFields.option03'),
      value: '2',
    },
    {
      label: translate('accreditation.customFields.option04'),
      value: '3',
    },
    {
      label: translate('accreditation.customFields.option05'),
      value: '4',
    },
    {
      label: translate('accreditation.customFields.option06'),
      value: '5',
    },
    {
      label: translate('accreditation.customFields.option07'),
      value: '6',
    },
  ];
  const themeNameParam = ProjectNames.VALE === themeName ? `.${themeName}` : '';
  const LabelTerms = () => (
    <S.Term>
      {translate(
        `accreditation.businessCard.acceptTerms${themeNameParam}.accept`,
      )}
      <S.ButtonTerms
        onClick={() => {
          const currentTerm = 'useTerm';
          handleSetIndexTerm(currentTerm);
        }}
        type="button"
      >
        {translate(
          `accreditation.businessCard.acceptTerms${themeNameParam}.conditions`,
        )}
      </S.ButtonTerms>
      {translate(`accreditation.businessCard.acceptTerms${themeNameParam}.and`)}
      <S.ButtonTerms
        onClick={() => {
          const currentTerm = 'privacyTerm';
          handleSetIndexTerm(currentTerm);
        }}
        type="button"
      >
        {translate(
          `accreditation.businessCard.acceptTerms${themeNameParam}.policies`,
        )}
      </S.ButtonTerms>
      {/* {translate(
        `accreditation.businessCard.acceptTerms${themeNameParam}.policiesAnd`,
      )} */}
    </S.Term>
  );
  return (
    <Form onSubmit={handleSubmit(onHandleSubmit)}>
      <Heading>{translate('accreditation.businessCard.title')}</Heading>
      <Paragraph>
        {translate('accreditation.businessCard.description')}
      </Paragraph>

      <FormGroup lgMargin>
        <TextField
          name="firstName"
          ref={register}
          label={translate('labels.name')}
          placeholder={translate('labels.name')}
          error={!!errors?.firstName?.message}
          helperText={translate(errors?.firstName?.message)}
        />
        <TextField
          name="lastName"
          ref={register}
          label={translate('labels.lastName')}
          placeholder={translate('labels.lastName')}
          error={!!errors?.lastName?.message}
          helperText={translate(errors?.lastName?.message)}
        />
        <TextField
          name="email"
          ref={register}
          label={translate(`labels${themeNameParam}.email`)}
          placeholder={translate(`labels${themeNameParam}.email`)}
          error={!!errors?.email?.message}
          helperText={translate(errors?.email?.message)}
          // TODO check if uuid is email to enable edit
          readOnly
        />
      </FormGroup>

      <FormGroup lgMargin>
        <TextField
          name="company"
          ref={register}
          label={translate('labels.company')}
          placeholder={translate('labels.company')}
          error={!!errors?.company?.message}
          helperText={translate(errors?.company?.message)}
        />
        <TextField
          name="ask3"
          ref={register}
          label={translate('labels.role')}
          placeholder={translate('labels.role')}
          error={!!errors?.ask3?.message}
          helperText={translate(errors?.ask3?.message)}
        />
      </FormGroup>

      <FormGroup lgMargin>
        {hasCountry && (
          <>
            <CustomSelect
              control={control}
              name="country"
              label={translate('labels.country')}
              placeholder={translate('labels.country')}
              error={translate(errors?.country?.message)}
              options={countries}
            />
            {Number(country) === BRAZIL_COUNTRY_CODE ? (
              <CustomSelect
                control={control}
                name="state"
                label={translate('labels.state')}
                placeholder={translate('labels.state')}
                error={errors?.state?.message}
                options={brazilianStates}
              />
            ) : (
              <TrickSpace />
            )}
          </>
        )}
      </FormGroup>

      <CustomSelect
        control={control}
        name="ask1"
        label={translate('accreditation.customFields.ask1')}
        error={errors?.ask1?.message}
        placeholder={translate('accreditation.customFields.ask1')}
        customStyles={{
          placeholder: () => ({ height: 20, padding: 5, color: 'white' }),
        }}
        options={[
          { label: translate('accreditation.customFields.yes'), value: '0' },
          { label: translate('accreditation.customFields.no'), value: '1' },
        ]}
      />
      <div style={{ marginBottom: '20px' }} />

      <CustomSelect
        control={control}
        name="ask2"
        label={translate('accreditation.customFields.ask2')}
        error={errors?.ask2?.message}
        placeholder={translate('accreditation.customFields.ask2')}
        customStyles={{
          placeholder: () => ({ height: 20, padding: 5, color: 'white' }),
        }}
        options={ask2}
      />
      <div style={{ marginBottom: '20px' }} />
      {areaAsk2 === '6' ? (
        <TextField
          name="ask4"
          ref={register}
          label={translate('labels.anotherArea')}
          placeholder={translate('labels.anotherArea')}
          error={!!errors?.ask4?.message}
          helperText={translate(errors?.ask4?.message)}
        />
      ) : (
        <TrickSpace />
      )}
      <div style={{ marginBottom: '20px' }} />
      <FormGroup hasMargin={false}>
        {hasJobArea && (
          <>
            <CustomSelect
              control={control}
              name="profile.area"
              label={translate('labels.profile')}
              placeholder={translate('labels.profile')}
              error={errors?.profile?.area?.message}
              options={profiles}
            />
            <CustomSelect
              control={control}
              name="preferableLanguage"
              label={translate('labels.language')}
              placeholder={translate('labels.language')}
              error={errors?.preferableLanguage?.message}
              options={languages}
              defaultValue={languages.find(
                ({ value }) => value === defaultValues?.preferableLanguage,
              )}
            />
          </>
        )}
        {/* {hasLinkedInField && (
          <TextField
            name="linkedInUrl"
            ref={(e) => {
              register(e);
              linkedinInputRef.current = e;
            }}
            label={translate('labels.linkedin')}
            placeholder={translate('labels.linkedin')}
            error={!!errors?.linkedInUrl?.message}
            helperText={translate(errors?.linkedInUrl?.message)}
            defaultValue={LINKEDIN_DEFAULT_URL}
          />
        )} */}

        <PasswordField
          name="password"
          autoComplete="new-password"
          ref={register}
          label={translate('labels.password')}
          placeholder={translate('labels.password')}
          error={!!errors?.password?.message}
          helperText={translate(errors?.password?.message)}
          minLength={6}
          maxLength={ProjectNames.VALE === themeName ? 6 : 100}
          showStrength
        />
        <FormGroup lgMargin>
          <input ref={register} type="hidden" name="uuid" />
          <PasswordField
            name="rePassword"
            autoComplete="new-password"
            ref={register}
            label={translate('labels.passwordConfirmation')}
            placeholder={translate('labels.passwordConfirmation')}
            error={!!errors?.rePassword?.message}
            helperText={translate(errors?.rePassword?.message)}
            minLength={6}
            maxLength={ProjectNames.VALE === themeName ? 6 : 100}
          />
        </FormGroup>
      </FormGroup>

      <S.TermsContainer>
        <Checkbox
          style={{
            marginBottom: 'auto',
            width: '34px',
          }}
          register={register}
          name="terms"
          error={errors.terms?.message}
          noCheckOnLabel
          label={((<LabelTerms />) as unknown) as string}
        />
      </S.TermsContainer>
      {/* <PublicFaqAnchor /> */}

      {showSupportButton && (
        <FormGroup lgMargin>
          <ButtonSupport
            onlyIcon={isMobile}
            fixedDesktop={!isMobile}
            icon={isMobile ? 'IcHelp' : undefined}
          />
        </FormGroup>
      )}

      {/* <FormActions> */}
      <S.ContainerButton>
        <Button
          fullWidth
          style={{
            marginBottom: '10px',
          }}
          type="submit"
          isLoading={isPending}
          disabled={isPending}
        >
          {translate('labels.next')}
        </Button>
      </S.ContainerButton>

      {/* </FormActions> */}
      <Terms
        term={term}
        show={showTerms}
        onConfirm={() => setShowTerms(false)}
      />
    </Form>
  );
};

export default BusinessCardForm;
