import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.a`
  display: flex;
  max-width: 100%;
  flex: 1;

  ${media.greaterThan('medium')`
    flex-direction: column;
    max-width: 235px;
    flex: 1 1 235px;
  `}
`;

export const PictureWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 135px;
    z-index: 0;

    img,
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
    }

    img {
      z-index: 0;
      width: 235px;
    }

    &::after {
      background-color: ${theme.colors.grayscale.light.info};
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    ${media.lessThan('medium')`
      margin-right: ${theme.spacing.sm};
      height: 74px;
      width: 132px;

      img {
      width: 100%;
    }
    `}
  `}
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    color: ${theme.colors.grayscale.dark.title};
    font-size: ${theme.typography.sizes.base};
    font-family: ${theme.typography.family.secondary};
    font-weight: bold;
    line-height: ${theme.typography.lineHeight.base};
    margin-top: ${theme.spacing.sm};

    ${media.lessThan('medium')`
    font-size: 13px;
    width: 50%;
    margin-top: 1px;
    `}
  `}
`;

export const Duration = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.grayscale.light.info};
    font-size: ${theme.typography.sizes.xs};
  `}
`;
