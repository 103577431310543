/* eslint-disable @typescript-eslint/no-explicit-any */
// import { CountdownErrorContext } from 'pages/accreditation';
import {
  ChatProvider,
  InteractionsProvider,
  SurveyProvider,
  BusinessCardProvider,
  ModalProvider,
  StreamProvider,
  NotificationProvider,
  ChatHandlersProvider,
  PictureInPictureProvider,
} from 'contexts';
import { SocketProvider } from 'contexts/Socket';
import { WithChildren } from 'interfaces/children';

export const PrivateContexts = ({ children }: WithChildren) => (
  <SocketProvider>
    <ModalProvider>
      <BusinessCardProvider>
        <NotificationProvider>
          <InteractionsProvider>
            <PictureInPictureProvider>
              <StreamProvider>
                <SurveyProvider>
                  <ChatProvider>
                    <ChatHandlersProvider>{children}</ChatHandlersProvider>
                  </ChatProvider>
                </SurveyProvider>
              </StreamProvider>
            </PictureInPictureProvider>
          </InteractionsProvider>
        </NotificationProvider>
      </BusinessCardProvider>
    </ModalProvider>
  </SocketProvider>
);
