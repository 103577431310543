import {
  ThemePartial,
  ThemeCountdownPartial,
  ThemePrivatePartial,
  ThemePublicPartial,
  ThemeCoffeePartial,
  ThemeBusinessCardPartial,
  Colors,
  ThemeOptions,
} from 'interfaces/theme';
import BackgroundError from 'assets/images/core/cnn/background-error.png';
import BackgroundOutlet from 'assets/images/core/cnn/background-outlet.png';
import { ReactComponent as Logo } from 'assets/images/core/cnn/logo.svg';
import BackgroundAccreditationFallback from 'assets/images/pages/cnn/accreditation/bg-fallback.png';
import BackgroundCountdown from 'assets/images/pages/cnn/countdown/background.png';

export const colors: Colors = {
  primary: {
    main: '#CC0000',
    hover: '#CC0000',
    active: '#CC0000',
  },
  secondary: {
    main: '#CC0000',
    hover: '#CC0000',
    active: '#CC0000',
  },
  tertiary: {
    main: '#66d9a3',
    active: '#47c885',
    hover: '#5ad297',
  },
  neutral: {
    black: '#000000',
    filter: 'rgba(0, 0, 0, 0.6)',
    modal: 'rgba(0, 0, 0, 0.8)',
    materialVideoOverlay: 'rgba(0, 0, 0, 0.4)',
    white: '#ffffff',
  },
  grayscale: {
    dark: {
      title: '#ffffff',
      paragraph: '#ffffff',
      info: 'rgba(255, 255, 255, 0.6)',
      line: 'rgba(255, 255, 255, 0.2)',
    },
    light: {
      title: '#000000',
      paragraph: '#fff',
      info: 'rgba(0, 0, 0, 0.8)',
      line: 'rgba(0, 0, 0, 0.2)',
    },
  },
  background: {
    dark: {
      input: 'rgba(0, 0, 0, 0.5)',
      button: 'rgba(0, 0, 0, 0.01)',
    },
    light: {
      input: 'rgba(255, 255, 255, 0.5)',
      button: 'rgba(255, 255, 255, 0.01)',
    },
    outlet: '#202020',
    float: {
      solid: '#1d2224',
    },
    public: {
      outlet: '#263441',
    },
  },
  contextual: {
    info: {
      main: '',
      hover: '',
      active: '',
    },
    success: {
      main: '#38cd3c',
      hover: '#2fc533',
      active: '#22b825',
    },
    attention: {
      main: '#fda700',
      hover: '',
      active: '',
    },
    danger: {
      main: '#ff0202',
      hover: '#cc0202',
      active: '#990303',
    },
    disabled: {
      main: '',
      hover: '',
      active: '',
    },
  },
  skeleton: {
    backgroundColor: '#c4c4c4',
    foregroundColor: 'rgba(255, 255, 255, 0.5)',
  },
  message: {
    light: 'rgba(255, 255, 255, 0.15)',
    dark: 'rgba(0, 0, 0, 0.2)',
  },
  popover: {
    dark: {
      background: '#000',
    },
    light: {
      background: '#fff',
    },
  },
};

export const typography = {
  family: {},
};

export const Navbar: ThemePartial = {};

export const BusinessCard: ThemeBusinessCardPartial = {
  colors: {
    popover: colors.popover,
    grayscale: {
      dark: {
        title: '#000',
        paragraph: '#000',
        info: 'rgba(255, 255, 255, 0.6)',
        line: 'rgba(255, 255, 255, 0.2)',
      },
      light: {
        ...colors.grayscale.light,
        title: '#fff',
        paragraph: '#000',
      },
    },
  },
};

export const Footer: ThemePartial = {
  colors: {
    neutral: {
      ...colors.neutral,
      modal: colors.neutral.white,
    },
    grayscale: {
      dark: colors.grayscale.dark,
      light: {
        ...colors.grayscale.light,
        ...{
          paragraph: 'rgba(0, 0, 0, 0.8)',
        },
      },
    },
  },
};

export const Interactions: ThemePartial = {
  colors: {
    grayscale: {
      dark: colors.grayscale.dark,
      light: {
        ...colors.grayscale.light,
        paragraph: '#000',
      },
    },
  },
};

export const Private: ThemePrivatePartial = {
  colors: {
    grayscale: {
      dark: colors.grayscale.dark,
      light: {
        ...colors.grayscale.light,
        ...{
          paragraph: '#fff',
        },
      },
    },
  } as Colors,
};

export const Public: ThemePublicPartial = {};

export const Countdown: ThemeCountdownPartial = {
  options: {
    hasSocialsLinks: true,
    hasFooterTitle: true,
  },
};

export const UserCountdown: ThemePartial = {};

export const Coffee: ThemeCoffeePartial = {};

export const EventOptions: ThemeOptions = {
  hasLinkedInField: true,
};

// Quais items do menu estão ativos
export const MenuLabels = [
  'navbar.userMenu.profile',
  'navbar.plenary',
  'navbar.schedule',
  'navbar.pressMaterial',
];

export const LogoWhite = Logo;

export {
  BackgroundError,
  BackgroundOutlet,
  Logo,
  BackgroundAccreditationFallback,
  BackgroundCountdown,
};
