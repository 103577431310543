import styled, { css } from 'styled-components';
import { Container } from 'components/structure';

export const Wrapper = styled(Container)`
  ${({ theme }) => css`
    > div {
      margin-top: ${theme.spacing.lg};
    }
  `}
`;

export const subTitle = styled.h4`
  font-size: 16px;
  font-weight: normal;
  display: inline;
`;

export const subTitleLink = styled.a`
  color: blue;
`;
export const paragraph = styled.p`
  margin-top: 10px;
  font-weight: bold;
`;
