import axios, { AxiosError } from 'axios';
import { useRef, useContext, useEffect } from 'react';
import { Cookies, useCookies } from 'react-cookie';
import { useNavigate } from 'react-router';
import { useTranslate, useToast } from 'hooks';
import { responseErrors } from 'lib/core/errors';
import { useIntlContext, useModalContext } from 'contexts';
import { Icon, Logo } from 'components/structure';
import { baseURL } from 'constants/endpoints';
import { ROUTES } from 'constants/urls';
import { useEnv } from './useEnv';

const INVALID_PASSWORD = 'invalid-password';
const USER_NOT_FOUND = 'user-not-found';
const RECAPTCHA_TOKEN_IS_MISSING = 'recaptcha-token-is-missing';
const RECAPTCHA_TOKEN_IS_INVALID = 'recaptcha-token-is-invalid';
const USER_NOT_CONFIRMED = 'user-not-confirmed';

// TODO: Rule for avatar
const AVATAR_NOT_FOUND = 'avatar-not-found';

export const useAPI = () => {
  const { setToast } = useToast();
  const { setModalProps, setShowModal } = useModalContext();
  const { xAPIKey, eventId } = useEnv();
  const { language } = useIntlContext();
  const [cookies] = useCookies();
  const translate = useTranslate();
  const navigate = useNavigate();
  const onRejected = async (error: AxiosError) => {
    const { response } = error ?? {};
    if (
      response?.status === 401 &&
      response?.data.error !== USER_NOT_CONFIRMED &&
      response?.data.error !== INVALID_PASSWORD &&
      response.data.error !== RECAPTCHA_TOKEN_IS_MISSING &&
      response.data.error !== RECAPTCHA_TOKEN_IS_INVALID &&
      response.data.error !== AVATAR_NOT_FOUND
    ) {
      setModalProps({
        title: translate('auth.expiredSession.title'),
        description: translate('auth.expiredSession.description'),
        buttonLabel: translate('labels.understood'),
        onClose: () => {
          localStorage.clear();
          return navigate(ROUTES.accreditation.getLink('gettingStarted'))
        },
        icon: <Logo medium light />,
        noBackground: true,
      });
      setShowModal(true);
    }

    if (
      response?.status === 401 &&
      response?.data.error === USER_NOT_CONFIRMED
    ) {

      setModalProps({
        title: translate('auth.notConfirmed.title'),
        description: translate('auth.notConfirmed.description'),
        buttonLabel: translate('labels.understood'),
        onClose: () => navigate(ROUTES.login.getLink('signIn')),
        icon: <Icon icon="IcEmail" />,
        noBackground: true,
      });
      setShowModal(true);
    }

    if (
      response?.data?.error &&
      response?.data?.error !== USER_NOT_FOUND &&
      response.data.error !== AVATAR_NOT_FOUND
    ) {
      setToast({
        type: 'danger',
        hasChat: false,
        description: translate(
          responseErrors(
            response?.data?.error ?? response?.config.data?.message,
          ),
          translate('errors.generic-error'),
        ),
      });
    }
    return Promise.reject(error);
  };

  const apiRef = useRef(
    axios.create({
      baseURL,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': xAPIKey,
        'event-id': eventId,
        language: cookies?.language || language,
      },
    }),
  );
  apiRef.current.interceptors.response.use((response) => response, onRejected);

  return apiRef.current;
};
