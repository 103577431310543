import styled, { css, DefaultTheme } from 'styled-components';
import media from 'styled-media-query';
import { InteractionsOpenState } from 'constants/enums';
import { InteractionsProps } from './Interactions';

type WrapperProps = Pick<InteractionsProps, 'show'> & {
  openState?: InteractionsOpenState;
  playerHeight?: number;
};

const wrapperModifiers = {
  hide: () => css`
    transform: translateX(360px);
    overflow: initial;
  `,
  closed: (theme: DefaultTheme) => css`
    transform: translateY(-${theme.sizes.navbarMobile});
  `,
  open: () => css`
    position: static;
    transform: initial;
    flex: 1 1 0;
  `,
  full: (theme: DefaultTheme) => css`
    height: calc(100% - ${theme.sizes.navbarMobile});
    transform: translateY(-100%);
  `,
};

export const Wrapper = styled.aside<WrapperProps>`
  ${({ theme, show, openState }) => css`
    background-color: ${theme.colors.background.float.solid};
    grid-area: interactions;
    height: 100%;
    position: fixed;
    right: 0;
    bottom: 0;
    transition: transform 0.2s ease-in-out;
    transition-property: height;
    width: ${theme.sizes.chat};
    overflow: hidden;

    ${media.lessThan('medium')`
      z-index: ${theme.layers.base};
    `}

    &,
    * {
      overscroll-behavior: contain;
    }

    ${media.lessThan('large')`
      left: 0;
      top: 100%;
      width: 100vw;

      ${show && openState && wrapperModifiers[openState](theme)};
    `}

    ${!show && wrapperModifiers.hide()};
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    border-bottom: 1px solid ${theme.colors.grayscale.dark.line};
    background-color: ${theme.colors.background.dark};
    padding: ${theme.spacing.sm} ${theme.spacing.sm} 0;
    height: ${theme.sizes.navbar};
    width: 100%;
    position: relative;

    ${media.lessThan('large')`
      height: ${theme.sizes.navbarMobile};
      padding: 20px ${theme.spacing.sm} 0;
    `}
  `}
`;

type IconWrapperProps = {
  hide: boolean;
  isOpen: boolean;
};

const iconWrapperModifiers = {
  hide: () => css`
    > svg {
      display: none;
    }
  `,
  isOpen: () => css`
    > svg {
      transition: transform 0.3s ease-in-out;
      transform: rotate(180deg);
    }
  `,
};

export const IconWrapper = styled.div<IconWrapperProps>`
  ${({ hide, isOpen }) => css`
    display: none;

    ${media.lessThan('large')`
      cursor: pointer;
      display: block;
      position: absolute;
      top: 0;
    `}

    ${isOpen && iconWrapperModifiers.isOpen()}
    ${hide && iconWrapperModifiers.hide()}
  `}
`;

const headerCollapseModifiers = {
  closed: (theme: DefaultTheme) => css`
    position: absolute;
    top: 10%;
    height: 40px;
    width: 40px;
    left: 0;
    background-color: ${theme.colors.neutral.filter};
    transform: translateY(60px) translateX(-40px);
    border-top-left-radius: ${theme.shapes.borderRadius.xs};
    border-bottom-left-radius: ${theme.shapes.borderRadius.xs};

    svg {
      transition: all 0.4s ease-out;
      transform: rotate(180deg);
    }
  `,
};

type HeaderCollapseProps = {
  closed: boolean;
};

export const HeaderCollapse = styled.button<HeaderCollapseProps>`
  ${({ theme, closed }) => css`
    background-color: ${theme.colors.grayscale.dark.line};
    width: 25px;
    appearance: none;
    border: none;
    outline: none;
    transition: transform 0.4s;
    display: flex;
    justify-content: center;
    align-items: center;

    ${media.lessThan('large')`
      display: none;
    `}

    ${closed && headerCollapseModifiers.closed(theme)}
  `}
`;

export const HeaderWrapper = styled.div`
  display: flex;
`;

export const Body = styled.div`
  ${({ theme }) => css`
    height: calc(100% - ${theme.sizes.footer});
    position: relative;
    overflow: hidden;

    ${media.lessThan('large')`
      height: calc(100% - ${theme.sizes.navbarMobile});
    `}
  `}
`;

type CommonProps = {
  active?: boolean;
};

export const Content = styled.div<CommonProps>`
  ${({ active }) => css`
    display: ${active ? 'block' : 'none'};
    height: 100%;
    width: 100%;
  `}
`;

const tabButtonModifiers = {
  active: (theme: DefaultTheme) => css`
    color: ${theme.colors.primary.main};
    border-bottom-color: ${theme.colors.primary.main};
  `,
};

export const TabButton = styled.button<CommonProps>`
  ${({ theme, active = false }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    appearance: none;
    border: none;
    border-bottom: 4px solid transparent;
    color: ${theme.colors.grayscale.dark.info};
    transition: all 0.2s linear;
    outline: none;
    font-size: ${theme.typography.sizes.sm};
    line-height: ${theme.typography.lineHeight.base};
    text-align: center;

    &:not(:last-child) {
      margin-right: ${theme.spacing.sm};
    }

    svg {
      margin-bottom: ${theme.spacing['2xs']};
      height: 24px;
      width: 24px;
      color: currentColor;
    }

    ${media.lessThan('large')`
      padding-bottom: ${theme.spacing.xs};
      margin-top: 0;

      svg {
        display: none;
        margin-bottom: 0;
      }
    `}

    ${active && tabButtonModifiers.active(theme)};
  `}
`;
