import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { DefaultScroll } from 'components/structure';
import * as EmptyStyles from 'components/structure/EmptySearch/EmptySearch.styles';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.background.float.solid};
    padding: ${theme.spacing.md} ${theme.spacing.xs};
    height: 100%;

    ${media.lessThan('medium')`
      overflow-y: overlay;
    `}
  `}
`;

export const PlenaryWrapper = styled.div`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.grayscale.dark.line};
    margin-bottom: ${theme.spacing.sm};
    padding: 0 ${theme.spacing.xs} ${theme.spacing.sm};
  `}
`;

export const SearchWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spacing.md};
    padding-left: ${theme.spacing.xs};
    padding-right: ${theme.spacing.xs};

    button {
      display: block;
      margin-bottom: ${theme.spacing.xs};
    }
  `}
`;

export const EmptyState = styled(EmptyStyles.EmptyState)``;

export const SpinnerWrapper = styled.div`
  height: 80px;
`;

export const Chats = styled.div`
  display: flex;
  overflow-y: overlay;
  flex-direction: column;

  &:empty {
    display: none;
  }

  ${media.lessThan('medium')`
    overflow-y: none;
  `}

  ${DefaultScroll};
`;
