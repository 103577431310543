import styled, { css, DefaultTheme } from 'styled-components';
import { Anchor as AnchorStyles } from 'components/structure/Typography/Typography';

type ProgressBoxProps = {
  hasProgressBottom?: boolean;
  hasProgressTop?: boolean;
  active?: boolean;
};

const progressBoxModifiers = {
  top: (theme: DefaultTheme) => css`
    &::before {
      content: '';
      bottom: 110%;
      height: 22px;
      background: ${theme.colors.grayscale.light.line};
    }
  `,
  bottom: (theme: DefaultTheme) => css`
    &::after {
      content: '';
      top: 100%;
      background: ${theme.colors.grayscale.light.line};
    }
  `,
  active: () => css`
    > svg {
      color: #008000;
    }
    &::after,
    &::before {
      background: #008000;
    }
  `,
};

export const ProgressBox = styled.div<ProgressBoxProps>`
  ${({ theme, hasProgressTop, hasProgressBottom, active }) => css`
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;

    > svg {
      width: 42px;
      height: 42px;
      margin-right: ${theme.spacing.xs};
      color: #ccc;
    }

    & + div {
      margin-top: ${theme.spacing.xl};
    }

    &::after,
    &::before {
      position: absolute;
      height: 21px;
      left: 20px;
      width: 2px;
    }

    ${hasProgressTop && progressBoxModifiers.top(theme)}
    ${hasProgressBottom && progressBoxModifiers.bottom(theme)}
    ${active && progressBoxModifiers.active()}
  `}
`;

export const Aside = styled.div`
  ${({ theme }) => css`
    > strong {
      color: ${theme.colors.grayscale.light.paragraph};
    }
  `}
`;

export const Divisor = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 0 ${theme.spacing.sm};
    margin-top: ${theme.spacing['2xs']};
  `}
`;

export const NeededPoints = styled.small`
  ${({ theme }) => css`
    font-size: ${theme.typography.sizes.sm};
    color: ${theme.colors.grayscale.light.paragraph};
  `}
`;

export const Anchor = styled(AnchorStyles)<{ isPending: boolean }>`
  ${({ theme, isPending }) => css`
    color: ${theme.colors.grayscale.light.paragraph};
    pointer-events: ${isPending ? 'none' : 'all'};
  `}
`;

export const AltSmall = styled.small`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    color: ${theme.colors.grayscale.light.info};

    svg {
      width: 16px;
      height: 16px;
      margin-right: ${theme.spacing.xs};
      color: ${theme.colors.grayscale.light.info};
    }
  `}
`;
