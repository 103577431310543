/* eslint-disable import-helpers/order-imports */
import { useState } from 'react';

import { SubMaterial } from 'pages/reimagineVideo/Materials';
import { Gallery, MaterialItem } from 'components/contexts/Materials';
import { Preview } from 'components/contexts/Materials';
import { dispatchGAEvent } from 'services/google';
import { IcChevronRight } from 'assets/icons';
import { GA_EVENTS } from 'constants/enums';
import { EventAction, EventCategory } from 'interfaces/analytics';

import * as S from './styles';

export enum MaterialsTypes {
  Video = 'videos',
  Document = 'document',
  Phtos = 'photos',
  Image = 'image',
  Chart = 'chart',
  BROLL = 'b-roll',
  link = 'link',
  url = 'url',
}
type Props = {
  title: string;
  description: string;
  subMaterials: SubMaterial[];
};
type GenericMaterial = {
  show?: boolean;
  url?: string;
  downloadUrl?: string;
  videos?: string[];
  images?: string[];
};

export const ReimagineVideoAccordionItem = ({
  title = 'title',
  description = 'description',
  subMaterials,
}: Props) => {
  const [openAccordion, setOpenAccordion] = useState(true);
  const [document, setDocument] = useState<GenericMaterial>({
    show: false,
    url: '',
  });
  const [video, setVideo] = useState<GenericMaterial>({
    show: false,
    url: '',
    downloadUrl: '',
  });
  const [photos, setPhotos] = useState<GenericMaterial>({
    show: false,
    images: [''],
  });
  const [bRoll, setBRoll] = useState<GenericMaterial>({
    show: false,
    videos: [''],
  });

  const handleClick = (
    url: string,
    type: MaterialsTypes,
    images: string | string[],
  ) => {
    if (type === MaterialsTypes.link || type === MaterialsTypes.url) {
      const isUrlDev = url.includes('https://storage-dev.tm1.com.br/');

      const newUrlPrd = url.split('https://storage.metasummitlatam.com/');
      const newUrlDev = url.split('https://storage-dev.tm1.com.br/');

      window.open(isUrlDev ? newUrlDev[1] : newUrlPrd[1], '_blank');
      return;
    }
    if (type === MaterialsTypes.Image) {
      return setPhotos({
        show: true,
        images: Array.isArray(images) ? images : [images],
      });
    }

    if (type === MaterialsTypes.Video) {
      return setVideo({
        show: true,
        url,
      });
    }

    if (!shouldBeLinks.includes(type)) {
      setDocument({ show: true, url });
    }
  };

  const shouldBeLinks = ['chart', 'external'];

  return (
    <S.Container>
      <S.HeaderContainer>
        <S.TitleContainer
          onClick={() => setOpenAccordion((prevState) => !prevState)}
        >
          <S.Title>{title}</S.Title>
          <S.Description>{description}</S.Description>
        </S.TitleContainer>
        <S.SVGContainer
          rotateSvg={openAccordion}
          onClick={() => setOpenAccordion((prevState) => !prevState)}
        >
          <IcChevronRight />
        </S.SVGContainer>
      </S.HeaderContainer>
      {openAccordion && (
        <S.ContentWrapper>
          {subMaterials?.map((subMaterial) => (
            <MaterialItem
              onClick={(e) => {
                e.stopPropagation();
                handleClick(subMaterial.url, subMaterial.type, subMaterial.url);
                dispatchGAEvent({
                  category: EventCategory.Materials,
                  action: EventAction.click,
                  label: GA_EVENTS.materials.labels.selectedMaterial(
                    subMaterial.name,
                  ),
                });
              }}
              as={shouldBeLinks.includes(subMaterial.type) ? 'a' : 'div'}
              isVideo={subMaterial.type === MaterialsTypes.Video}
              key={subMaterial.name}
              cover={subMaterial.cover}
              name={subMaterial.name}
              href={subMaterial.url}
              button-name={subMaterial['button-name']}
              target={
                shouldBeLinks.includes(subMaterial.type) ? '_blank' : '_self'
              }
            />
          ))}
        </S.ContentWrapper>
      )}
      <Gallery
        handleCloseModal={() =>
          setBRoll((prevState) => ({ ...prevState, show: false }))
        }
        videos={bRoll.videos}
        showModal={bRoll.show!}
      />
      <Gallery
        handleCloseModal={() =>
          setPhotos((prevState) => ({ ...prevState, show: false }))
        }
        images={photos.images}
        showModal={photos.show!}
      />
      <Preview
        handleCloseModal={() =>
          setVideo((prevState) => ({ ...prevState, show: false }))
        }
        videoUrl={video.url}
        showModal={video.show!}
      />
      <Preview
        handleCloseModal={() =>
          setDocument((prevState) => ({ ...prevState, show: false }))
        }
        fileUrl={document.url}
        showModal={document.show!}
      />
    </S.Container>
  );
};
