import styled, { css, DefaultTheme } from 'styled-components';
import {
  Paragraph,
  SubTitle,
} from 'components/structure/Typography/Typography';

export const Wrapper = styled.div`
  min-width: 280px;
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    padding: ${theme.spacing.xs};

    ${SubTitle} {
      flex: 1;
      margin-top: 0;
      margin-bottom: 0;
      font-size: ${theme.typography.sizes.base};
      color: ${theme.colors.neutral.black};
    }
  `}
`;

export const ButtonRemove = styled.button`
  ${({ theme }) => css`
    background-color: transparent;
    min-width: auto;
    border: none;
    svg {
      width: 16px;
      height: 16px;
      color: ${theme.colors.grayscale.dark.info};
    }
  `}
`;

export const Item = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.grayscale.dark.info};
    padding: ${theme.spacing.xs};
    display: flex;
    flex-direction: row;
    align-items: center;
  `}
`;

type ItemStatusProps = {
  viewed?: boolean;
};

const itemStatusModifiers = {
  viewedShow: () => css`
    &:after {
      display: none;
    }
  `,
};

export const ItemStatus = styled.span<ItemStatusProps>`
  ${({ theme, viewed }) => css`
    width: 20px;
    align-self: flex-start;
    padding-top: ${theme.spacing.xs};
    padding-right: ${theme.spacing.xs};

    &:after {
      content: '';
      height: 8px;
      width: 8px;
      border-radius: 8px;
      background-color: ${theme.colors.primary.main};
      display: block;
    }

    ${viewed && itemStatusModifiers.viewedShow()}
  `}
`;

type ItemContentProps = {
  divider?: boolean;
};

const itemContentModifiers = {
  divider: (theme: DefaultTheme) => css`
    border-bottom: 1px solid ${theme.colors.grayscale.dark.line};
  `,
};

export const ItemContent = styled.div<ItemContentProps>`
  ${({ theme, divider }) => css`
    display: flex;
    padding-bottom: ${theme.spacing.xs};
    flex: 1;
    min-width: 300px;

    ${divider && itemContentModifiers.divider(theme)}
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow-y: scroll;

    /* TODO: Criar schema de snippets */
    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
      margin: 14px;
    }

    ::-webkit-scrollbar-thumb {
      width: 5px;
      background-color: ${theme.colors.primary.main};
      border-radius: 20px;
    }
  `}
`;

export const ItemContentText = styled(Paragraph)`
  ${({ theme }) => css`
    color: ${theme.colors.neutral.black};
    font-size: ${theme.typography.sizes.sm};
    margin-bottom: 0;
    flex: 1;
  `}
`;

export const ItemAction = styled.div`
  ${({ theme }) => css`
    width: 50px;
    display: block;
    align-self: flex-start;
    height: 100%;
    margin-left: ${theme.spacing.xs};
  `}
`;

export const ItemTime = styled(Paragraph)`
  ${({ theme }) => css`
    color: ${theme.colors.grayscale.dark.info};
    font-size: ${theme.typography.sizes.sm};
    margin-bottom: 0;
  `}
`;

export const NoData = styled(Paragraph)`
  ${({ theme }) => css`
    color: ${theme.colors.grayscale.dark.info};
    text-align: center;
  `}
`;
