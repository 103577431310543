import { useParams } from 'react-router';
import { useUpdatePassword } from 'useCases/auth';
import { ChangePasswordForm } from 'components/contexts/authentication';

export const ChangePassword = () => {
  const { handleUpdatePassword, isPending } = useUpdatePassword();
  const { token } = useParams();

  return (
    <ChangePasswordForm
      onSubmit={(values) => handleUpdatePassword({ ...values, token })}
      isPending={isPending}
    />
  );
};
