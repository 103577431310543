import styled, { css } from 'styled-components';
import * as TypographyStyles from 'components/structure/Typography/Typography';
import { ModalPeoplesProps } from './ModalPeoples';

type WrapperProps = Pick<ModalPeoplesProps, 'showModal'>;

const wrapperModifiers = {
  showModal: () => css`
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s linear, visibility 0s 0s;
  `,
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, showModal }) => css`
    background: ${theme.colors.neutral.modal};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s linear, visibility 0s 0.2s;
    z-index: 100;

    ${showModal && wrapperModifiers.showModal()}
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.primary.main};
    display: flex;
    padding: ${theme.spacing.lg};
    flex-direction: column;
    align-items: center;
    max-width: 864px;
    width: 80%;
    background: ${theme.colors.neutral.black};
    max-height: 594px;
    border-radius: ${theme.shapes.borderRadius.md};
    position: fixed;
  `}
`;

export const Header = styled.header`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;

    > p {
      color: ${theme.colors.grayscale.dark.info};
      margin-top: ${theme.spacing.sm};
    }
  `}
`;

export const TopContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${TypographyStyles.SubTitle} {
    margin-bottom: 0;
  }
`;

export const InputContainer = styled.div`
  ${({ theme }) => css`
    margin-top: 12px;
    max-width: calc(${theme.sizes.input} * 2 + 32px);
  `}
`;

export const IconWrapper = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.primary.main};
    margin-top: -${theme.spacing.lg};
    cursor: pointer;
  `}
`;

export const Content = styled.main`
  ${({ theme }) => css`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0 ${theme.spacing['2xl']};
    align-items: center;
    margin-top: ${theme.spacing.xl};
    height: 100%;
  `}
`;

export const UserInviteChatContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    > button {
      border: 1px solid ${theme.colors.primary.main};
      width: 88px;
      min-width: 88px;
      font-size: ${theme.typography.sizes.sm};
      font-weight: normal;
      height: 24px;
    }
  `}

  &:hover {
    > article {
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const Footer = styled.footer`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: ${theme.spacing.xl};
  `}
`;

export const MovePageButton = styled.button`
  ${({ theme }) => css`
    border: none;
    background: transparent;
    color: ${theme.colors.primary.main};
  `}
`;
