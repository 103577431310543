import { ChangeEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { responseInterface } from 'swr';
import { dispatchGAEvent } from 'services/google';
import { useEvent } from 'useCases/events';
import {
  useDebounce,
  useInfiniteScroll,
  usePrefixMessage,
  useSession,
  useTranslate,
  useZendesk,
} from 'hooks';
import { useChatContext } from 'contexts';
import { ChatRoom, PlenaryIcon, UserChat } from 'components/contexts/interactions';
import { Chip } from 'components/contexts/users';
import { SearchField } from 'components/form';
import {
  Anchor,
  ErrorSearch,
  MediaMatch,
  Spinner,
  EmptySearch,
} from 'components/structure';
import { EventAction, EventCategory } from 'interfaces/analytics';
import { ChatListAPI, ChatParams } from 'interfaces/chat';
import { GA_EVENTS } from 'constants/enums';
import { Skeleton } from './ChatList.skeleton';
import * as S from './ChatList.styles';

type FormData = {
  name?: string;
};

type UseUserChats = responseInterface<ChatListAPI, unknown> & {
  isLoading: boolean;
  isLoadingMore: boolean;
  hasMore: boolean;
  loadMoreChats: () => void;
};

export type ChatListProps = {
  plenaryChat?: UserChat;
  chats?: UserChat[];
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleJoinStreamChannel: (streamId: string) => void;
  handleJoinPrivateChannel: (streamId: string) => void;
  useUserChats: (params: ChatParams) => UseUserChats;
  isPending?: boolean;
};

export const ChatList = ({
  plenaryChat,
  isPending = false,
  useUserChats,
  handleJoinStreamChannel,
  handleJoinPrivateChannel,
}: ChatListProps) => {
  const translate = useTranslate();
  const session = useSession();
  const handlerSupport = useZendesk();
  const { data: event } = useEvent();
  const [params, setParams] = useState<ChatParams>({ name: '' });
  const prefixMessage = usePrefixMessage();
  const { plenary, showRoom, room, setRoom, setShowRoom } = useChatContext();

  const {
    data,
    isLoading,
    isLoadingMore,
    hasMore,
    loadMoreChats,
  } = useUserChats(params);

  const lastChatRef = useInfiniteScroll(isLoadingMore, hasMore, loadMoreChats);

  const { register } = useForm<FormData>({
    defaultValues: { name: '' },
    shouldFocusError: false,
  });

  const handleFilterByName = useDebounce(
    (value: string) => setParams({ name: value }),
    350,
  );

  const unreadMessage = (userId: string, read?: boolean) =>
    read && session.id !== userId;

  const getChatRef = (position: number, arraySize: number) =>
    position === arraySize - 1 ? lastChatRef : null;

  if (isPending || (isLoading && !params?.name)) {
    return <Skeleton />;
  }

  return (
    <S.Wrapper>
      {!!plenaryChat?.id && (
        <S.PlenaryWrapper>
          <Chip
            id={plenaryChat.id}
            icon={<PlenaryIcon size="medium" />}
            name={translate('interactions.chatPlenary')}
            description={plenaryChat.message}
            size="medium"
            onClick={() => {
              handleJoinStreamChannel(plenaryChat.id);
              dispatchGAEvent({
                action: EventAction.click,
                label: GA_EVENTS.stream.labels.chatStream,
                category: EventCategory.Chat,
              });
            }}
          />
        </S.PlenaryWrapper>
      )}
      <form>
        <S.SearchWrapper>
          <MediaMatch lessThan="large">
            {event?.event.zendesk?.active && (
              <Anchor as="button" type="button" onClick={handlerSupport} brand>
                {translate('interactions.needHelp')}
              </Anchor>
            )}
          </MediaMatch>
          <SearchField
            name="name"
            placeholder={translate('interactions.searchByName')}
            ref={register}
            autoComplete="off"
            onChange={({ target: { value } }) => handleFilterByName(value)}
          />
        </S.SearchWrapper>
      </form>

      <S.Chats>
        {data?.chats?.map(
          ({ lastMessage, id, name, peer, avatar }, index, chats) => (
            <Chip
              ref={getChatRef(index, chats.length)}
              id={id}
              key={id}
              avatar={avatar}
              name={name}
              description={prefixMessage(lastMessage)}
              hasDot={
                !!lastMessage?.content &&
                unreadMessage(lastMessage?.userId, !lastMessage?.read)
              }
              size="medium"
              isClickable
              onClick={() => {
                handleJoinPrivateChannel(peer)
              }}
            />
          ),
        )}

        {(isLoading || isLoadingMore) && (
          <S.SpinnerWrapper>
            <Spinner />
          </S.SpinnerWrapper>
        )}
      </S.Chats>

      {!data?.chats?.length && !params?.name && !isLoading && (
        <EmptySearch icon="IcChat" title="interactions.chats.startChat" />
      )}

      {!data?.chats?.length && !!params.name && !isLoading && (
        <S.EmptyState>
          <ErrorSearch />
        </S.EmptyState>
      )}
    </S.Wrapper>
  );
};
