import Hls from 'hls.js';
import { useRef, useState, useCallback, useEffect } from 'react';
import { isSafari } from 'react-device-detect';
import ReactJWPlayer from 'react-jw-player';
import ReactPlayer from 'react-player';
import { useLocation, useNavigate } from 'react-router';
import { useStreams } from 'useCases/streams';
import { useEnv, useTranslate } from 'hooks';
import { dayInterval } from 'lib/contexts/date';
import { Skeleton } from 'pages/streams/Stream/Stream.skeleton';
import { useMiniPlayerContext, useSettingsContext } from 'contexts';
import { FloatingHeart } from 'components/contexts/interactions/FloatingHeart/FloatingHeart';
import { Players, VideoPlayers } from 'constants/enums';
import { ROUTES } from 'constants/urls';
import IcPip from 'assets/icons/interactions/pip.svg';
import * as S from './VirtualRoom.styles';

export type VirtualProps = {
  url: string;
  children?: JSX.Element | null | undefined | boolean;
  watchAgainPlayer: boolean;
  videoPlayerType?: VideoPlayers;
};

const PIP_CONFIG = {
  icon: IcPip,
  tooltipText: 'Picture In Picture',
  id: 'pip',
};

const UNMUTE_WARNING_DURATION = 5000;

type VideoPresentationModeType = 'inline' | 'picture-in-picture';

interface IHTMLVideoElement extends HTMLVideoElement {
  requestPictureInPicture: () => Promise<void>;
  disablePictureInPicture: boolean;
  webkitPresentationMode?: VideoPresentationModeType;
  webkitSetPresentationMode: (mode: VideoPresentationModeType) => void;
  webkitSupportsPresentationMode: boolean;
}

export const VirtualRoom = ({
  url,
  children,
  videoPlayerType,
}: VirtualProps) => {
  const translate = useTranslate();
  const { reactJWPlayer } = useEnv();
  const playerRef = useRef<HTMLDivElement>(null);
  const { setPlayerHeight } = useSettingsContext();
  const [isMounted, setIsMounted] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const { setUrl } = useMiniPlayerContext();
  const [hasUnmuteWarning, setHasUnmuteWarning] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const streamId = pathname.split('/')[2];
  const { data } = useStreams(dayInterval());

  const currentStream = data?.streams.find((s) => s.id === streamId);

  const REACTIONS = currentStream?.reactions?.active;
  const POSITION_REACTIONS = currentStream?.reactions?.position;

  const onReady = useCallback(() => {
    setIsMounted(false);
    setIsReady(true);
  }, []);

  const time = parseInt(localStorage.getItem('video-time')!);

  const playlist = [
    {
      file: url,
      starttime: time | 0,
    },
  ];

  const enterPictureInPicture = useCallback(
    async (videoElement: IHTMLVideoElement) => {
      try {
        if (videoElement.webkitSupportsPresentationMode) {
          videoElement.webkitSetPresentationMode('picture-in-picture');
        } else {
          await videoElement.requestPictureInPicture();
        }
      } catch (error) {
        console.error(error);
      }
    },
    [],
  );

  const onPip = useCallback(async () => {
    const videoHls = document.createElement('video');
    videoHls.hidden = true;
    document.body.appendChild(videoHls);

    let hls: Hls;
    if (videoHls) {
      const video = videoHls;

      if (video.canPlayType('application/vnd.apple.mpegurl')) {
        // This will run in safari, where HLS is supported natively
        video.src = url;
        video.addEventListener('loadedmetadata', () => {
          enterPictureInPicture(video as unknown as IHTMLVideoElement);
        });
      } else if (Hls.isSupported()) {
        // This will run in all other modern browsers
        hls = new Hls();
        hls.loadSource(url);
        hls.attachMedia(video);
        video.play();
        video.addEventListener('loadedmetadata', () => {
          enterPictureInPicture(video as unknown as IHTMLVideoElement);
        });
      }
    }

    videoHls.addEventListener('leavepictureinpicture', () => {
      videoHls.remove();
      navigate(ROUTES.stream.getLink('id', streamId));
    });

    navigate(ROUTES.app.getLink('home'));
  }, [enterPictureInPicture, navigate, streamId, url]);

  useEffect(() => {
    if (videoPlayerType === VideoPlayers.JWPLAYER) {
      if (typeof window.jwplayer === 'function' && isReady) {
        const player = window.jwplayer(Players.MAIN);
        if (!isSafari) {
          const { icon, tooltipText, id } = PIP_CONFIG;
          player.addButton(icon, tooltipText, onPip, id);
        }
        const hasPlayerHeight = player.getHeight();
        if (hasPlayerHeight) setPlayerHeight(hasPlayerHeight);
      }
    }
  }, [isReady, setPlayerHeight, videoPlayerType, onPip]);

  useEffect(() => {
    if (hasUnmuteWarning) {
      const timeout = setTimeout(() => {
        setHasUnmuteWarning(false);
      }, UNMUTE_WARNING_DURATION);

      return () => clearTimeout(timeout);
    }
  }, [hasUnmuteWarning]);

  useEffect(() => {
    if (videoPlayerType === VideoPlayers.JWPLAYER) {
      if (!isReady || typeof window.jwplayer !== 'function') return;
      const player = window.jwplayer(Players.MAIN);
      const isMuted = player.getMute();
      if (isMuted) setHasUnmuteWarning(true);
    }
  }, [isReady, videoPlayerType]);

  useEffect(() => {
    setUrl(url);
  }, [setUrl, url]);

  const videoPlayers = {
    [VideoPlayers.YOUTUBE]: (
      <S.VideoWrapper>
        <S.VideoContainer>
          <iframe
            src={url}
            allow="autoplay; fullscreen; encrypted-media"
            frameBorder="0"
            allowFullScreen
            scrolling="no"
          ></iframe>
        </S.VideoContainer>
      </S.VideoWrapper>
    ),
    [VideoPlayers.JWPLAYER]: (
      <ReactJWPlayer
        className="react-jwp-player"
        playerId={Players.MAIN}
        playerScript={reactJWPlayer}
        playlist={playlist}
        onReady={onReady}
      />
    ),
    [VideoPlayers.REACTPLAYER]: (
      <ReactPlayer controls width="100%" height="100%" url={url} pip={true} />
    ),
  };

  return (
    <>
      <S.Wrapper ref={playerRef} hide={isMounted}>
        {isMounted && <Skeleton />}

        <S.PlayerVideo>
          {hasUnmuteWarning && (
            <S.UnmuteLabel>{translate('stream.unMutePlayer')}</S.UnmuteLabel>
          )}
          {videoPlayerType && videoPlayers[videoPlayerType]}
          {children}
        </S.PlayerVideo>
      </S.Wrapper>
      {REACTIONS && (
        <S.Interaction position={POSITION_REACTIONS}>
          <FloatingHeart position={POSITION_REACTIONS} />
        </S.Interaction>
      )}
    </>
  );
};
