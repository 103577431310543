/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useMemo, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useCertificate } from 'useCases/assets';
import { useEventById } from 'useCases/events';
import { useUserById } from 'useCases/users';
import { useEnv, useTranslate, useThemeOptions, useSession } from 'hooks';
import { LanguageStream, useIntlContext } from 'contexts';
import { CountdownPage } from 'components/contexts/accreditation';
import { Footer } from 'components/structure';
import { floatingMenuItemsMock } from 'components/structure/Navbar/Navbar.mock';
import * as CountdownTheme from 'styles/overrides/Countdown.theme';
import { EventAction, EventCategory } from 'interfaces/analytics';
import { Certificate, GA_EVENTS, ProjectNames } from 'constants/enums';

// eslint-disable-next-line @typescript-eslint/no-explicit-any

export const Countdown = () => {
  const { id: userId } = useSession();
  const userById: any = useUserById(userId!);

  const isLoadingUser = useMemo(() => userById.isLoading, [userById.isLoading]);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const errorMessage = useMemo(
    () => userById?.error?.message?.includes('401'),
    [userById?.error?.message],
  );

  const [error, setError] = useState(false);

  useEffect(() => {
    if (!isLoadingUser) {
      if (errorMessage) {
        console.log('redirecting to login', isLoadingUser, errorMessage);
        localStorage.clear();
        sessionStorage.clear();
        document.cookie = '';
        setError(true);
      }
    }
  }, [errorMessage, isLoadingUser]);

  const { eventId } = useEnv();
  const { data, isLoading } = useEventById(eventId);
  const { languageStream, setLanguageStream } = useIntlContext();
  const eventById = useEventById(eventId);
  const handleDownloadCertificate = useCertificate();
  const eventCertificate = eventById?.data?.event.certificate;
  const eventName = eventById?.data?.event.name;
  const hasCertificate = eventCertificate !== Certificate.NOTEXISTS;
  const hasMemberGetMember = data?.event.hasMemberGetMember;
  const certificateOption = useMemo(
    () => ({
      label: 'navbar.userMenu.generateCertificate',
      onClick: () => handleDownloadCertificate(eventName),
      url: '',
      internal: false,
      disabled: eventCertificate === Certificate.UNAVAILABLE,
      ga: {
        action: EventAction.click,
        label: GA_EVENTS.navBar.labels.certificate,
        category: EventCategory.Navigation,
      },
    }),
    [eventCertificate, eventName, handleDownloadCertificate],
  );
  const filteredMenuItems = useMemo(() => {
    const mergedOptions = hasCertificate
      ? [certificateOption, ...floatingMenuItemsMock]
      : floatingMenuItemsMock;

    return mergedOptions.filter(({ label }) =>
      hasMemberGetMember ? label : label !== 'navbar.userMenu.rewards',
    );
  }, [certificateOption, hasCertificate, hasMemberGetMember]);

  const { themeName } = useThemeOptions();
  const translate = useTranslate();

  if (isLoading) {
    return null;
  }
  const themeNameParam =
    ProjectNames.BAYER === themeName || ProjectNames.VALE === themeName
      ? `.${themeName}`
      : '';

  // const userEmailDomain = userById.data?.user?.email
  //   ?.split('@')[1]
  //   .split('.')[0];
  // const allowedDomains = ['fb', 'facebook', 'meta'];

  // const isUnauthorized =
  //   Number(country) !== BRAZIL_COUNTRY_CODE &&
  //   !allowedDomains?.includes(userEmailDomain!) &&
  //   !userById.isLoading;

  if (error) {
    return <Navigate to="/accreditation" />;
  }

  return (
    <>
      {
        <Footer
          blockedScreen={true}
          avatar={userById.data?.user.avatar}
          userName={userById.data?.user.firstName}
          show={true}
          onChangeLanguage={(lang: LanguageStream) => setLanguageStream(lang)}
          isTranslationChange={false}
          language={languageStream}
          hasInteractions={false}
          hasNotification={false}
          hasMemberGetMember={hasMemberGetMember}
          menuItems={filteredMenuItems}
        />
      }
      <CountdownPage
        socialLinks={data?.event?.socialNetworks}
        eventDate={data?.event.dates[0] ?? ''}
        hasFooterTitle={CountdownTheme.options?.hasFooterTitle}
        hasFooterDescription={CountdownTheme.options?.hasFooterDescription}
        footerTitle={translate(
          `accreditation.countdown${themeNameParam}.footerTitle`,
        )}
        hasMemberGetMember={hasMemberGetMember}
      />
    </>
  );
};
