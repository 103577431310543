import { createContext, useContext, useCallback } from 'react';
import { useSocketContext, useChatContext } from 'contexts';
import { WithChildren } from 'interfaces/children';
import { ChatEvents } from 'constants/enums';

type ContextType = {
  handleJoinPrivateChannel: (
    userId: string,
    startedByUser?: boolean,
    cb?: () => void,
  ) => void;
  handleJoinStreamChannel: (streamId: string, callback?: () => void) => void;
};

const defaultValue = {
  handleJoinPrivateChannel: () => undefined,
  handleJoinStreamChannel: () => undefined,
};

export const ChatHandlersContext = createContext<ContextType>(defaultValue);

export const ChatHandlersProvider = ({ children }: WithChildren) => {
  const { chatSocket: socket } = useSocketContext();
  const { setShowRoom } = useChatContext();

  const handleJoinPrivateChannel = useCallback(
    (userId: string, startedByUser = true, callback) => {
      if (startedByUser) {
        setShowRoom(true);
      }

      socket.emit(ChatEvents.JOIN_PRIVATE_CHANNEL, {
        userId,
        startedByUser,
      });

      callback && callback();
    },
    [setShowRoom, socket],
  );

  const handleJoinStreamChannel = useCallback(
    (streamId: string, callback) => {
      socket.emit(ChatEvents.JOIN_STREAM_CHANNEL, {
        streamId,
      });

      callback && callback();
    },
    [socket],
  );

  return (
    <ChatHandlersContext.Provider
      value={{ handleJoinPrivateChannel, handleJoinStreamChannel }}
    >
      {children}
    </ChatHandlersContext.Provider>
  );
};

export const useChatHandlersContext = () => useContext(ChatHandlersContext);
