import { useNavigate } from 'react-router';
import { useHandleUserExists } from 'useCases/accreditation';
import { useEvent } from 'useCases/events';
import { useQueryString, useToast, useTranslate } from 'hooks';
import { useAccreditationContext, useIntlContext } from 'contexts';
import { GettingStartedForm } from 'components/contexts/accreditation';
import { UserExistsParams, User } from 'interfaces/user';
import { ROUTES } from 'constants/urls';
const { accreditation } = ROUTES;

export const GettingStarted = () => {
  const navigate = useNavigate();
  const { language } = useIntlContext();
  const { setGettingStarted } = useAccreditationContext();
  const translate = useTranslate();
  const { data: event } = useEvent();
  const { setToast } = useToast();
  const params = useQueryString();

  const onUserNotExists = (uuid: string, userGettingStarted?: User) => {
    setGettingStarted({
      ...userGettingStarted,
      uuid,
      email: uuid,
      preferableLanguage: language,
      ...params,
    });
    navigate(accreditation.getLink('createBusinessCard'));
  };

  const onEventIsClosedAndUserNotExists = () => {
    setToast({
      type: 'alert',
      description: translate('errors.userNotExists'),
    });
  };

  const { handleUserExists, isPending } = useHandleUserExists({
    onUserNotExists,
    onEventIsClosedAndUserNotExists,
  });

  const onSubmit = (payload: UserExistsParams) => handleUserExists(payload);

  return (
    <GettingStartedForm
      onSubmit={onSubmit}
      isPending={isPending}
      showSupportButton={event?.event.zendesk?.active}
    />
  );
};
