import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { DefaultScroll } from 'components/structure';

export const Wrapper = styled.div`
  &:empty {
    display: none;
  }
`;

export const StreamsWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spacing.sm};
    /* height: 100%; */
    padding-right: ${theme.spacing.xs};

    ${DefaultScroll};
  `}
`;

export const LinkButton = styled(Link)`
  ${({ theme }) => css`
    background: ${theme.colors.grayscale.light.title};
    border: 1px solid ${theme.colors.primary.main};
    border-radius: ${theme.shapes.borderRadius.md};
    color: ${theme.colors.primary.main};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${theme.typography.sizes.base};
    line-height: ${theme.typography.lineHeight.base};
    height: 56px;
    width: 100%;

    svg {
      margin-right: ${theme.spacing.xs};
      height: 18px;
      width: 18px;
    }
  `}
`;
