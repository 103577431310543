import * as Yup from 'yup';
import messages from 'lib/core/errors';

const { invalidParam, requiredParam } = messages;

export const schema = Yup.object().shape({
  uuid: Yup.string()
    .trim()
    .email(invalidParam('email'))
    .required(requiredParam('uuidToContinue')),
});

export const defaultValues = {
  uuid: '',
};
