import { useRef, useState } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { MessageField } from 'components/form';
import { Icon } from 'components/structure';
import { CHAT_FIELD_MAX_LENGTH } from 'constants/enums';
import * as S from './ConversationMessage.styles';

type ConversationInputFieldProps = {
  showError: boolean;
  error?: string;
  focus: boolean;
  handleSubmit: () => void;
  placeholder: string;
} & Pick<UseFormMethods, 'register' | 'setValue' | 'getValues'>;
// const inputRef = useRef<HTMLInputElement | null>();
export const ConversationMessage = ({
  showError,
  error,
  handleSubmit,
  focus,
  placeholder,
  register,
  setValue,
  getValues,
}: ConversationInputFieldProps) => {
  const inputRef = useRef<HTMLInputElement | null>();
  const [mentionsValue, setMentionsValue] = useState([]);
  return (
    <S.InputWrapper>
      <S.MessageRejected show={showError}>
        <Icon icon="IcAlert" />
        <span>{error}</span>
      </S.MessageRejected>

      <form autoComplete="off" onSubmit={handleSubmit}>
        <MessageField
          isDisabled={showError}
          autoFocus={focus}
          name="message"
          setValue={setValue}
          getValues={getValues}
          placeholder={placeholder}
          register={register}
          maxLength={CHAT_FIELD_MAX_LENGTH}
          setMentionsValue={setMentionsValue}
          mentionsValue={mentionsValue}
        />
        <input
          name="mentions"
          value={JSON.stringify(mentionsValue)}
          ref={(event) => {
            register(event);
            inputRef.current = event;
          }}
          hidden={true}
        />
      </form>
    </S.InputWrapper>
  );
};
