import styled, { css, DefaultTheme } from 'styled-components';

type WrapperProps = {
  checked?: boolean;
};

const wrapperModifiers = {
  active: (theme: DefaultTheme) => css`
    border-color: ${theme.colors.primary.main};
  `,
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, checked }) => css`
    background: ${theme.colors.grayscale.light.info};
    border: 1px solid transparent;
    box-sizing: border-box;
    border-radius: ${theme.shapes.borderRadius.sm};
    padding: ${theme.spacing.xs} 10px;
    margin-bottom: ${theme.spacing.sm};
    transition: border-color 0.2s linear;
    will-change: border-color;

    ${checked && wrapperModifiers.active(theme)};
  `}
`;

export const Radio = styled.span`
  ${({ theme }) => css`
    border-radius: ${theme.shapes.borderRadius.sm};
    border: 2px solid ${theme.colors.grayscale.dark.line};
    display: inline-block;
    height: 20px;
    width: 20px;
    min-width: 20px;
    border-radius: 50%;
    position: relative;
    transition: all 0.2s linear;
    margin-right: 10px;

    &::after {
      background-color: transparent;
      border-radius: 50%;
      content: '';
      height: 8px;
      width: 8px;
      position: absolute;
      top: 50%;
      left: 4px;
      transform: translateY(-50%);
      transition: all 0.2s linear;
    }
  `}
`;

export const Input = styled.input`
  ${({ theme }) => css`
    display: none;

    &:checked + ${Label} {
      ${Radio} {
        border-color: ${theme.colors.primary.main};

        &::after {
          background-color: ${theme.colors.primary.main};
        }
      }

      span {
        color: ${theme.colors.grayscale.dark.paragraph};
      }
    }
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    cursor: pointer;
    display: grid;
    grid-template-columns: 20px 1fr;
    grid-gap: 10px;

    span {
      color: ${theme.colors.grayscale.dark.info};
    }
  `}
`;
