/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-var-requires */
import { MouseEvent } from 'react';
import { Option } from 'components/contexts/Materials/SectionMaterial/SectionMaterial';
import { Icon } from 'components/structure';
import * as S from './MaterialItem.styles';

type MaterialItemProps = {
  isVideo?: boolean;
  material?: Option;
  onClick: (e: MouseEvent) => void;
  as: React.ElementType;
  href?: React.ElementType | string;
  target?: unknown;
  cover: string;
  name?: string;
  'button-name'?: string;
};

export const MaterialItem = ({
  isVideo,
  //material,
  // onClick,
  as,
  href,
  cover,
  //name,
  target,
  ...props
}: MaterialItemProps) => (
  <>
    {props['button-name'] ? (
      <S.Container href={href} target={target} as={as}>
        <a href={(href as string) || '#'} target={'_blank'} rel={'noreferrer'}>
          <S.WrapperItem>
            <S.ThumbImage
              src={
                !cover.includes('/')
                  ? require('../../../../assets/speakers/' + cover).default
                  : cover
              }
              isVideo={isVideo}
            >
              <Icon icon="IcPlayVideo" />
            </S.ThumbImage>
            {/* <S.MaterialName
      style={{ textAlign: 'center' }}
      dangerouslySetInnerHTML={{ __html: material?.name ?? name ?? '' }}
    /> */}
          </S.WrapperItem>

          <S.WatchButton>{props['button-name']}</S.WatchButton>
        </a>
      </S.Container>
    ) : (
      <S.Container href={href} target={target} as={as}>
        <S.WrapperItem>
          <S.ThumbImage
            src={
              !cover.includes('/')
                ? require('../../../../assets/speakers/' + cover).default
                : cover
            }
            isVideo={isVideo}
          >
            <Icon icon="IcPlayVideo" />
          </S.ThumbImage>
        </S.WrapperItem>
      </S.Container>
    )}
  </>
);
