/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useRef, useState } from "react";
import io from 'socket.io-client';
import { useUserById } from "./users";
export const useLikes: any = (streamId: string, preLoadedLikes?: any[]) => {
    const socket = useRef<SocketIOClient.Socket>();
    // console.log(2, preLoadedLikes?.reduce((prev: any, curr: any) => [...prev, ...curr], []))
    const { data } = useUserById(localStorage.getItem('id') || '');
    const [likes, setLikes] = useState<any>([]);

    useEffect(() => {
        socket.current = io(process.env.REACT_APP_WEB_SOCKET_URL!, {
            path: '/likes',
            transports: ['websocket'],
            auth: {
                accessToken: data?.accessToken,
            },
        });

        socket.current.on('connected', () => {
            socket.current?.emit('join_stream', {
                streamId: streamId,
            });
        });
        return () => {
            socket.current?.emit('leave_stream', {
                streamId: streamId,
            });

            socket.current?.close();
        };
    }, [data?.accessToken, streamId]);

    const handleSendReaction = useCallback(
        (event: 'new_like' | 'dislike', { id, reactType, userId, eventId, streamId, messageId }: { id?: string, userId: string, eventId: string, streamId: string, messageId: string, reactType: string }) => {
            if (userId) {
                socket.current?.emit(event, {
                    userId: userId,
                    eventId: eventId,
                    messageId: messageId,
                    streamId: streamId,
                    reactType,
                    id,
                });
            }
        },
        [streamId],
    );

    const handleReceiveReaction = useCallback(() => {

        socket.current?.on(
            'share_like',
            ({ id, userId, reactType, eventId, streamId, messageId }: { id: string, reactType: string, userId: string, eventId: string, streamId: string, messageId: string }) => {
                setLikes((prevState: any) => {

                    prevState = prevState ? prevState : []
                    if (reactType == 'dislike') {
                        return [
                            ...prevState,
                        ].filter(like => !(like.userId == userId && like.messageId == messageId))
                    }
                    return [
                        ...prevState,
                        { id, userId, eventId, streamId, messageId }
                    ].filter(l => l)
                })
            },
        );

        socket.current?.on(
            'share_to_all',
            ({ id, userId, eventId, streamId, messageId, reactType }: { id: string, reactType: string, userId: string, eventId: string, streamId: string, messageId: string }) => {

                setLikes((prevState: any) => {

                    prevState = prevState ? prevState : []
                    if (reactType == 'dislike') {
                        return [
                            ...prevState,
                        ].filter(like => !(like.userId == userId && like.messageId == messageId))
                    }
                    return [
                        ...prevState,
                        { id, userId, eventId, streamId, messageId }
                    ].filter(l => l)
                })
            },
        );
        socket.current?.on(
            'dislike',
            ({ id, userId, eventId, streamId, messageId }: { id: string, userId: string, eventId: string, streamId: string, messageId: string }) => {
                setLikes((prevState: any) => {
                    const newArray = [...prevState].filter(element => !(element.userId == userId && element.id == id));

                    [
                        ...newArray
                    ]
                }
                );
            },
        );

    }, []);

    return {
        handleSendReaction,
        handleReceiveReaction,
        likes,
        setLikes,
    };
};