import { ICalendar } from 'datebook';
import { Masonry } from 'masonic';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { useScheduleById, Ics } from 'useCases/schedules';
import { useTranslate } from 'hooks';
import { hoursIntervalWithoutSeconds } from 'lib/contexts/date';
import { useIntlContext } from 'contexts';
import { SpeakerCard } from 'components/contexts/schedule';
import { Heading, Icon } from 'components/structure';
import { LanguagesAcronyms } from 'constants/enums';
import { Skeleton } from './ScheduleDetails.skeleton';
import * as S from './ScheduleItemDetails.styles';

type ScheduleItemDetailsProps = {
  id: string;
};

export const ScheduleItemDetails = ({ id }: ScheduleItemDetailsProps) => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const { data, isLoading } = useScheduleById(id);
  const { language } = useIntlContext();
  const isPlenaryTime = false;

  const speakers =
    data?.speakers?.sort((a, b) => a.name.localeCompare(b.name)) || [];

  const config = (ics: Array<Ics>) => ({
    title: ics[0].title,
    location: ics[0].location,
    description: ics[0].description,
    start: new Date(ics[0].startTime),
    end: new Date(ics[0].endTime),
  });

  const icalendar = (ics: Array<Ics>) => new ICalendar(config(ics)).download();

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <>
      <S.Header>
        <div onClick={() => navigate(-1)}>
          <Icon icon="IcChevronLeft" />

          <span>{translate('schedule.scheduleTitle')}</span>
        </div>
      </S.Header>

      <S.PlenarySection>
        <S.PlenaryScheduleContent>
          <Heading>{data?.title}</Heading>

          <S.OnAgendaSession>
            {data?.ics && (
              <S.ButtonOnAgenda onClick={() => icalendar(data.ics)}>
                <S.SaveOnAgenda>
                  &#xa0;&#xa0;{translate('schedule.saveOnAgenda')}
                </S.SaveOnAgenda>
              </S.ButtonOnAgenda>
            )}
          </S.OnAgendaSession>
          <Heading>
            {data &&
              data.timeSlot?.start &&
              data.timeSlot?.end &&
              hoursIntervalWithoutSeconds(
                data.timeSlot.start,
                data.timeSlot.end,
                LanguagesAcronyms[language],
                isPlenaryTime,
              )}
          </Heading>
        </S.PlenaryScheduleContent>

        <S.Description>{data?.description}</S.Description>
      </S.PlenarySection>

      <S.SpeakersSection>
        {isMobile || speakers.length === 1 ? (
          speakers.map((speaker) => (
            <SpeakerCard key={speaker.id} {...speaker} language={language} />
          ))
        ) : (
          <Masonry
            items={speakers || []}
            columnCount={2}
            columnGutter={24}
            render={({ data }) => (
              <SpeakerCard language={language} key={data.id} {...data} />
            )}
          />
        )}
      </S.SpeakersSection>
    </>
  );
};
