import { usePortal, useTranslate } from 'hooks';
import { Button, Icon } from 'components/structure';
import * as S from './PreviewCreators.styles';

export type PreviewDocProps = {
  showModal: boolean;
  handleCloseModal: (value: boolean) => void;
  videoUrl?: string;
  downloadUrl?: string;
  fileUrl?: string;
  showButton?: boolean;
};

export const PreviewCreators = ({
  showModal,
  handleCloseModal,
  fileUrl,
  showButton,
}: PreviewDocProps) => {
  const Portal = usePortal();
  const translate = useTranslate();

  return (
    <Portal>
      {showModal && (
        <S.Wrapper>
          <S.IconWrapper
            onClick={(e) => {
              e.stopPropagation();
              handleCloseModal(false);
            }}
          >
            <Icon icon="IcClose" aria-label="Fechar" />
          </S.IconWrapper>

          <S.Content>
            {
              <S.DisplayContainer>
                <S.Document type="text/html" data={fileUrl} />

                {showButton && (
                  <Button
                    download
                    as="a"
                    href={fileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    color="tertiary"
                  >
                    {translate('labels.download')}
                  </Button>
                )}
              </S.DisplayContainer>
            }
          </S.Content>
        </S.Wrapper>
      )}
    </Portal>
  );
};
