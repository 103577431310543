import styled, { css } from 'styled-components/macro';

type DividerProps = {
  height?: string;
  width?: string;
};

export const Divider = styled.div<DividerProps>`
  ${({ theme, height = '2px', width = '100%' }) => css`
    width: ${width};
    height: ${height};
    background-color: ${theme.colors.grayscale.dark.line};
  `}
`;
