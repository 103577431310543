import en from './en.json';
import es from './es.json';
import pt from './pt-br.json';

export const coffeePT = {
  coffee: pt,
};

export const coffeeEN = {
  coffee: en,
};

export const coffeeES = {
  coffee: es,
};
