import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { Heading } from 'components/structure/Typography/Typography';

export const Wrap = styled.div`
  ${Heading} {
    ${({ theme }) => css`
      font-size: ${theme.typography.sizes.xl};
    `}
  }
`;

export const BoxInstagram = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.sm};
  `}
`;

export const FeedWrap = styled.div`
  ${({ theme }) => css`
    height: 365px;
    background: ${theme.colors.neutral.black};
    box-shadow: 0px 8px 20px rgba(19, 114, 110, 0.1);
    backdrop-filter: blur(50px);
    border-radius: ${theme.shapes.borderRadius.md};
    padding: ${theme.spacing.sm} ${theme.spacing.md};

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-gap: 0px ${theme.spacing.sm};
    overflow: auto;

    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
      margin: 14px;
    }

    ::-webkit-scrollbar-thumb {
      width: 5px;
      background-color: ${theme.colors.primary.main};
      border-radius: 20px;
    }

    button {
      margin: 0 auto;
    }

    ${media.lessThan('large')`
      grid-template-columns: repeat(2, 1fr);
    `}

    ${media.lessThan('medium')`
      height: 1200px;
      display: flex;
      flex-direction: column;
    `}
  `}
`;

export const FeedCard = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: auto;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: ${theme.spacing.sm};
    background-color: ${theme.colors.background.public.outlet};
    svg path {
      fill: ${theme.colors.neutral.white};
      stroke: ${theme.colors.neutral.white};
    }
  `}
`;

export const HeaderCard = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    padding: ${theme.spacing.xs};

    ${media.lessThan('medium')`
      padding: ${theme.spacing.sm};
      svg {
        width: 28px;
        height: 28px;
      }
    `}
  `}
`;

export const FooterCard = styled(HeaderCard)`
  ${({ theme }) => css`
    justify-content: flex-start;
    align-items: center;
    margin-top: -${theme.spacing['2xs']};
    padding: ${theme.spacing.xs};
    span {
      font-size: ${theme.typography.family.primary};
      color: ${theme.colors.neutral.white};
      margin-left: ${theme.spacing['2xs']};
    }
  `}
`;

export const ImageCard = styled.img`
  width: 100%;
  height: 122px;
  object-fit: cover;

  ${media.lessThan('medium')`
    width: 100%;
    height: auto;
  `}
`;

export const Spinner = styled.div`
  height: 100px;
  width: 100%;
`;
