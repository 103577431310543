import * as Yup from 'yup';
import messages from 'lib/core/errors';

const { requiredParam } = messages;

export const schema = Yup.object().shape({
  uuid: Yup.string().required(requiredParam('uuid')),
  password: Yup.string().required(requiredParam('password')),
});

export const defaultValues = {
  uuid: '',
  password: '',
};
