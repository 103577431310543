import styled, { css, DefaultTheme } from 'styled-components';
import media from 'styled-media-query';
import { SubTitle, Heading } from 'components/structure';
import * as LogoStyles from 'components/structure/Logo/Logo.styles';
import { THEME } from 'styles/themes';

type CountdownText = {
  isActive?: boolean;
};

const containerModifiers = {
  hasMemberGetMember: (theme: DefaultTheme) => css`
    ${media.greaterThan('large')`
      min-height: 100%;
      height: calc(100vh - ${theme.sizes.footer});
      overflow-y: auto;
    `}

    ${media.lessThan('large')`
      ${Content} {
        padding-top: ${theme.spacing.xl};
      }

      > svg {
        display: none;
      }
    `}
  `,
};

export const Container = styled.div<{ hasMemberGetMember?: boolean }>`
  ${({ theme, hasMemberGetMember }) => css`
    background: ${theme.colors.neutral.white} url(${THEME.BackgroundCountdown})
      no-repeat center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    min-height: 100vh;
    /* height: calc(100vh - ${theme.sizes.footer}); */
    width: 100%;
    padding: ${theme.spacing.lg} ${theme.spacing['2xl']};

    ${media.lessThan('large')`
      padding: ${theme.spacing.xl} ${theme.spacing.md} ${theme.spacing.md};
      height: 100%;


      ${LogoStyles.Wrapper} {
        margin-bottom: 0;
      }
    `}

    ${SubTitle} {
      color: ${theme.colors.grayscale.dark.paragraph};
    }

    ${hasMemberGetMember && containerModifiers.hasMemberGetMember(theme)}
  `}
`;

export const Content = styled.main`
  ${({ theme }) => css`
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: center; */
    /* margin-bottom: ${theme.spacing['2xl']}; */

    > h3 {
      &:not(:last-child) {
        margin-bottom: 0px;
      }
    }
    ${media.greaterThan('large')`
     align-items: center;
    justify-content: center;
     margin-bottom: ${theme.spacing['2xl']};
    `}

    ${media.lessThan('medium')`
      text-align: center;
      margin-bottom: ${theme.spacing.lg};
    `}
  `};
`;
export const Card = styled.main`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  backdrop-filter: blur(16px);
  padding: 8px 0 0;
  margin: 60px 0 140px;

  ${media.lessThan('medium')`
  /* margin-bottom:60px; */
  margin: 0px 0 30px;

    `}

  ${media.greaterThan('huge')`
    padding: 48px 0 ;

    `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.grayscale.dark.title};
    font-size: 64px;
    margin-bottom: ${theme.spacing.sm};
    text-align: center;
    width: 641px;

    ${media.lessThan('medium')`
      font-size: ${theme.typography.sizes.xl};
      line-height: 40px;
      margin-top: ${theme.spacing.md};
      width: auto;
    `}
  `}
`;

export const TitleTwo = styled.h2`
  ${({ theme }) => css`
    color: ${theme.colors.grayscale.dark.title};
    font-size: 1.5rem;
    margin-bottom: ${theme.spacing.sm};
    text-align: center;
    width: 511px;

    ${media.lessThan('medium')`
      font-size: ${theme.typography.sizes.md};
      line-height: 40px;
      margin-top: ${theme.spacing.md};
      width: auto;
    `}
  `}
`;
export const FooterDescription = styled.h2`
  ${({ theme }) => css`
    color: ${theme.colors.grayscale.dark.title};
    font-size: 16px;
    margin-bottom: ${theme.spacing.sm};
    font-weight: 400;
    text-align: center;
    width: 450px;

    ${media.lessThan('medium')`
      font-size: ${theme.typography.sizes.sm};
      line-height: 20px;
      margin-top: ${theme.spacing.md};
      width: auto;
    `}
  `}
`;

export const CountdownContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ${theme.spacing.lg};
    width: 100%;

    ${media.lessThan('large')`
      flex-wrap: wrap;
    `}

    ${media.lessThan('small')`
      flex-direction:column;
    `}
  `}
`;

const textModifiers = {
  isActive: (theme: DefaultTheme) => css`
    ${CountText}, ${PeriodText} {
      color: ${theme.colors.neutral.white};
    }
  `,
};

export const CurrentTimeContainer = styled.div<CountdownText>`
  font-size: 12px;
  ${({ theme, isActive }) => css`
    padding: 0 ${theme.spacing.xl};
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    word-break: break-all;

    ${media.lessThan('medium')`
      margin-bottom: ${theme.spacing.sm};
    `}

    ${isActive && textModifiers.isActive(theme)}
  `}
`;

export const CountText = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.neutral.white};
    font-size: calc(${theme.typography.sizes['4xl']} * 2);
    font-weight: normal;

    ${media.lessThan('medium')`
      font-size: ${theme.typography.sizes['4xl']};
      line-height: 64px;
      font-weight: 700;
    `}
  `}
`;

export const PeriodText = styled.h3`
  ${({ theme }) => css`
    color: ${theme.colors.neutral.white};
    letter-spacing: 10px;
    margin-top: ${theme.spacing.sm};

    ${media.lessThan('medium')`
      font-size: ${theme.typography.sizes.sm};
      line-height: ${theme.typography.lineHeight.base};
      font-weight: 700;
      text-align: center;
      margin-top: 0;
      margin-left: 10px;
    `}
  `}
`;

export const CountdownFooter = styled.footer`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    //margin-top: 20px;

    > small {
      text-align: center;
      color: ${theme.colors.neutral.white};
      max-width: 420px;
      margin-top: ${theme.spacing.sm};
    }
  `}
`;

export const SocialLinksContainer = styled.div`
  ${({ theme }) => css`
    margin-top: 50px;
    display: flex;
    align-items: center;
    margin-bottom: ${theme.spacing.sm};
    ${media.lessThan('medium')`

      flex-direction: column;
      margin-top: 50px;
    `}
    > strong {
      color: ${theme.colors.neutral.white};
      margin-right: ${theme.spacing.sm};
    }

    > p {
      margin-bottom: 0;
    }
  `}
`;

export const SocialLinksList = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-left: 20px;

    a {
      color: ${theme.colors.neutral.white};
      margin-right: 20px;
    }

    svg {
      color: ${theme.colors.neutral.white};
      width: 18px;
      height: 18px;
    }
  `}
`;

export const Footer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${theme.colors.neutral.black};
    height: ${theme.sizes.footer};
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 ${theme.spacing.lg};

    > svg {
      display: none;
    }

    ${media.lessThan('large')`
      top: 0;
      padding: 0 ${theme.spacing.md};

      > svg {
        width: 80px;
        display: block;
      }
    `}
  `}
`;

export const Aside = styled.aside`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    > svg {
      cursor: pointer;
      color: ${theme.colors.grayscale.dark.title};
    }

    ${Heading} {
      margin-bottom: 0;
      margin-left: ${theme.spacing.lg};
      font-size: ${theme.typography.sizes.lg};
    }

    ${media.lessThan('large')`
      > svg {
        width: 14px;
        height: 14px;
      }

      ${Heading} {
        font-size: ${theme.typography.sizes.sm};
        margin-left: ${theme.spacing.sm};
      }
    `}
  `}
`;

export const FooterTitle = styled.h3`
  ${({ theme }) => css`
    color: ${theme.colors.neutral.white};
    font-size: ${theme.typography.sizes['xl']};
    margin-bottom: ${theme.spacing.md};
    font-weight: 400;
    max-width: 124px;
  `}
`;

export const Paragraph = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.grayscale.light.title};
    font-size: ${theme.typography.sizes.base};
    font-weight: 400px;
    line-height: 24px;
    text-align: center;
    position: absolute;
    width: 434px;
    height: 48px;
    left: 35%;
    top: 80%;

    ${media.lessThan('medium')`
      font-size: ${theme.typography.sizes.xs};
      line-height: 24px;
      position: absolute;
      width: 287px;
      height: 72px;
      left: calc(50% - 287px/2);
      top:90%;

      @media (max-width: 375px){
        width: 280px;
        height: 50px;
        top:100%;
      }
    `}
  `}
`;

export const CreatorWeekBr = styled.h5`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  position: absolute;
  width: 135px;
  left: 730px;
  top: 743px;
  ${media.lessThan('medium')`
  left: 30%;
  top: 780px;
  `};
  ${media.lessThan('large')`
  left: 600px;
  `};
`;

export const ContentTitle = styled.div`
  display: inline;
  text-align: center;
  width: 80%;
  margin-top: 45px;
  ${media.lessThan('medium')`
      margin-left:10px ;
  `}
`;

export const Link = styled.a`
  ${({ theme }) => css`
    color: ${theme.colors.grayscale.dark.title};
    font-size: 22px;
    font-family: ${theme.typography.family.secondary};
    font-weight: bold;
    text-decoration: underline;

    ${media.lessThan('medium')`
      font-size: ${theme.typography.sizes.md};
      line-height: ${theme.typography.lineHeight.base};
    `}
  `}
`;

export const ContentFooter = styled.div`
  display: inline;
`;
