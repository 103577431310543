import { useEffect, useState } from 'react';
import { dispatchGAEvent } from 'services/google';
import { useSignOut } from 'useCases/auth';
import { useEvent } from 'useCases/events';
import { useTranslate, useZendesk } from 'hooks';
import { LanguageStream, ThemeCustomProvider, useStreamContext } from 'contexts';
import { UserMenu, UserMenuItem } from 'components/contexts/users/UserMenu';
import { CFCode, Paragraph, Icon } from 'components/structure';
import { ChangeLanguageSelect } from 'components/structure';
import { floatingMenuItemsMock } from 'components/structure/Navbar/Navbar.mock';
import { Notification } from 'components/structure/Notification/Notification';
import EventOptions from 'styles/overrides/EventOptions.theme';
import * as FooterTheme from 'styles/overrides/Footer.theme';
import { EventAction, EventCategory } from 'interfaces/analytics';
import { GA_EVENTS } from 'constants/enums';
import { Skeleton } from './Footer.skeleton';
import * as S from './Footer.styles';

export type FooterProps = {
  avatar?: string;
  userName?: string;
  show: boolean;
  onChangeLanguage?: (lang: LanguageStream) => void;
  hasInteractions?: boolean;
  isTranslationChange?: boolean;
  language: LanguageStream;
  hasNotification: boolean;
  hasMemberGetMember?: boolean;
  menuItems: UserMenuItem[];
  blockedScreen?: boolean;
};

export const Footer = ({
  avatar,
  userName,
  show,
  onChangeLanguage,
  hasInteractions,
  isTranslationChange,
  language,
  hasNotification,
  blockedScreen,
}: // hasMemberGetMember,
  //menuItems,
  FooterProps) => {
  const translation = useTranslate();
  const handleSupport = useZendesk();
  const { data: event } = useEvent();
  const { handleLogout } = useSignOut();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleChangeLanguage = (lang: LanguageStream) => {
    dispatchGAEvent({
      category: EventCategory.Stream,
      action: EventAction.click,
      label: GA_EVENTS.stream.labels.selectedAudio(language),
    });
    onChangeLanguage && onChangeLanguage(lang);
  };
  useEffect(() => {
    dispatchGAEvent({
      category: EventCategory.Stream,
      action: EventAction.click,
      label: GA_EVENTS.stream.labels.selectedAudio(language),
    });
  }, [isTranslationChange, language]);
  return (
    <ThemeCustomProvider {...FooterTheme}>
      <S.Container
        show={show}
        hasInteractions={hasInteractions}
        blockedScreen={blockedScreen}
      >
        <S.AsideLeftContent>
          <UserMenu
            avatar={avatar}
            avatarLabel={userName}
            handleLogout={handleLogout}
            items={floatingMenuItemsMock}
            isOpen={isMenuOpen}
            handleToggle={setIsMenuOpen}
          />
          <S.Content>
            <Paragraph dark bold>
              {translation('footer.welcome')}
            </Paragraph>
            <S.Flex>
              {userName ? (
                <>
                  <Paragraph dark>{userName}</Paragraph>
                  <CFCode isDesktop marginLeft />
                </>
              ) : (
                <Skeleton />
              )}
            </S.Flex>
          </S.Content>
        </S.AsideLeftContent>
        <S.AsideContent>
          {isTranslationChange && (
            <ChangeLanguageSelect
              language={language}
              handleChangeLanguage={handleChangeLanguage}
            />
          )}
          {event?.event.zendesk?.active && (
            <S.IconButton
              onClick={handleSupport}
              fullWidth
              isRound
              color="primary"
            >
              <Icon icon="IcSupport" />
              {translation('footer.support')}
            </S.IconButton>
          )}
          {EventOptions.hasNotification && hasNotification && <Notification />}
        </S.AsideContent>
      </S.Container>
    </ThemeCustomProvider>
  );
};
