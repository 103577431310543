import { useParams } from 'react-router-dom';
import { ScheduleItemDetails } from 'components/contexts/schedule';
import { Container } from 'components/structure';

type Params = {
  id: string;
};

export const ScheduleDetails = () => {
  const { id } = useParams() as Params;

  return (
    <Container>
      <ScheduleItemDetails id={id} />
    </Container>
  );
};
