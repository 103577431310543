import { isSafari } from 'react-device-detect';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from 'styled-components';
import { ToastProvider, IntlProvider, useSettingsContext } from 'contexts';
import GlobalStyles from 'styles/global';
import theme from 'styles/theme';
import { WithChildren } from 'interfaces/children';

export const AppContexts = ({ children }: WithChildren) => {
  const { isBlockScroll, hasStream } = useSettingsContext();

  return (
    <ThemeProvider theme={theme}>
      <ToastProvider>
        <IntlProvider>
          <GlobalStyles
            blockScroll={isBlockScroll}
            hasStream={hasStream}
            isSafari={isSafari}
          />
          <HelmetProvider>{children}</HelmetProvider>
        </IntlProvider>
      </ToastProvider>
    </ThemeProvider>
  );
};
