import styled, { css } from 'styled-components';

export const EmptyState = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.grayscale.dark.info};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    height: 100%;
    margin: 0 auto;
    width: calc(100% - ${theme.spacing.lg});

    h4,
    p {
      text-align: center;
      width: 216px;
    }

    svg {
      height: 40px;
      width: 40px;
      margin-bottom: ${theme.spacing.sm};
      color: ${theme.colors.grayscale.dark.info};
    }
  `}
`;
