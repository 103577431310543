import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSchedulesByEvent } from 'useCases/schedules';
import { useRealTimeSession } from 'useCases/session';
import { useTranslate } from 'hooks';
import { useFormatDate } from 'hooks/useFormatDate';
import { dateFormatHandler } from 'lib/contexts/date';
import { ScheduleList } from 'pages/schedule/ScheduleList/ScheduleList';
import { Container, Spinner, ButtonGroup } from 'components/structure';
import * as S from './Schedule.styles';

export const Schedule = () => {
  // const session = useRealTimeSession();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedDateParams = searchParams.get('selectedDate');
  const [selectedDate, setSelectedDate] = useState(selectedDateParams ?? '');
  const translate = useTranslate();
  const { data: scheduleData, isLoading } = useSchedulesByEvent();
  const dates = useFormatDate(scheduleData?.eventDays.map((x) => x.date) || []);

  useEffect(() => {
    if (selectedDateParams) {
      return;
    }

    const initialDate = scheduleData?.eventDays[0].date;

    if (initialDate) {
      setSelectedDate(dateFormatHandler(initialDate));
    }
  }, [selectedDateParams, scheduleData?.eventDays]);

  useEffect(() => {
    if (selectedDate) {
      setSearchParams({ selectedDate });
    }
  }, [setSearchParams, selectedDate]);

  const handleSelectDate = (value: string) => setSelectedDate(value);

  if (!selectedDate || isLoading) {
    return <Spinner />;
  }

  const streamGroups = scheduleData?.eventDays?.find(
    (x) => dateFormatHandler(x.date) === selectedDate,
  )?.streamGroups;

  return (
    <Container>
      <S.Container>
        <S.Content>
          <S.Title>{translate('schedule.title')}</S.Title>
          <ButtonGroup
            onChange={handleSelectDate}
            value={selectedDate}
            options={dates}
          />
          <ScheduleList
            isLoading={isLoading}
            streamGroups={streamGroups || []}
          />
        </S.Content>
      </S.Container>
    </Container>
  );
};
