import en from './en.json';
import es from './es.json';
import pt from './pt-br.json';

export const notificationPT = {
  notification: {
    ...pt,
  },
};

export const notificationEN = {
  notification: {
    ...en,
  },
};

export const notificationES = {
  notification: {
    ...es,
  },
};
