import * as Yup from 'yup';
import { isBayer } from 'lib/contexts';
import { passwordStrength, passwordStrengthForVale } from 'lib/contexts/input';
import messages from 'lib/core/errors';
import { getEventThemeName } from 'lib/core/event';
import EventOptions from 'styles/overrides/EventOptions.theme';
import { BRAZIL_COUNTRY_CODE, ProjectNames } from 'constants/enums';

const { requiredParam, paramsDontMatch, invalidParam } = messages;

const configurationPasswordValidation = Yup.string()
  .trim()
  .when('rePassword', {
    is: (value: string) => !!value,
    then: Yup.string().oneOf(
      [Yup.ref('rePassword'), null],
      paramsDontMatch('password'),
    ),
  })
  .transform((value) => (value === '' ? undefined : value))
  .default(undefined)
  .notRequired();

// eslint-disable-next-line no-useless-escape
//const validateDuplicateURL = /^(https:\/\/|http:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-])*\/?$/;

const yupPassword =
  getEventThemeName() === ProjectNames.VALE
    ? Yup.string()
        .trim()
        .transform((value) => (value === '' ? undefined : value))
        .default(undefined)
        .notRequired()
        .matches(
          passwordStrengthForVale.hasOnlySixNumbers,
          messages.invalidParam('passwordHasSixNumbers'),
        )
    : EventOptions.hasPasswordStrength
    ? configurationPasswordValidation
        .min(8, invalidParam('password'))
        .matches(
          passwordStrength.hasNumber,
          messages.invalidParam('passwordHasNumber'),
        )
        .matches(
          passwordStrength.hasSymbol,
          messages.invalidParam('passwordHasSymbol'),
        )
        .matches(
          passwordStrength.hasCapitalLetter,
          messages.invalidParam('passwordHasCapitalLetter'),
        )
        .matches(
          passwordStrength.hasLowercaseLetter,
          messages.invalidParam('passwordHasLowercaseLetter'),
        )
    : configurationPasswordValidation;

export const schema = Yup.object().shape(
  {
    firstName: Yup.string().required(requiredParam('name')),
    lastName: Yup.string().required(requiredParam('lastName')),
    email: Yup.string().email().required(requiredParam('uuid')),
    // preferableLanguage: Yup.string().required(requiredParam('language')),
    // linkedInUrl: Yup.string()
    //   .url(invalidParam('linkedin'))
    //   .matches(validateDuplicateURL, invalidParam('linkedin')),
    country: Yup.string().required(requiredParam('country')),
    state: Yup.string().when('country', {
      is: BRAZIL_COUNTRY_CODE.toString(),
      then: Yup.string().required(requiredParam('state')),
    }),
    password: yupPassword,
    rePassword: Yup.string().when('password', {
      is: (value: string) => !!value,
      then: Yup.string()
        .required(requiredParam('confirm-password'))
        .oneOf([Yup.ref('password'), null], paramsDontMatch('password')),
      otherwise: Yup.string().notRequired().default(undefined),
    }),
  },
  [['rePassword', 'password']],
);

export const schemaBayer = Yup.object().shape({
  firstName: Yup.string().required(requiredParam('name')),
  lastName: Yup.string().required(requiredParam('lastName')),
  email: Yup.string().email().required(requiredParam('uuid')),
  image: Yup.string(),
  professionalId: Yup.string().required(requiredParam('professionalId')),
  preferableLanguage: Yup.string().required(requiredParam('language')),
  country: Yup.string().required(requiredParam('country')),
  state: Yup.string().when('country', {
    is: '76',
    then: Yup.string().required(requiredParam('state')),
  }),
  profile: Yup.object({}).when('email', {
    is: (email: string) => !isBayer(email),
    then: Yup.object({
      specialty: Yup.string().required(requiredParam('specialty')),
    }),
    otherwise: Yup.object().nullable().default(null),
  }),
  password: Yup.string()
    .trim()
    .transform((value) => (value === '' ? undefined : value))
    .default(undefined)
    .notRequired()
    .min(8, invalidParam('password'))
    .matches(
      passwordStrength.hasNumber,
      messages.invalidParam('passwordHasNumber'),
    )
    .matches(
      passwordStrength.hasSymbol,
      messages.invalidParam('passwordHasSymbol'),
    )
    .matches(
      passwordStrength.hasCapitalLetter,
      messages.invalidParam('passwordHasCapitalLetter'),
    )
    .matches(
      passwordStrength.hasLowercaseLetter,
      messages.invalidParam('passwordHasLowercaseLetter'),
    ),
  rePassword: Yup.string().when('password', {
    is: (value: string) => !!value,
    then: Yup.string()
      .required(requiredParam('confirm-password'))
      .oneOf([Yup.ref('password'), null], paramsDontMatch('password')),
    otherwise: Yup.string().notRequired().default(undefined),
  }),
});

export const defaultValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: undefined,
  rePassword: undefined,
  preferableLanguage: '',
  country: '',
  profile: {
    area: '',
  },
  state: '',
};

export const defaultValuesBayer = {
  firstName: '',
  lastName: '',
  email: '',
  country: '',
  professionalId: '',
  profile: {
    specialty: '',
  },
  preferableLanguage: '',
  state: '',
  password: undefined,
  rePassword: undefined,
};
