/* eslint-disable @typescript-eslint/no-unused-vars */
import styled, { css, DefaultTheme } from 'styled-components';
import media from 'styled-media-query';
import { Button, ButtonProps } from 'components/structure';
import * as PopoverStyles from 'components/structure/Popover/Popover.styles';

type ContainerProps = ButtonProps & {
  badge?: boolean;
};

export const Container = styled.div`
  ${({ theme }) => css`
    & ${PopoverStyles.Content} {
      padding: ${theme.spacing.xs};
      right: -10px;
      max-width: max-content;

      ${media.lessThan('medium')`
        transform: scale(.90);
        right: -40px;
      `}
    }
  `}
`;

const buttonModifiers = {
  badge: (theme: DefaultTheme) => css`
    &::after {
      background-color: ${theme.colors.primary.active};
      width: 12px;
      height: 12px;
      content: '';
      border-radius: 50%;
      top: -3px;
      position: absolute;
      right: 0;
    }
  `,
};

export const ButtonNotification = styled(Button)<ContainerProps>`
  ${({ theme, badge }) => css`
    && {
      background-color: transparent;
      width: 32px;
      min-width: 32px;
      color: ${theme.colors.neutral.black};
      display: flex;
      min-height: 30px;
      height: 32px;
      margin-right: ${theme.spacing.sm};
      border-radius: ${theme.spacing.sm};
      padding-right: 5px;
      padding-left: 2px;
      position: relative;

      &:hover,
      &:focus {
        background-color: ${theme.colors.grayscale.light.line};
      }

      svg {
        &:nth-of-type(1) {
          height: 25px;
          width: 25px;
          path {
            stroke: ${theme.colors.primary.main};
          }
        }
      }

      ${badge && buttonModifiers.badge(theme)}
    }
  `}
`;
