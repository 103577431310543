import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import media from 'styled-media-query';
import { Divider } from 'pages/configurations/divider';
import { SubTitle, Icon } from 'components/structure';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 0px 10px 2px;
  align-items: center;
`;

export const TabRouterContainer = styled(NavLink)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-decoration: none;

    ${SubTitle} {
      white-space: pre;
      margin: 0px ${theme.spacing.lg} 0px ${theme.spacing.xs};
    }

    ${SubTitle}, ${Icon} {
      color: ${theme.colors.grayscale.dark.info};
    }

    ${Divider} {
      background-color: ${theme.colors.grayscale.dark.line};
      box-sizing: content-box;
      margin-top: 2px;
    }

    &.active {
      ${SubTitle}, ${Icon} {
        color: ${theme.colors.secondary.main};
      }
      ${Divider} {
        margin-top: 0px;
        border-top: 2px solid ${theme.colors.secondary.main};
        background-color: ${theme.colors.secondary.main};
      }
    }

    ${media.lessThan('large')`
      ${Container} {
        padding: 0px ${theme.spacing.lg} 14px 2px;
      }
      ${SubTitle} {
        display:none;
        font-size: ${theme.typography.sizes.md};
        margin-right: 0px;
      }
      ${Icon} {
        width: 18px;
        height: 18px;
      }
      &.active {
        ${SubTitle} {
          display:block;
        }
        ${Container} {
          padding: 0px ${theme.spacing.lg} 12px 2px;
        }
      }
    `}
  `}
`;
