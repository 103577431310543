import styled, { css, keyframes } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 456px;
    border: 1px solid ${theme.colors.grayscale.dark.line};
    border-radius: ${theme.shapes.borderRadius.md};
    overflow: hidden;
    box-sizing: border-box;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);

    ${media.lessThan('large')`
      width: 100%;
      min-width: 0;
      height: 414px;
    `}
  `}
`;

export const ClusterMarker = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.primary.main};
    backdrop-filter: blur(50px);
    border-radius: 50%;
    padding: 10px;
    width: 4px;
    height: 4px;
  `}
`;

type BallonProps = {
  show: boolean;
};
type WrapperProps = Pick<BallonProps, 'show'>;

const wrapperModifiers = {
  show: () => css`
    display: block;
  `,
};

export const BodyBallon = styled.div<WrapperProps>`
  ${({ theme, show }) => css`
    background: ${theme.colors.background.dark.input};
    backdrop-filter: blur(50px);
    box-sizing: border-box;
    border-radius: ${theme.shapes.borderRadius.sm};
    padding: ${theme.shapes.borderRadius.sm};
    width: 214px;
    display: flex;
    flex-flow: column;
    position: absolute;
    max-height: 220px;
    overflow-y: auto;
    z-index: ${theme.layers.alwaysOnTop};
    min-height: 50px;
    border: 1px solid ${theme.colors.grayscale.dark.info};
    overflow: hidden;
    display: none;
    ${show && wrapperModifiers['show']()};
  `}
`;

export const HeaderBallon = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.sm} 4px ${theme.spacing['2xs']};
    border-bottom: 1px solid ${theme.colors.grayscale.dark.line};
    margin-bottom: ${theme.spacing.sm};
    display: flex;
    align-items: center;
    span {
      color: ${theme.colors.grayscale.dark.info};
      font-weight: bold;
      font-size: ${theme.typography.sizes.base};
      margin-left: ${theme.spacing['2xs']};
    }
  `}
`;

export const DotBallon = styled.div`
  ${({ theme }) => css`
    width: 4px;
    height: 4px;
    border-radius: ${theme.shapes.borderRadius.xs};
    background: ${theme.colors.primary.main};
  `}
`;

export const UserName = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.typography.sizes.base};
    color: ${theme.colors.neutral.white};
    margin-left: ${theme.spacing['2xs']};
  `}
`;

export const UserRow = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-bottom: ${theme.spacing.sm};
  `}
`;

const rippleEffect = keyframes`
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
`;

export const Loading = styled.div`
  ${({ theme }) => css`
    height: 80px;
    width: 80px;
    transform: scale(0.35);

    & div {
      border: 4px solid ${theme.colors.primary.main};
      position: absolute;
      opacity: 1;
      border-radius: 50%;
      animation: ${rippleEffect} 1.5s cubic-bezier(0, 0.2, 0.8, 1) infinite;

      &:nth-child(2) {
        animation-delay: -0.5s;
      }
    }
  `}
`;
