/* eslint-disable @typescript-eslint/no-explicit-any */
// import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslate } from 'hooks';
import { useIntlContext } from 'contexts';
import { MaterialsTypes } from 'components/contexts/Materials';
import { Heading } from 'components/structure';
// import { Skeleton } from './materials.skeleton';
import { MaterialsAccordionItem } from 'components/structure/MaterialsAccordionItem';
import materialsJSON from './materials.json';
import * as S from './materials.styles';

export type Request = {
  materials: Material[];
};

export type Material = {
  title: string;
  id: string;
  description: string;
  materiais: SubMaterial[];
};

export type SubMaterial = {
  id: string;
  cover: string;
  downloadUrl: string;
  label: string;
  link: string;
  name: string;
  type: MaterialsTypes;
  seeMore: string;
  url: string;
  'button-name': string;
};

export const MaterialsCreators = () => {
  const translate = useTranslate();
  const { language } = useIntlContext();

  const [materials, setMaterials] = useState<Material[]>();

  // const url =
  //   'https://storage-files-tm1-eventos.s3.amazonaws.com/creatorweek2022/materials/materials.json';

  useEffect(() => {
    const getData = async () => {
      // const response = await axios.get<Request>(url);
      setMaterials((materialsJSON as any)?.materials);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  // if (isLoading) {
  //   return (
  //     <S.Wrapper>
  //       <Heading>{translate('materials.title')}</Heading>
  //       <Skeleton />
  //     </S.Wrapper>
  //   );
  // }

  return (
    <S.Wrapper>
      <Heading>{translate('materials.title')}</Heading>
      <S.subTitle>{translate('materials.subTitle')}</S.subTitle>
      <S.subTitleLink
        href="https://storage-files-tm1-eventos.s3.amazonaws.com/creatorweek2022/materials/MetaforCreatorsPlaybook_BR.pdf"
        target="_blank"
        rel="noreferrer"
      >
        {translate('materials.subTitleLink')}
      </S.subTitleLink>
      <S.subTitle>{translate('materials.subTitleEnd')}</S.subTitle>
      <S.paragraph>{translate('materials.paragraph')}</S.paragraph>
      {materials?.map((material) => (
        <MaterialsAccordionItem
          description={material.description}
          title={material.title}
          key={material.id}
          subMaterials={material.materiais}
        />
      ))}
    </S.Wrapper>
  );
};
