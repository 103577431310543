import styled, { css, DefaultTheme } from 'styled-components';

type CommonProps = {
  isOpen?: boolean;
};

const menuActionsModifiers = {
  open: (theme: DefaultTheme) => css`
    color: ${theme.colors.primary.main};

    svg {
      transform: rotate(-180deg);
    }

    ${UserAvatar} {
      svg {
        transform: rotate(0);
      }
    }
  `,
};

export const MenuButtonLogout = styled.button``;

export const MenuActions = styled.div<CommonProps>`
  ${({ theme, isOpen = false }) => css`
    color: ${theme.colors.grayscale.dark.info};
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;

    svg {
      height: 6px;
      transition: transform 0.2s linear;
      color: white;
    }

    ${isOpen && menuActionsModifiers.open(theme)};
  `}
`;

export const UserAvatar = styled.div`
  ${({ theme }) => css`
    border: 1px solid transparent;
    border-radius: 50%;
    color: ${theme.colors.primary.main};
    overflow: hidden;
    margin-right: ${theme.spacing.xs};
    height: 46px;
    width: 46px;

    svg,
    img {
      height: 100%;
      width: auto;
    }
  `}
`;

export const FloatingOptions = styled.div<CommonProps>`
  ${({ theme }) => css`
    opacity: 1;
    color: ${theme.colors.neutral.white};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: intrinsic;
    a,
    button {
      color: ${theme.colors.neutral.black};
      font-size: ${theme.typography.family.primary};
      line-height: ${theme.typography.lineHeight.base};
      font-size: ${theme.typography.sizes.sm};
      text-align: start;
      white-space: nowrap;
    }

    & *:not(:last-child) {
      margin-bottom: ${theme.spacing.xs};
    }
  `}
`;
