import { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useClickOutside } from 'hooks';
import {
  useNotificationContext,
  useInteractionsContext,
  useChatHandlersContext,
  useChatContext,
} from 'contexts';
import { Icon, Popover } from 'components/structure';
import { NotificationItem } from 'interfaces/notification';
import { InteractionsOpenState } from 'constants/enums';
import {
  InteractionsTabs,
  NotificationTypes,
  PopoverArrowDirection,
} from 'constants/enums';
import { ROUTES } from 'constants/urls';
import * as S from './Notification.styles';
import { NotificationList } from './NotificationList/NotificationList';

export type NotificationProps = {
  placement?: 'bottom' | undefined;
  arrowAlign?: PopoverArrowDirection;
};

export type NotificationHandle = {
  [key: string]: (value: string) => void;
};

const useNotificationHandlers = () => {
  const navigate = useNavigate();
  const { setTab, setOpenState } = useInteractionsContext();
  const {
    handleJoinPrivateChannel,
    handleJoinStreamChannel,
  } = useChatHandlersContext();

  const { setRoom, setShowRoom, setPlenary, plenary, room } = useChatContext();
  const { pathname } = useLocation();
  const streamId = pathname.split('/'[3]);
  return {
    [NotificationTypes.MESSAGE]: (value: string) => {
      setTab(InteractionsTabs.chat);
      handleJoinPrivateChannel(value);
      isMobile && setOpenState(InteractionsOpenState.full);
    },
    [NotificationTypes.NEW_LIVE_STREAM]: (value: string) => {
      navigate(ROUTES.stream.getLink('id', value));
    },
    [NotificationTypes.MENTION]: (value: string) => {
      const streamId = value.replace('STREAM-', '')
      if (streamId === plenary.id) {
        setTab(InteractionsTabs.chat);
        setRoom({
          id: 'STREAM-' + plenary.id,
          isPrivate: false,
          name: plenary.name,
        });
        setShowRoom(true)
      } else {
        window.location.href = ROUTES.stream.getLink('id', streamId)
      }

    },
  } as NotificationHandle;
};

export const Notification = ({
  arrowAlign = 'right',
  placement,
}: NotificationProps) => {
  const [open, setOpen] = useState(false);
  const [hasBadge, setHasBadge] = useState(false);
  const { notifications, clearAll, setViewed } = useNotificationContext();
  const notificationHandlers = useNotificationHandlers();

  useEffect(() => {
    setHasBadge(notifications.some((notification) => !notification.viewed));
  }, [notifications]);

  const handleClick = () => setOpen((prevState) => !prevState);

  const elRef = useClickOutside(() => {
    setOpen((prevState) => prevState && false);
  });

  const handleClear = () => {
    handleClick();
    setTimeout(() => {
      clearAll();
    }, 1000);
  };

  const handleNotification = (notification: NotificationItem) => {
    setViewed(notification.id, notification.type);
    if (notificationHandlers[notification.type]) {
      notificationHandlers[notification.type](notification.value);
    }
    handleClick();
  };

  return (
    <S.Container ref={elRef}>
      <Popover.Root
        isOpen={open}
        placement={placement}
        arrowDirection={arrowAlign}
        distance={isMobile ? '30px' : '40px'}
      >
        <Popover.Content>
          <NotificationList
            items={notifications}
            handleClick={handleNotification}
            handleClear={handleClear}
          />
        </Popover.Content>
      </Popover.Root>
      <S.ButtonNotification
        badge={hasBadge}
        color="primary"
        onClick={handleClick}
        aria-label="btn-notification"
        aria-hidden={open}
      >
        <Icon icon="IcBell" />
      </S.ButtonNotification>
    </S.Container>
  );
};
