import styled, { css } from 'styled-components';
import * as Typography from 'components/structure/Typography/Typography';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    height: 100%;
    width: 100%;

    ${Typography.Paragraph} {
      margin: ${theme.spacing.md} 0;
      color: ${theme.colors.grayscale.dark.title};
    }
  `}
`;

export const TopContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${Typography.SubTitle} {
    margin-bottom: 0;
    color: ${({ theme }) => theme.colors.grayscale.dark.title};
  }
`;

export const AmountVotes = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.neutral.black};
    border-radius: ${theme.shapes.borderRadius.lg};
    padding: ${theme.spacing['2xs']};
    width: 63px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      color: ${theme.colors.grayscale.dark.title};
      font-size: ${theme.typography.sizes.xs};
    }
  `}
`;

export const AmountText = styled.span``;

export const Result = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.neutral.black};
    border-radius: ${theme.shapes.borderRadius.sm};
    display: grid;
    grid-template-columns: 1fr 36px;
    grid-template-rows: 1fr 1fr;
    grid-gap: 0 6px;
    align-items: center;
    padding: 6px 12px;
    margin-bottom: ${theme.spacing.sm};

    &:first-child {
      ${ResultTitle} {
        color: ${theme.colors.neutral.white};
      }
    }

    strong {
      color: ${theme.colors.primary.main};
    }
  `}
`;

export const ResultTitle = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.grayscale.dark.info};
    grid-column: 1/-1;
  `}
`;

type ProgressBarProps = {
  width?: number;
};

export const ProgressBar = styled.span<ProgressBarProps>`
  ${({ theme, width = 0 }) => css`
    border: 1px solid ${theme.colors.primary.main};
    border-radius: ${theme.shapes.borderRadius.sm};
    height: 8px;
    overflow: hidden;
    position: relative;

    &::after {
      background-color: ${theme.colors.primary.main};
      content: '';
      height: 100%;
      width: ${width}%;
      position: absolute;
      left: 0;
      top: 0;
      transition: width 0.2s linear;
    }
  `}
`;
