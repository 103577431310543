import { mutate } from 'swr';
import { useEnv, useFetch } from 'hooks';
import { getEventId } from 'lib/core/event';
import { Event } from 'interfaces/event';
import { EVENTS } from 'constants/endpoints';

export const useEvent = () => useEventById(useEnv().eventId);

export const useEventById = (id: string) =>
  useFetch<{ event: Event }>(EVENTS.BY_ID(id));

export const revalidateOnChangeLanguage = (preferableLanguage: string) =>
  mutate(
    EVENTS.BY_ID(getEventId()!),
    (cachedEvent: Event) => ({
      ...cachedEvent,
      preferableLanguage,
    }),
    true,
  );
