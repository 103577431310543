import en from './en.json';
import es from './es.json';
import pt from './pt-br.json';

export const interactionsPT = {
  interactions: pt,
};

export const interactionsEN = {
  interactions: en,
};

export const interactionsES = {
  interactions: es,
};
