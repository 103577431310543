import styled, { css, DefaultTheme } from 'styled-components';
import { PopoverArrowDirection } from 'constants/enums';

type popoverProps = {
  show: boolean;
  bottom?: boolean;
  ref?: React.Ref<HTMLDivElement | null>;
  distance?: string;
  arrowDirection?: PopoverArrowDirection;
  placement?: 'bottom';
  colorScheme?: 'dark' | 'light';
};

export const popoverColorModifiers = {
  dark: ({ colors }: DefaultTheme) => css`
    background-color: ${colors.neutral.white};
    color: ${colors.popover?.dark?.title ?? colors.neutral.white};

    &::after {
      background-color: ${colors.neutral.white};
    }
  `,
  light: ({ colors }: DefaultTheme) => css`
    background-color: ${colors.popover?.light?.background ??
    colors.neutral.white};
    color: ${colors.popover?.light?.title ?? colors.neutral.black};

    &::after {
      background-color: ${colors.popover?.light?.background ??
      colors.neutral.white};
    }
  `,
};

export const popoverPositionModifiers = {
  bottom: (distance: string) => css`
    top: calc(100% + ${distance});
    bottom: initial;
    left: initial;
    transform: translateX(-50%);

    &:after {
      display: none;
    }
  `,
};

export const arrowModifiers = {
  center: () => css`
    bottom: -8px;
    left: 0;
    right: 0;
    margin: 0 auto;
  `,
  left: () => css`
    bottom: -8px;
    left: 32px;
  `,
  right: () => css`
    bottom: -8px;
    right: 32px;
  `,
};

export const Container = styled.div`
  position: relative;
`;

export const Content = styled.div<popoverProps>`
  ${({
    theme,
    show,
    placement,
    distance = '10px',
    arrowDirection = 'center',
    colorScheme = 'dark',
  }) => css`
    background-color: ${theme.colors.neutral.black};
    border: 1px solid ${theme.colors.primary.main};
    border-radius: ${theme.spacing.sm};
    color: ${theme.colors.neutral.white};
    transition: opacity 0.3s linear;
    cursor: pointer;
    padding: ${theme.spacing.sm} ${theme.spacing.md};
    max-width: 216px;
    display: flex;
    align-items: flex-start;
    flex-flow: column;
    min-width: 190px;
    position: absolute;
    bottom: calc(100% + ${distance});
    z-index: ${theme.layers.alwaysOnTop};
    opacity: ${show ? '1' : '0'};
    pointer-events: ${show ? 'all' : 'none'};

    a,
    button {
      transition: color 0.3s linear;
    }

    a:hover,
    button:hover {
      color: ${theme.colors.primary.main};
    }

    &::after {
      border: 1px solid ${theme.colors.primary.main};
      border-bottom: none;
      border-right: none;
      background-color: ${theme.colors.neutral.black};
      content: '';
      display: block;
      height: 15px;
      width: 15px;
      position: absolute;

      transform: rotate(225deg);

      ${arrowDirection && arrowModifiers[arrowDirection]()}
    }

    ${colorScheme && popoverColorModifiers[colorScheme](theme)};
    ${placement && popoverPositionModifiers[placement](distance)}
  `}
`;
