import styled, { css, DefaultTheme } from 'styled-components';
import media from 'styled-media-query';
import { Heading } from 'components/structure/Typography/Typography';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${theme.colors.primary.main};
    height: ${theme.sizes.footer};
    padding: 0 ${theme.spacing.lg};

    ${media.lessThan('large')`
      padding: ${theme.spacing.xs};

      > svg {
        width: 80px;
      }
    `}
  `}
`;

export const Aside = styled.aside`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    text-align: center;
    gap: 0 ${theme.spacing.xs};

    > div {
      width: 44px;
    }

    ${Heading} {
      margin-bottom: 0;
      font-size: ${theme.typography.sizes.lg};
    }
  `}
`;

export const PeriodText = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.typography.sizes.sm};
  `}
`;

const textModifiers = {
  hasDot: (theme: DefaultTheme) => css`
    &:after,
    &:before {
      content: '';
      position: absolute;
      top: 40%;
      right: -20%;
      transform: translate(-50%, -50%);
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: ${theme.colors.grayscale.dark.title};
    }

    &:before {
      top: 70%;
    }
  `,
};

export const TimeText = styled(Heading)<{ hasDot?: boolean }>`
  ${({ theme, hasDot }) => css`
    position: relative;
    ${hasDot && textModifiers.hasDot(theme)}
  `}
`;

export const IconWrapper = styled.button`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.md};

    ${media.lessThan('large')`
      margin-left: 0;
    `}
  `}
`;
