import { yupResolver } from '@hookform/resolvers/yup';
import { RefObject } from 'react';
import { isMobile } from 'react-device-detect';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useQueryString, useEnv } from 'hooks';
import { useTranslate } from 'hooks';
import { BackButton } from 'components/extra/BackButton';
// import { PublicFaqAnchor } from 'components/extra/PublicFaqAnchor';
import { PasswordField, TextField } from 'components/form';
import {
  Anchor,
  Button,
  CFCode,
  Form,
  FormActions,
  FormGroup,
  Heading,
  MediaMatch,
  Paragraph,
  ButtonSupport,
} from 'components/structure';
import { SignIn } from 'interfaces/auth';
import { ROUTES } from 'constants/urls';
//import LogoButton from 'assets/images/pages/creators/fundo.jpg';
import * as S from './SignInForm.styles';
import { defaultValues, schema } from './SignInForm.validations';

type SignInFormProps = {
  onSubmit: (payload: SignIn) => void;
  isPending?: boolean;
  showRecaptcha?: boolean;
  showSupportButton?: boolean;
  recaptchaRef?: RefObject<ReCAPTCHA>;
};

export const SignInForm = ({
  onSubmit,
  isPending,
  showRecaptcha,
  showSupportButton,
  recaptchaRef,
}: SignInFormProps) => {
  const translate = useTranslate();
  const params = useQueryString();
  const { googleRecaptcha } = useEnv();

  const { register, errors, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { ...defaultValues, ...params },
    shouldFocusError: false,
  });

  return (
    <S.Wrapper>
      <BackButton />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Heading>{translate('auth.signIn.title')}</Heading>
        <FormGroup>
          <TextField
            // style={{ backgroundColor: '#80808047' }}
            ref={register}
            type="uuid"
            name="uuid"
            autoComplete="uuid"
            label={translate('labels.uuid')}
            placeholder={translate('labels.uuid')}
            error={!!errors?.uuid?.message}
            helperText={translate(errors?.uuid?.message)}
            // readOnly
          />
          <S.Container>
            <PasswordField
              ref={register}
              name="password"
              autoComplete="current-password"
              label={translate('labels.password')}
              placeholder={translate('labels.password')}
              error={!!errors?.password?.message}
              helperText={translate(errors?.password?.message)}
            />
            <Anchor
              id="forgotPassword"
              as={Link}
              to={ROUTES.login.getLink('forgotPassword')}
            >
              {translate('auth.signIn.forgotPassword')}
            </Anchor>
          </S.Container>
        </FormGroup>
        {showRecaptcha && (
          <ReCAPTCHA ref={recaptchaRef} sitekey={googleRecaptcha} />
        )}
        {showSupportButton && (
          <FormGroup lgMargin>
            <ButtonSupport
              onlyIcon={isMobile}
              fixedDesktop={!isMobile}
              icon={isMobile ? 'IcHelp' : undefined}
            />
          </FormGroup>
        )}
        <FormActions>
          <CFCode />
          <Button isLoading={isPending} disabled={isPending} type="submit">
            {translate('labels.login')}
          </Button>
          <MediaMatch lessThan="medium">
            <Paragraph>
              {translate('auth.dontHaveAccount')}{' '}
              <Anchor
                as={Link}
                to={ROUTES.accreditation.getLink('gettingStarted')}
              >
                {translate('auth.signUpHere')}
              </Anchor>
            </Paragraph>
          </MediaMatch>
        </FormActions>
        <MediaMatch greaterThan="medium">
          <Paragraph>
            {translate('auth.dontHaveAccount')}
            <Anchor
              as={Link}
              to={ROUTES.accreditation.getLink('gettingStarted')}
            >
              {translate('auth.signUpHere')}
            </Anchor>
          </Paragraph>
          <br />
          {/* <PublicFaqAnchor /> */}
        </MediaMatch>
      </Form>
    </S.Wrapper>
  );
};

export default SignInForm;
