import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { ExplorationCardProps } from './ExplorationCard';

type WrapperProps = Pick<ExplorationCardProps, 'image'>;

export const Container = styled.article`
  ${({ theme }) => css`
    height: 408px;
    background: ${theme.colors.neutral.black};
    border-radius: ${theme.shapes.borderRadius.md};
    padding: ${theme.spacing.md};

    ${media.lessThan('huge')`
      width: 456px;
      padding: ${theme.spacing.lg} ${theme.spacing.md} ${theme.spacing.md} ${theme.spacing.md};
    `}

    ${media.lessThan('medium')`
      width: 327px;
      height: 364px;
      padding: ${theme.spacing.md};
    `}
  `}
`;

export const Heading = styled.h3`
  ${({ theme }) => css`
    color: ${theme.colors.grayscale.dark.title};
    font-size: ${theme.typography.sizes.lg};
    line-height: ${theme.spacing.md};
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.grayscale.dark.title};
    margin-top: ${theme.spacing.sm};
    line-height: ${theme.spacing.md};
  `}
`;

export const ImageContainer = styled.div<WrapperProps>`
  ${({ theme, image }) => css`
    width: 100%;
    height: 234px;

    background: url(${image}) no-repeat center;
    background-size: cover;
    margin-top: ${theme.spacing.md};
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${theme.colors.grayscale.light.info};
    }

    ${media.lessThan('huge')`
      height: 180px;
      margin-top: ${theme.spacing.lg};
    `}

    ${media.lessThan('medium')`
      height: 156px;
      margin-top: ${theme.spacing.sm};
    `}
  `}
`;
