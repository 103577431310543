import * as React from 'react';
import { PopoverProvider, usePopoverContext } from 'contexts/Popover';
import { WithChildren } from 'interfaces/children';
import { PopoverArrowDirection } from 'constants/enums';
import * as S from './Popover.styles';

export type PopoverRootProps = {
  onClick?: () => void;
  isOpen?: boolean;
  arrowDirection?: PopoverArrowDirection;
  distance?: string;
  placement?: 'bottom';
  colorScheme?: 'dark' | 'light';
} & WithChildren;

export const Root = React.forwardRef(
  (
    {
      onClick,
      isOpen,
      children,
      arrowDirection,
      distance,
      placement,
      colorScheme,
    }: PopoverRootProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => (
    <PopoverProvider
      value={{
        isOpen: !!isOpen,
        onClick,
        arrowDirection,
        distance,
        placement,
        colorScheme,
      }}
    >
      <S.Container ref={ref}>{children}</S.Container>
    </PopoverProvider>
  ),
);

export const Trigger = ({ children }: WithChildren) => {
  const { onClick } = usePopoverContext();

  return <div onClick={onClick}>{children}</div>;
};

export const Content = ({ children }: WithChildren) => {
  const {
    isOpen,
    arrowDirection,
    distance,
    placement,
    colorScheme,
  } = usePopoverContext();

  return (
    <S.Content
      placement={placement}
      show={isOpen}
      arrowDirection={arrowDirection}
      distance={distance}
      colorScheme={colorScheme}
    >
      {children}
    </S.Content>
  );
};

export const Popover = { Root, Trigger, Content };
