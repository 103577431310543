import { getEventThemeName } from 'lib/core/event';
import { ProjectNames } from 'constants/enums';
import * as AMPRO from './ampro';
import * as BAYER from './bayer';
import * as CNN from './cnn';
import * as CREATORS from './creators';
import * as MOVILE from './movile';
import * as NETFLIX from './netflix';
import * as SERASA from './serasa';
import * as SXSW from './sxsw';
import * as VALE from './vale';

export const themes = {
  CNN,
  BAYER,
  MOVILE,
  VALE,
  SXSW,
  NETFLIX,
  SERASA,
  CREATORS,
  AMPRO,
};

type ThemeName =
  | ProjectNames.CNN
  | ProjectNames.BAYER
  | ProjectNames.MOVILE
  | ProjectNames.VALE
  | ProjectNames.SXSW
  | ProjectNames.NETFLIX
  | ProjectNames.SERASA
  | ProjectNames.CREATORS
  | ProjectNames.AMPRO;

const getTheme = () => themes[getEventThemeName() as ThemeName];

export const THEME = getTheme();

export default themes;
