import en from './en.json';
import es from './es.json';
import pt from './pt-br.json';

export const rewardsPT = {
  rewards: pt,
};

export const rewardsEN = {
  rewards: en,
};

export const rewardsES = {
  rewards: es,
};
