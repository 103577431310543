import {
  accreditationPT,
  accreditationEN,
  accreditationES,
} from './accreditation';
import { authPT, authEN, authES } from './auth';
import { coffeePT, coffeeEN, coffeeES } from './coffee';
import { configurations } from './configurations';
import { errorGuardEN, errorGuardES, errorGuardPT } from './error-guard';
import { helpCenterPT, helpCenterEN, helpCenterES } from './help-center';
import { interactionsPT, interactionsEN, interactionsES } from './interactions';
import { materialsPT, materialsEN, materialsES } from './materials';
import { rewardsPT, rewardsEN, rewardsES } from './rewards';
import { routesEN, routesES, routesPT } from './routes';
import { scheduleEN, scheduleES, schedulePT } from './schedule';
import { streamPT, streamEN, streamES } from './stream';
import { usersPT, usersEN, usersES } from './users';

export const contextsPT = {
  ...accreditationPT,
  ...authPT,
  ...usersPT,
  ...interactionsPT,
  ...streamPT,
  ...helpCenterPT,
  ...coffeePT,
  ...routesPT,
  ...configurations.pt,
  ...materialsPT,
  ...schedulePT,
  ...errorGuardPT,
  ...rewardsPT,
};

export const contextsEN = {
  ...interactionsEN,
  ...coffeeEN,
  ...helpCenterEN,
  ...streamEN,
  ...accreditationEN,
  ...authEN,
  ...usersEN,
  ...routesEN,
  ...configurations.en,
  ...materialsEN,
  ...scheduleEN,
  ...errorGuardEN,
  ...rewardsEN,
};

export const contextsES = {
  ...interactionsES,
  ...accreditationES,
  ...streamES,
  ...authES,
  ...usersES,
  ...configurations.es,
  ...scheduleES,
  ...errorGuardES,
  ...coffeeES,
  ...materialsES,
  ...helpCenterES,
  ...routesES,
  ...rewardsES,
};
