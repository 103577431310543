import en from './en.json';
import es from './es.json';
import pt from './pt-br.json';

export const schedulePT = {
  schedule: pt,
};

export const scheduleEN = {
  schedule: en,
};

export const scheduleES = {
  schedule: es,
};
