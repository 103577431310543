import { MouseEvent } from 'react';
//import { useTranslate } from 'hooks';
import { Option } from 'components/contexts/Materials/SectionMaterial/SectionMaterial';
import { Icon } from 'components/structure';
import * as S from './MaterialItem.styles';

type MaterialItemProps = {
  isVideo?: boolean;
  material?: Option;
  onClick: (e: MouseEvent) => void;
  as: React.ElementType;
  href?: React.ElementType | string;
  target?: unknown;
  cover: string;
  name?: string;
  more?: string;
  'button-name'?: string;
};

export const MaterialItemCreators = ({
  isVideo,
  material,
  //onClick,
  as,
  href,
  cover,
  name,
  target,
  // more,
  ...props
}: MaterialItemProps) => (
  // const translate = useTranslate();

  <S.Container href={href} target={target} as={as}>
    <a href={(href as string) || '#'} target={'_blank'} rel={'noreferrer'}>
      <S.WrapperItem>
        <S.ThumbImage src={cover} isVideo={isVideo}>
          <Icon icon="IcPlayVideo" />
        </S.ThumbImage>
        <S.MaterialName
          style={{ textAlign: 'center' }}
          dangerouslySetInnerHTML={{ __html: material?.name ?? name ?? '' }}
        />
      </S.WrapperItem>
      <S.ButtonContainer>
        {props['button-name'] ? (
          <S.WatchButton>{props['button-name']}</S.WatchButton>
        ) : (
          ''
        )}
      </S.ButtonContainer>
    </a>
  </S.Container>
);
