import { useMemo, useState } from 'react';
import { UseFormMethods, useController } from 'react-hook-form';
import Select, {
  GroupTypeBase,
  OptionTypeBase,
  Styles,
  components,
  CommonProps,
} from 'react-select';
import { CSSObject } from 'styled-components';
import { useTranslate, useTheme } from 'hooks';
import { HelperText } from 'components/form';
import { Icon } from 'components/structure';
import * as S from './MultiSelect.styles';

export type Options = {
  id: string;
  value: string;
  label?: string;
};

export type MultiSelectProps = Pick<UseFormMethods, 'control'> & {
  label: string;
  name: string;
  placeholder?: string;
  error?: string | undefined;
  options: Options[] | undefined;
  defaultValue?: Options;
  isMulti?: boolean;
  onChange: (value: Options[]) => void;
};

const isNullOrUndefined = (value: string[]) =>
  value === null || value === undefined || !value.length;

const useCustomStyles = () => {
  const { colors } = useTheme();
  return ({
    menu: () => ({
      border: `1px solid ${colors?.form?.input?.label ?? colors.primary.main};`,
      borderTop: 0,
    }),
    control: (provided: CSSObject) => ({
      ...provided,
      boxShadow: 0,
    }),
  } as unknown) as Partial<Styles<Options, true, GroupTypeBase<Options>>>;
};

export const MultiSelect = ({
  error,
  label,
  placeholder,
  options,
  name,
  control,
  defaultValue,
  onChange,
}: MultiSelectProps) => {
  // as Partial<Styles<Options, true, GroupTypeBase<Options>>>
  const translate = useTranslate();
  const customStyles = useCustomStyles();
  const [selectedInterests, setSelectedInterests] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const selectedLabel = translate('labels.selected');
  const {
    field: { ref, value },
  } = useController({
    control,
    name,
  });

  // TODO change checkbox component to always use icon to infer the theme color
  const CustomOption = useMemo(
    () => ({ value, innerProps }: OptionTypeBase) => {
      const selectItem = () => {
        if (!selectedInterests.includes(value)) {
          setSelectedInterests([...selectedInterests, value]);
          return;
        }

        const newSelectedInterests = selectedInterests.filter(
          (item) => item !== value,
        );
        setSelectedInterests(newSelectedInterests);
      };

      return (
        <div
          role="option"
          aria-selected
          tabIndex={0}
          onKeyPress={selectItem}
          onClick={selectItem}
        >
          <S.SelectOptions {...innerProps}>
            <S.RowContainer>
              <S.Checkbox>
                {selectedInterests.includes(value) && (
                  <Icon width="12px" height="12px" icon="IcCheck" />
                )}
              </S.Checkbox>
              <span>{value}</span>
            </S.RowContainer>
          </S.SelectOptions>
        </div>
      );
    },
    [selectedInterests, setSelectedInterests],
  );

  const ValueContainer = useMemo(
    () => ({ getValue, children, ...props }: OptionTypeBase) => {
      const selectedValues = getValue().length;
      const { hasValue } = props;

      if (!hasValue) {
        return (
          <components.ValueContainer
            {...(props as CommonProps<
              OptionTypeBase,
              boolean,
              GroupTypeBase<OptionTypeBase>
            >)}
          >
            {children}
          </components.ValueContainer>
        );
      }

      return (
        <components.ValueContainer
          {...(props as CommonProps<
            OptionTypeBase,
            boolean,
            GroupTypeBase<OptionTypeBase>
          >)}
        >
          {`${selectedValues} ${selectedLabel}`}
        </components.ValueContainer>
      );
    },
    [selectedLabel],
  );

  return (
    <S.Wrapper hasError={!!error}>
      <Select
        components={{ Option: CustomOption, ValueContainer }}
        inputRef={ref}
        name={name}
        classNamePrefix="custom-select"
        placeholder={placeholder}
        options={options}
        value={options?.find(({ value: optionValue }) => optionValue === value)}
        onChange={(value: unknown) => value && onChange(value as Options[])}
        defaultValue={defaultValue}
        styles={customStyles}
        isMulti
        isClearable={false}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isSearchable={false}
        onMenuClose={() => setIsOpen(false)}
        onMenuOpen={() => setIsOpen(true)}
      />
      <S.Label isOpen={isOpen} hide={isNullOrUndefined(selectedInterests)}>
        {label ?? placeholder}
      </S.Label>
      {error && <HelperText error={!!error}>{translate(error)}</HelperText>}
    </S.Wrapper>
  );
};
