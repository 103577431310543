import { useTheme } from 'hooks';
import { Icon } from 'components/structure';
import * as S from './ButtonGroup.styles';

export type Options = {
  text: string;
  value: string;
};

export type ButtonGroupProps = {
  value?: string;
  options: Options[];
  onChange?: (value: string) => void;
  icon?: React.ReactNode;
  initial?: number;
  isWatchAgein?: boolean;
};

export const ButtonGroup = ({
  // icon,
  onChange,
  value,
  options,
  isWatchAgein,
}: ButtonGroupProps) => {
  const { colors } = useTheme();
  const handleClick = (value: string) => {
    !!onChange && onChange(value);
  };

  return (
    <S.ButtonGroupWrapper>
      {options.map(({ text, value: optionValue }) => (
        <S.ButtonItem
          key={optionValue}
          onClick={() => handleClick(optionValue)}
          active={optionValue === value}
        >
          {isWatchAgein ? (
            <Icon icon="IcTelevision" color={colors.grayscale.dark.title} />
          ) : (
            <Icon icon="IcCalendar" color={colors.grayscale.dark.title} />
          )}
          <span>{text}</span>
        </S.ButtonItem>
      ))}
    </S.ButtonGroupWrapper>
  );
};

export default ButtonGroup;
