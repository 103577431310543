import styled, { css } from 'styled-components/macro';
import media from 'styled-media-query';
import * as Structure from 'components/structure';

export const FormActions = styled(Structure.FormActions)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing['2xl']};

    ${media.lessThan('large')`
        /* position: fixed; */
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px ${theme.spacing.lg} 42px ${theme.spacing.lg};
        &::after {
          content: none;
        }
        button {
          z-index:2;
          width: 100%
        }
        &::before {
          position:absolute;
          display: flex;
          background: ${theme.colors.background.outlet};
          filter: blur(${theme.spacing.sm});
          content:'';
          width: 100%;
          /* height: 100%; */
        }
    `}
  `}
`;

export const FormGroup = styled(Structure.FormGroup)`
  /* ${media.between('medium', 'large')`
    margin-bottom: 120px;
  `} */
`;
