import en from './en.json';
import es from './es.json';
import pt from './pt-br.json';

export const footerPT = {
  footer: {
    ...pt,
  },
};

export const footerEN = {
  footer: {
    ...en,
  },
};

export const footerES = {
  footer: {
    ...es,
  },
};
