import {
  ThemePartial,
  ThemeCountdownPartial,
  ThemePrivatePartial,
  ThemePublicPartial,
  ThemeCoffeePartial,
  ThemeBusinessCardPartial,
  Colors,
  ThemeOptions,
} from 'interfaces/theme';
import { ReactComponent as Logo } from 'assets/images/core/creators/logoCreatorW.svg';
import { ReactComponent as LogoWhite } from 'assets/images/core/creators/logoMeta.svg';
import BackgroundError from 'assets/images/core/movile/background-error.png';
import BackgroundOutlet from 'assets/images/core/sxsw/background-outlet.png';
import BackgroundCountdown from 'assets/images/pages/creators/contagemRegressiva01.png';
import BackgroundAccreditationFallbackEn from 'assets/images/pages/sxsw/accreditation/bg-fallback-en.jpg';
import BackgroundAccreditationFallbackEs from 'assets/images/pages/sxsw/accreditation/bg-fallback-es.png';
import BackgroundAccreditationFallback from 'assets/images/pages/sxsw/accreditation/bg-fallback.png';

export const colors: Colors = {
  primary: {
    main: '#fff',
    hover: '#fff',
    active: '#fff',
  },
  secondary: {
    main: '#2BA6ED',
    hover: '#3a3a3a',
    active: '#3a3a3a',
  },
  tertiary: {
    main: '#66d9a3',
    active: '#47c885',
    hover: '#5ad297',
  },
  neutral: {
    black: '#fff',
    filter: 'rgba(0, 0, 0, 0.6)',
    modal: 'rgba(0, 0, 0, 0.8)',
    materialVideoOverlay: 'rgba(0, 0, 0, 0.4)',
    white: '#000',
  },
  grayscale: {
    dark: {
      title: '#000000',
      paragraph: 'rgba(0, 0, 0, 0.8)',
      info: 'rgba(0, 0, 0, 0.5)',
      line: '#F7F8F8',
    },
    light: {
      title: '#fff',
      paragraph: '#fff',
      info: 'rgba(0, 0, 0, 0.5)',
      line: 'rgba(0, 0, 0, 0.5)',
    },
  },
  background: {
    dark: {
      input: 'rgba(255, 255, 255, 0.5)',
      button: 'rgba(0, 0, 0, 0.01)',
    },
    light: {
      input: 'rgba(255, 255, 255, 0.5)',
      button: 'rgba(255, 255, 255, 0.01)',
    },
    outlet: '#e8e8e8',
    public: {
      outlet: '#e8e8e8',
    },
    float: {
      solid: '#f7f8f8',
    },
  },
  form: {
    input: {
      label: 'rgba(0, 0, 0)',
    },
  },
  contextual: {
    info: {
      main: '',
      hover: '',
      active: '',
    },
    success: {
      main: '#38cd3c',
      hover: '#2fc533',
      active: '#22b825',
    },
    attention: {
      main: '#fda700',
      hover: '',
      active: '',
    },
    danger: {
      main: '#ff0202',
      hover: '#cc0202',
      active: '#990303',
    },
    disabled: {
      main: '',
      hover: '',
      active: '',
    },
  },
  skeleton: {
    backgroundColor: '#c4c4c4',
    foregroundColor: 'rgba(255, 255, 255, 0.5)',
  },
  message: {
    light: 'rgba(255, 255, 255, 0.15)',
    dark: 'rgba(0, 0, 0, 0.2)',
  },
};

export const typography = {
  family: {},
};

export const Navbar: ThemePartial = {
  colors: {
    grayscale: {
      ...colors.grayscale,
      dark: {
        title: '#fff',
        paragraph: '#ffffff',
        info: 'rgba(255, 255, 255, 0.6)',
        line: 'rgba(255, 255, 255, 0.2)',
      },
    },
    neutral: {
      ...colors.neutral,
      black: '#000',
      white: '#fff',
    },
  },
  options: {
    logo: <LogoWhite />,
  },
};

export const BusinessCard: ThemeBusinessCardPartial = {
  colors: {
    popover: {
      light: { background: '#fff' },
      dark: {
        background: '#fff',
      },
    },
    neutral: {
      ...colors.neutral,
      white: '#000',
      black: '#000',
    },
  },
};

export const Footer: ThemePartial = {
  colors: {
    grayscale: {
      dark: {
        title: '#fff',
        paragraph: '#fff',
        info: 'rgba(255, 255, 255, 0.6)',
        line: 'rgba(255, 255, 255, 0.2)',
      },
      light: {
        ...colors.grayscale.light,
        title: '#000000',
        paragraph: 'rgba(0, 0, 0, 0.8)',
        info: 'rgba(0, 0, 0, 0.8)',
        line: 'rgba(0, 0, 0, 0.2)',
      },
    },
    neutral: {
      ...colors.neutral,
      black: '#000',
      white: '#fff',
      modal: '#fff',
    },
  },
};

export const Interactions: ThemePartial = {
  colors: {
    grayscale: {
      dark: {
        title: 'rgba(255, 255, 255, 0.5)',
        paragraph: '#ffffff',
        info: 'rgba(255, 255, 255, 0.5)',
        line: 'rgba(255, 255, 255, 0.2)',
      },
      light: {
        ...colors.grayscale.light,
        paragraph: '#000',
        info: 'rgba(255, 255, 255, 0.6)',
        title: '#000',
      },
    },

    neutral: {
      ...colors.neutral,
      white: '#fff',
      black: '#000',
    },

    background: {
      ...colors.background,
      dark: {
        ...colors.background.dark,
        input: 'rgba(0, 0, 0, 0.5)',
      },
    },

    form: {
      input: {
        label: colors.primary.main,
      },
    },
  },
};

export const Private: ThemePrivatePartial = {
  colors: {
    form: {
      input: {
        label: 'rgba(0, 0, 0)',
      },
    },
    grayscale: {
      dark: colors.grayscale.dark,
      light: {
        ...colors.grayscale.light,
        paragraph: 'rgba(0, 0, 0, 0.8)',
      },
    },
    neutral: {
      ...colors.neutral,
      white: '#fff',
    },
  },
};

export const Public: ThemePublicPartial = {
  colors: {
    grayscale: {
      dark: {
        title: '#000000',
        paragraph: 'rgba(0, 0, 0, 0.8)',
        info: 'rgba(0, 0, 0, 0.5)',
        line: 'rgba(0, 0, 0, 0.2)',
      },
      light: colors.grayscale.light,
    },
    neutral: {
      ...colors.neutral,
      modal: '#fff',
    },
  },
  options: {
    backgroundPosition: 'center',
  },
};

export const Countdown: ThemeCountdownPartial = {};

export const UserCountdown: ThemePartial = {
  colors: {
    grayscale: {
      dark: colors.grayscale.dark,
      light: {
        title: '#000',
        paragraph: 'rgba(0, 0, 0, 0.8)',
        info: 'rgba(0, 0, 0, 0.5)',
        line: 'rgba(0, 0, 0, 0.2)',
      },
    },
    neutral: {
      ...colors.neutral,
      white: '#fff',
    },
  },
};

export const Coffee: ThemeCoffeePartial = {
  colors: {
    neutral: colors.neutral,
  },
};

export const EventOptions: ThemeOptions = {
  hasNotification: true,
  hasLinkedInField: true,
  hasInterests: false,
  hasOffers: true,
};

// Quais items do menu estão ativos
export const MenuLabels = [
  // 'navbar.userMenu.profile',
  'navbar.plenary',
  // 'navbar.expo',
  // 'navbar.schedule',
  'navbar.pressMaterial',
  // 'navbar.coffee',
  // 'navbar.pressMaterial',
  'navbar.reimagineVideo',
];

export {
  BackgroundError,
  BackgroundOutlet,
  Logo,
  BackgroundAccreditationFallbackEn,
  BackgroundAccreditationFallbackEs,
  BackgroundAccreditationFallback,
  BackgroundCountdown,
  LogoWhite,
};
