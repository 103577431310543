import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import * as ButtonGroup from 'components/structure/ButtonGroup/ButtonGroup.styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  height: 100%;
  justify-content: space-between;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;

    ${media.lessThan('medium')`
      padding-bottom: calc(${theme.spacing['2xl']} * 2);
    `}

    ${ButtonGroup.ButtonGroupWrapper} {
      margin-top: ${theme.spacing.md};
      height: 40px;
    }
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.grayscale.dark.title};
  `}
`;
