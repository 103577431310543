import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { Heading } from 'components/structure/Typography/Typography';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    height: calc(100vh - ${theme.sizes.footer});
    max-height: 100%;
    position: relative;
    overflow-y: auto;

    ${media.lessThan('large')`
      grid-template-columns: 1fr;
      height: 100%;
      padding: ${theme.spacing.lg} 0;
    `}
  `}
`;

export const Aside = styled.aside`
  ${({ theme }) => css`
    background: #f5f5f5;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spacing['2xl']};

    ${Heading} {
      text-align: center;
      font-size: ${theme.typography.sizes.lg};
      color: ${theme.colors.grayscale.light.title};
    }

    > svg {
      margin: ${theme.spacing.xl} 0 0 ${theme.spacing['2xl']};
    }

    ${media.lessThan('large')`
      padding-top: ${theme.spacing.xl};

      > svg {
        display: none;
      }
    `}
  `}
`;

export const Progress = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  height: 100%;
`;

export const RankContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${theme.colors.neutral.white};
    margin-top: ${theme.spacing.xl};
  `}
`;

export const Footer = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  ${media.lessThan('large')`
    top: 0;
    bottom: initial;
  `}
`;
