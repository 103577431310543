import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import * as ButtonStyles from 'components/structure/Button/Button.styles';
import * as ModalStyles from 'components/structure/Modal/Modal.styles';

export const Wrapper = styled(ModalStyles.Wrapper)`
  input {
    display: none;
  }
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    background: ${theme.colors.modal?.light.background ??
    theme.colors.grayscale.dark.paragraph};
    border-radius: ${theme.shapes.borderRadius.md};
    max-width: 864px;
    max-height: 520px;
    height: 90%;
    width: 90%;

    ${media.lessThan('medium')`
      border-radius: 0;
      height: 100%;
      max-height: unset;
      width: 100%;
    `}
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${theme.spacing.md};
    height: 72px;
    width: 100%;

    h4 {
      color: ${theme.colors.primary.main};
      font-size: ${theme.typography.sizes.lg};
      font-family: ${theme.typography.family.secondary};
      font-weight: 700;
      line-height: ${theme.typography.lineHeight.md};
    }

    ${media.lessThan('medium')`
      height: 64px;
    `}
  `}
`;

export const Footer = styled(Header)`
  ${({ theme }) => css`
    justify-content: flex-end;
    padding-top: 0;
    padding-bottom: 0;

    ${ButtonStyles.Wrapper} {
      &:not(:last-child) {
        margin-right: ${theme.spacing.md};
      }
    }

    ${media.lessThan('medium')`
      justify-content: space-between;

      ${ButtonStyles.Wrapper} {
        height: 40px;
        width: 150px;
      }
    `}
  `}
`;

export const CanvasWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.grayscale.light.title};
    border: 1px solid ${theme.colors.primary.main};
    border-top: none;
    border-bottom: none;
    flex: 1;
    position: relative;
    width: 100%;

    .reactEasyCrop {
      &_Container {
        height: 100%;
        position: relative;
        background-color: ${theme.colors.grayscale.light.title};
      }

      &_CropArea {
        opacity: 1;
        border: 2px solid ${theme.colors.neutral.white};
      }
    }
  `}
`;

export const SliderWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing['2xs']} 0;
    margin: 0 auto;
    height: 60px;
    width: 320px;

    span {
      color: ${theme.colors.neutral.white};
      line-height: ${theme.typography.lineHeight.base};
      margin-left: -6px;
    }

    .rc-slider {
      &-track {
        background-color: ${theme.colors.primary.main};
      }

      &-handle {
        background-color: ${theme.colors.primary.main};
        border: ${theme.colors.primary.main};

        :active {
          box-shadow: unset;
        }
      }
    }
  `}
`;

export const ButtonClose = styled.button`
  ${({ theme }) => css`
    color: ${theme.colors.primary.main};
    height: 24px;
    width: 24px;
  `}
`;

export const FloatingText = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.neutral.white};
    position: absolute;
    bottom: 68px;
    left: 50%;
    transform: translateX(-50%);
  `}
`;
