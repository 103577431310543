import styled from 'styled-components';

type SVGContainerProps = {
  rotateSvg: boolean;
};

export const Container = styled.div`
  width: 100%;
  max-width: 95vw;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.2);

  border-radius: 4px;

  @media screen and (max-width: 1170px) {
    border: none;
    background: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0;
    /* background-color: red; */
  }
`;

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 20px 40px;
`;

export const TitleContainer = styled.section``;

export const Title = styled.h1`
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 24px;
`;

export const Description = styled.p`
  font-weight: 400;
  font-size: 16px;
`;

export const SVGContainer = styled.button<SVGContainerProps>`
  width: 35px;
  min-width: 35px;
  max-width: 35px;
  height: 35px;
  min-height: 35px;
  max-height: 35px;
  margin-left: 10px;
  background-color: transparent;
  border-radius: 50%;
  outline: 4px solid #c2c8ce;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    transform: ${({ rotateSvg }) =>
      !rotateSvg ? 'rotate(90deg)' : 'rotate(-180deg)'};
    transition: transform 0.2s ease-in-out;
    background-color: white;
  }
  > svg {
    transform: ${({ rotateSvg }) =>
      rotateSvg ? 'rotate(90deg)' : 'rotate(0deg)'};
    transition: transform 0.2s ease-in-out;
    > path {
      fill: black;
    }
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  padding: 10px;
  flex-wrap: wrap;
  justify-content: flex-start;

  @media screen and (max-width: 1170px) {
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: scroll;
    justify-content: flex-start;
  }
`;
