import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Pagination = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: center;

    .pagination {
      border-radius: 20px;
      margin: ${theme.spacing.lg} 0 0;
      display: flex;
      list-style: none;
      justify-content: space-between;
      align-items: center;

      ${media.lessThan('huge')`
        margin: 0;
      `}

      li {
        a {
          color: ${theme.colors.grayscale.dark.info};
          cursor: pointer;
          font-family: ${theme.typography.family.primary};
          font-weight: 500;
          outline: 0;
          padding: ${theme.spacing.sm};

          ${media.lessThan('medium')`
            padding: 10px;
          `}
        }

        &.active a {
          color: ${theme.colors.primary.main};
        }

        &.previous.disabled,
        &.next.disabled {
          opacity: 0.5;
          color: ${theme.colors.primary.main};
        }

        &.previous a,
        &.next a {
          opacity: 1;
          color: ${theme.colors.primary.main};
        }
      }
    }
  `}
`;
