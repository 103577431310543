import styled, { css } from 'styled-components';
import media from 'styled-media-query';

type CFCodeProps = {
  isDesktop?: boolean;
  isCenter?: boolean;
  marginTop?: boolean;
  marginBottom?: boolean;
  marginLeft?: boolean;
};

export const Content = styled.p<CFCodeProps>`
  ${({
    theme,
    isDesktop,
    isCenter,
    marginTop,
    marginBottom,
    marginLeft,
  }) => css`
    font-size: ${theme.typography.sizes.xs};
    color: ${theme.colors.grayscale.dark.info};
    margin-top: ${marginTop ? theme.spacing.md : '0px'};
    margin-bottom: ${marginBottom ? theme.spacing.md : '0px'};
    margin-left: ${marginLeft ? theme.spacing.md : '0px'};
    text-align: ${isCenter ? 'center' : 'left'};

    ${media.lessThan('large')`
      display: ${isDesktop ? 'none' : 'block'};
    `}

    ${media.greaterThan('large')`
      display: ${!isDesktop ? 'none' : 'block'};
    `}
  `}
`;
