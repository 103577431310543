import { getEventThemeName } from 'lib/core/event';
import { PlenaryCard, OtherStreams } from 'components/contexts/home';
import { Stream } from 'interfaces/stream';
import { ProjectNames } from 'constants/enums';
import * as S from './EventSchedule.styles';

export type EventScheduleProps = {
  streams: Stream[];
};
// TODO validate the possibilite of streams be empty and if so added empty state
export const EventSchedule = ({ streams = [] }: EventScheduleProps) => {
  const [firstStreamData, secondStreamData, ...otherStreams] = streams;

  // TODO: adicionar index nas plenarias para ordenar.
  const [firstStream, secondStream] =
    ProjectNames.BAYER === getEventThemeName()
      ? [secondStreamData, firstStreamData]
      : [firstStreamData, secondStreamData];

  return (
    <S.Wrapper>
      {firstStream && (
        <PlenaryCard
          key={`plenary-card-${firstStream.id}`}
          id={firstStream.id}
          title={firstStream.title}
          thumbnail={firstStream.thumbnail}
          description={firstStream.description}
          streamState={firstStream.state}
          scheduledStartTime={firstStream.scheduledStartTime}
          scheduledEndTime={firstStream.scheduledEndTime}
        />
      )}
      {!otherStreams.length && !!secondStream && (
        <PlenaryCard
          key={`plenary-card-${secondStream.id}`}
          id={secondStream.id}
          title={secondStream.title}
          thumbnail={secondStream.thumbnail}
          description={secondStream.description}
          streamState={secondStream.state}
          scheduledStartTime={secondStream.scheduledStartTime}
          scheduledEndTime={secondStream.scheduledEndTime}
        />
      )}

      {!!otherStreams.length && (
        <OtherStreams streams={[secondStream, ...otherStreams]} />
      )}
    </S.Wrapper>
  );
};
