import { useEffect } from 'react';
import { Icon } from 'components/structure';
import * as S from './Toast.styles';

const typesIcons = {
  default: '',
  success: <Icon icon="IcSuccess" />,
  danger: <Icon icon="IcExclude" />,
  alert: <Icon icon="IcAlert" />,
  conversion: '',
};

export type ToastProps = {
  hasChat?: boolean;
  show?: boolean;
  title?: string;
  description?: string;
  type?: 'default' | 'success' | 'danger' | 'alert' | 'conversion';
  handleClose?: () => void;
  setShow?: (show: boolean) => void;
  duration?: number;
  pin?: boolean;
  link?: string;
  image?: string;
  buttonLabel?: string;
  optionalText?: string;
  isExternalLink?: boolean;
  playerHeight?: number;
};

export const Toast = ({
  hasChat = false,
  show = false,
  title,
  description,
  type = 'default',
  duration = 5000,
  pin = false,
  handleClose,
  setShow,
  link,
  image,
  buttonLabel,
  optionalText,
  isExternalLink,
  playerHeight,
}: ToastProps) => {
  useEffect(() => {
    if (!pin) {
      const timeout = setTimeout(() => !!setShow && setShow(false), duration);

      return () => clearTimeout(timeout);
    }
  }, [show, duration, pin, setShow]);

  return (
    <S.Wrapper
      hasChat={hasChat}
      type={type}
      show={show}
      playerHeight={playerHeight}
    >
      {!!typesIcons[type] && <S.IconWrapper>{typesIcons[type]}</S.IconWrapper>}
      {!!image && <img src={image} alt={title} />}
      <S.ContentWrapper
        as={link ? 'a' : 'div'}
        href={link}
        target={isExternalLink ? '_blank' : '_self'}
        rel="noopener noreferrer"
      >
        <S.Title>{title}</S.Title>
        <S.Description>{description}</S.Description>

        {type === 'conversion' && (
          <S.ActionsWrapper>
            {optionalText && <strong>{optionalText}</strong>}
            <S.ActionButton
              type="button"
              href={link}
              target={isExternalLink ? '_blank' : '_self'}
              rel="noopener noreferrer"
            >
              {buttonLabel}
            </S.ActionButton>
          </S.ActionsWrapper>
        )}
      </S.ContentWrapper>
      <S.ButtonClose onClick={handleClose}>
        <Icon icon="IcClose" />
      </S.ButtonClose>
    </S.Wrapper>
  );
};
