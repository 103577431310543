import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAPI, useFetch, useSocketListener } from 'hooks';
import {
  useInteractionsContext,
  useSurveyContext,
  useIntlContext,
  useChatContext,
  useStreamContext,
} from 'contexts';
import { useSocketContext } from 'contexts/Socket';
import { SURVEYS } from 'constants/endpoints';
import { InteractionsTabs, StreamEvents, SurveyState } from 'constants/enums';
import { useChat } from './chat';

export type AlternativesType = {
  id: string;
  language: string;
  text: string;
};

type QuestionType = {
  language: string;
  text: string;
};

type AlternativesResultType = {
  count: number;
  id: string;
  percent: number;
};

type ResultType = {
  alternatives: AlternativesResultType[];
  total: number;
};

export type Survey = {
  id: string;
  question: QuestionType;
  alternatives: AlternativesType[];
  result?: ResultType;
};

export const useSurveyById = (id: string) =>
  useFetch<{ survey: Survey }>(SURVEYS.BY_ID(id));

export const useHandleSurveyAvailable = (callback?: () => void) => {
  const { availableTabs, setTab } = useInteractionsContext();
  const { language } = useIntlContext();
  const { setSurvey, setSurveyState, setAwaitingResult } = useSurveyContext();

  return ({
    id,
    alternatives,
    question,
    result,
  }: {
    id: string;
    alternatives: [
      [
        {
          id: string;
          language: string;
          text: string;
        },
      ],
    ];
    question: [
      {
        language: string;
        text: string;
      },
    ];
    result: {
      alternatives: [
        {
          count: number;
          id: string;
          percent: number;
        },
      ];
      total: number;
    };
  }) => {
    const items: AlternativesType[] = [];
    alternatives.forEach((alternative) => {
      const item = alternative.find((value) => value.language === language);
      if (item) {
        items.push(item);
      }

      return items;
    });

    const newQuestion = question.find(
      (value) => value.language === language,
    ) || {
      text: '',
      language: '',
    };

    setSurvey({
      id,
      alternatives: items,
      question: newQuestion,
      result,
    });
    setSurveyState(SurveyState.available);
    if (availableTabs.includes(InteractionsTabs.survey)) {
      setTab(InteractionsTabs.survey);
    }
    setAwaitingResult(false);
    callback && callback();
  };
};

export const useHandleSurveyUnavailable = () => {
  const { availableTabs, setTab } = useInteractionsContext();
  const { setSurvey, setSurveyState, setAwaitingResult } = useSurveyContext();
  const {
    plenary,
    showRoom,
    room,
    setRoom,
    setShowRoom,
    setPlenary,
    setUserChatsParams,
  } = useChatContext();
  return ({
    id,
    alternatives,
    question,
  }: {
    id: string;
    alternatives: [
      {
        id: string;
        language: string;
        text: string;
      },
    ];
    question: {
      language: string;
      text: string;
    };
  }) => {
    setSurvey({ id, alternatives, question });
    setSurveyState(SurveyState.notAvailable);
    if (availableTabs.includes(InteractionsTabs.survey)) {
      setTab(InteractionsTabs.chat);
      setRoom({
        id: 'STREAM-' + plenary.id,
        isPrivate: false,
        name: plenary.name,
      });
      setShowRoom(true);
      setPlenary({ id: plenary.id, name: plenary.name });
    }
    setAwaitingResult(false);
  };
};

export const useHandleResultAvailable = (callback?: () => void) => {
  const {
    survey,
    setSurvey,
    setSurveyState,
    awaitingResult,
  } = useSurveyContext();

  return ({ answer }: { answer: ResultType }) => {
    setSurvey({ ...survey, result: answer });
    if (awaitingResult) {
      setSurveyState(SurveyState.resultAvailable);
    }
    callback && callback();
  };
};

export const useJoinToStream = (
  socket: SocketIOClient.Socket,
  streamId?: string,
) => {
  useEffect(() => {
    socket.emit(StreamEvents.JOIN, {
      streamId,
    });
  }, [socket, streamId]);
};

export const useHandleVoteSurvey = () => {
  const api = useAPI();
  const [isPending, setIsPending] = useState(false);
  const { setSurveyState, setAwaitingResult } = useSurveyContext();

  const handleVoteSurvey = async (surveyId: string, answer: string[]) => {
    try {
      setIsPending(true);
      await api.post(SURVEYS.ANSWERS(surveyId), { answer });
      setSurveyState(SurveyState.resultAvailable);
      setAwaitingResult(true);
    } finally {
      setIsPending(false);
    }
  };

  return { isPending, handleVoteSurvey };
};

type useSurveyProps = {
  streamId: string;
  onSurveyAvailable?: () => void;
  onSurveyResult?: () => void;
};

export const useSurvey = ({
  streamId,
  onSurveyAvailable: handleSurveyAvailable,
  onSurveyResult: handleSurveyResult,
}: useSurveyProps) => {
  const { surveySocket: socket } = useSocketContext();

  const onConnect = () =>
    console.info(
      `%cSurvey socket connected`,
      `color: #06c16a; font-weight: bold;`,
    );

  const onSurveyAvailable = useHandleSurveyAvailable(handleSurveyAvailable);

  const onSurveyResult = useHandleResultAvailable(handleSurveyResult);
  const onSurveyUnavailable = useHandleSurveyUnavailable();

  useSocketListener(socket, StreamEvents.CONNECTED, onConnect);
  useJoinToStream(socket, streamId);
  useSocketListener(socket, StreamEvents.SURVEY_AVAILABLE, onSurveyAvailable);
  useSocketListener(
    socket,
    StreamEvents.SURVEY_UNAVAILABLE,
    onSurveyUnavailable,
  );
  useSocketListener(socket, StreamEvents.SURVEY_RESULT, onSurveyResult);
};
