/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
// import { useUpdateUser } from 'useCases/users';
import { useTranslate } from 'hooks';
import { useCountdown } from 'hooks/useCountdown';
import { setPadStart } from 'lib/contexts/accreditation';
import { getUserId } from 'lib/core/session';
import { ThemeCustomProvider } from 'contexts';
import { SubTitle, Icon, Heading, Paragraph } from 'components/structure';
import * as CountdownTheme from 'styles/overrides/Countdown.theme';
import { THEME } from 'styles/themes';
import { SocialLink } from 'interfaces/event';
import { User, UserAPI } from 'interfaces/user';
import { USERS } from 'constants/endpoints';
import { ROUTES } from 'constants/urls';
import * as S from './Countdown.styles';

type Countdown = {
  total: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

type CountdownProps = {
  countdown?: Countdown;
  socialLinks?: SocialLink[] | null;
  eventDate: string;
  hashtags?: string[];
  hasFooterDescription?: boolean;
  hasFooterTitle?: boolean;
  footerTitle?: string;
  subTitle?: string;
  hasMemberGetMember?: boolean;
};

type Icons = {
  [key: string]: () => JSX.Element;
};

type Periods = 'days' | 'hours' | 'minutes' | 'seconds';

const icons: Icons = {
  linkedin: () => <Icon icon="IcLinkedinSmall" />,
  youtube: () => <Icon icon="IcYoutube" />,
  twitter: () => <Icon icon="IcTwitter" />,
  instagram: () => <Icon icon="IcInstragram" />,
  facebook: () => <Icon icon="IcFacebook" />,
};

const socialsLinks = [
  { icon: '', links: '' },
  { icon: '', links: '' },
  { icon: '', links: '' },
];

const getIcon = (name: string) => icons[name.toLowerCase()]();
const Countdown = ({
  eventDate,
  socialLinks,
  hashtags,
  hasFooterTitle = false,
  footerTitle,
  subTitle,
  hasMemberGetMember,
}: CountdownProps) => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const userId = getUserId();
  // const { updateUser } = useUpdateUser();
  const { days, hours, minutes, seconds } = useCountdown(eventDate);
  const user: User = {
    id: userId!,
  };
  const checkTimeRemaining = (time: Periods) => {
    if (time === 'days' && days) return time;

    if (time === 'hours' && !days && hours) return time;

    if (time === 'minutes' && !days && !hours && minutes) return time;

    if (time === 'seconds' && !days && !hours && !minutes && seconds)
      return time;
  };
  useEffect(() => {
    (async () => {
      const { data } = await api.patch<UserAPI>(USERS.ROOT, {
        ...user,
        completeRegistration: true,
      });
    })();
  }, []);

  return (
    <ThemeCustomProvider {...CountdownTheme}>
      <S.Container hasMemberGetMember={hasMemberGetMember}>
        {/* <THEME.Logo style={{ width: '200px', height: '200px' }} /> */}

        <S.Content>
          <S.Card>
            <S.Title>
              {translate('accreditation.countdown.AMPRO.title')}
            </S.Title>
            <S.TitleTwo>
              {translate('accreditation.countdown.AMPRO.subTitle')}
            </S.TitleTwo>

            <S.CountdownContainer>
              <S.CurrentTimeContainer isActive={!!checkTimeRemaining('days')}>
                <S.CountText>{setPadStart(days)}</S.CountText>
                <S.PeriodText>
                  {translate('accreditation.countdown.days')}
                </S.PeriodText>
              </S.CurrentTimeContainer>

              <S.CurrentTimeContainer isActive={!!checkTimeRemaining('hours')}>
                <S.CountText>{setPadStart(hours)}</S.CountText>
                <S.PeriodText>
                  {translate('accreditation.countdown.hours')}
                </S.PeriodText>
              </S.CurrentTimeContainer>

              <S.CurrentTimeContainer
                isActive={!!checkTimeRemaining('minutes')}
              >
                <S.CountText>{setPadStart(minutes)}</S.CountText>
                <S.PeriodText>
                  {translate('accreditation.countdown.minutes')}
                </S.PeriodText>
              </S.CurrentTimeContainer>

              <S.CurrentTimeContainer
                isActive={!!checkTimeRemaining('seconds')}
              >
                <S.CountText>{setPadStart(seconds)}</S.CountText>
                <S.PeriodText>
                  {translate('accreditation.countdown.seconds')}
                </S.PeriodText>
              </S.CurrentTimeContainer>
            </S.CountdownContainer>
            <S.SocialLinksContainer>
              {/* {hasFooterTitle && <S.FooterTitle>{footerTitle}</S.FooterTitle>} */}
              {hashtags?.map((item) => (
                <strong key={item}>{item}</strong>
              ))}

              {socialLinks && (
                <S.SocialLinksList>
                  {socialLinks?.map(({ name, link }) => (
                    <a
                      key={name}
                      href={link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {getIcon(name)}
                    </a>
                  ))}
                </S.SocialLinksList>
              )}
            </S.SocialLinksContainer>
            <S.CountdownFooter>
              <S.ContentFooter>
                <S.FooterDescription>
                  {translate('accreditation.countdown.AMPRO.footerDescription')}
                </S.FooterDescription>
              </S.ContentFooter>
            </S.CountdownFooter>
          </S.Card>
        </S.Content>

        {hasMemberGetMember && (
          <S.Footer>
            <S.Aside>
              <Icon
                icon="IcChevronLeft"
                onClick={() =>
                  navigate(ROUTES.countdown.getLink('userCountdown'))
                }
              />
              <Heading>{translate('accreditation.countdown.rewards')}</Heading>
            </S.Aside>
            <THEME.Logo />
          </S.Footer>
        )}
      </S.Container>
    </ThemeCustomProvider>
  );
};

export default Countdown;
