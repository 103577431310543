import { Country } from 'useCases/assets';
// import { useTranslate } from 'hooks';
import { isValidUrl } from 'lib/contexts';
import { Avatar } from 'components/contexts/users';
import { IcEmail, IcLinkedin } from 'assets/icons';
import * as S from './BusinessCard.styles';

export type BusinessCardProps = {
  name?: string;
  image?: string;
  email?: string;
  linkedIn?: string;
  profile?: string;
  country?: Country;
  state?: string;
  ask3?: string;
  company?: string;
  isLightBackground?: boolean;
};
const BRAZIL_COUNTRY_CODE = 76;
const isBrazil = (country?: string) => Number(country) === BRAZIL_COUNTRY_CODE;

export const BusinessCard = ({
  name = '',
  profile,
  linkedIn,
  email,
  image,
  country,
  ask3,
  state,
  company,
  isLightBackground = false,
}: BusinessCardProps) => (
  // const translate = useTranslate();

  <S.Wrapper isLightBackground={isLightBackground}>
    {/* <S.FloatingText isLightBackground={isLightBackground}>
        {translate('configurations.cardPreview')}
      </S.FloatingText> */}
    <S.AvatarWrapper>
      <Avatar src={image} alt={name} />
    </S.AvatarWrapper>
    <S.ContentWrapper>
      {name && (
        <span>
          <strong>{name}</strong>
        </span>
      )}
      <span>
        {!!state && isBrazil(country?.value) && `${state}, `} {country?.label}
      </span>
      {ask3 && (
        <span>
          {' '}
          {company},{ask3}
        </span>
      )}
      {profile && <span>{profile}</span>}
      <S.LinksWrapper>
        {linkedIn && (
          <a
            href={isValidUrl(linkedIn) ? linkedIn : undefined}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IcLinkedin />
          </a>
        )}
        {email && (
          <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
            <IcEmail />
          </a>
        )}
      </S.LinksWrapper>
    </S.ContentWrapper>
  </S.Wrapper>
);

export default BusinessCard;
