import styled, { css, DefaultTheme } from 'styled-components';
import media from 'styled-media-query';
import * as ButtonStyles from 'components/structure/Button/Button.styles';
import * as LogoStyles from 'components/structure/Logo/Logo.styles';
import * as Typography from 'components/structure/Typography/Typography';

type ContainerProps = {
  src?: string;
  isSpaceFull?: boolean;
};

const containerModifiers = {
  hasImage: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.grayscale.light.info};

    ${Content} {
      ${Title} {
        color: ${theme.colors.neutral.white};
        font-weight: bold;
      }

      ${Typography.SubTitle} {
        color: ${theme.colors.neutral.white};
        font-weight: bold;
      }

      p {
        color: ${theme.colors.neutral.white};
      }

      ${ButtonStyles.Wrapper} {
        background: ${theme.colors.primary.main};
        color: ${theme.colors.neutral.white};
      }
    }
  `,
  shortSpace: () => css`
    min-height: 100%;
  `,
};

export const Container = styled.div<ContainerProps>`
  ${({ theme, src = '', isSpaceFull }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    width: 100%;
    background: ${theme.colors.primary.main} url(${src}) no-repeat center;
    background-size: cover;
    padding: ${theme.spacing.lg} ${theme.spacing['2xl']};

    ${media.lessThan('large')`
      padding: ${theme.spacing.xl} ${theme.spacing.md} ${theme.spacing.md};

      ${LogoStyles.Wrapper} {
        margin-bottom: 0;
      }
    `}

    ${!!src && containerModifiers.hasImage(theme)}
    ${!isSpaceFull && containerModifiers.shortSpace()}
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    ${Typography.SubTitle} {
      font-size: ${theme.typography.sizes['2xl']};
      text-align: center;
      color: ${theme.colors.grayscale.light.paragraph};
      font-weight: normal;
      text-transform: uppercase;

      ${media.lessThan('medium')`
        font-size: ${theme.typography.sizes.xl};
        line-height: ${theme.typography.sizes['2xl']};
      `}

      ${media.lessThan('large')`
        font-size: ${theme.typography.sizes['2xl']};
      `}
    }

    p {
      color: ${theme.colors.grayscale.light.paragraph};
      margin-top: ${theme.spacing.md};
      max-width: 418px;
      text-align: center;
      line-height: ${theme.spacing.md};
    }

    ${ButtonStyles.Wrapper} {
      margin-top: ${theme.spacing.md};
      background: ${theme.colors.grayscale.light.line};
    }
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.secondary.main};
    font-size: ${theme.typography.sizes['4xl']};
    margin-bottom: ${theme.spacing.sm};
    font-weight: normal;
    text-transform: uppercase;

    ${media.lessThan('medium')`
      font-size:  ${theme.typography.sizes.xl};
      line-height: 40px;
      text-align: center;
    `}
  `}
`;
