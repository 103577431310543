import { yupResolver } from '@hookform/resolvers/yup';
import { RefObject } from 'react';
import { isMobile } from 'react-device-detect';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useTranslate } from 'hooks';
import { useEnv } from 'hooks';
import { TextField } from 'components/form';
import {
  Button,
  CFCode,
  Form,
  FormActions,
  FormGroup,
  Heading,
  Paragraph,
  ButtonSupport,
} from 'components/structure';
import { ForgotPassword } from 'interfaces/auth';
import { ROUTES } from 'constants/urls';
import { schema, defaultValues } from './ForgotPasswordForm.validations';

type ForgotPasswordFormProps = {
  onSubmit: (email: ForgotPassword) => void;
  isPending?: boolean;
  showRecaptcha?: boolean;
  showSupportButton?: boolean;
  recaptchaRef?: RefObject<ReCAPTCHA>;
};

export const ForgotPasswordForm = ({
  onSubmit,
  isPending,
  showRecaptcha,
  showSupportButton,
  recaptchaRef,
}: ForgotPasswordFormProps) => {
  const translate = useTranslate();
  const { googleRecaptcha } = useEnv();

  const { register, errors, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Heading>{translate('auth.forgotPassword.title')}</Heading>
      <Paragraph>{translate('auth.forgotPassword.description')}</Paragraph>

      <FormGroup half>
        <TextField
          ref={register}
          type="email"
          name="email"
          autoComplete="email"
          label={translate('labels.email')}
          placeholder={translate('labels.email')}
          error={!!errors?.email?.message}
          helperText={translate(errors?.email?.message)}
        />
      </FormGroup>
      {showRecaptcha && (
        <ReCAPTCHA ref={recaptchaRef} sitekey={googleRecaptcha} />
      )}
      {showSupportButton && (
        <FormGroup lgMargin>
          <ButtonSupport
            onlyIcon={isMobile}
            fixedDesktop={!isMobile}
            icon={isMobile ? 'IcHelp' : undefined}
          />
        </FormGroup>
      )}
      <FormActions>
        <CFCode />
        <Button
          as={Link}
          type="button"
          to={ROUTES.login.getLink('signIn')}
          color="secondary"
        >
          {translate('labels.back')}
        </Button>
        <Button isLoading={isPending} disabled={isPending} type="submit">
          {translate('labels.recover')}
        </Button>
      </FormActions>
    </Form>
  );
};

export default ForgotPasswordForm;
